import { useState, useEffect } from "react";

// Define a custom hook that takes the initial time and an interval
export function useCountdown(initialSeconds: number, interval = 1000) {
    // Use state to store the remaining seconds
    const [seconds, setSeconds] = useState<number>(initialSeconds);
    // Use state to store the restart flag
    const [restart, setRestart] = useState(false);
    // Use effect to set up a timer that updates the state every second
    useEffect(() => {
        let timerId: any = null;
        // If the seconds are not zero, create a timer and store its id
        if (seconds > 0) {
            timerId = setInterval(() => {
                setSeconds((prevSeconds: any) => prevSeconds - 1);
            }, interval);
            // setTimerId(id);
        } else {
            // If the seconds are zero, clear the timer and alert an error
            clearInterval(timerId);
            // alert("Time is up!");
        }
        // Return a cleanup function that clears the timer when the component unmounts or the dependency changes
        return () => {
            clearInterval(timerId);
        };
    }, [seconds]); // The effect depends on the seconds and the interval

    // Use effect to reset the seconds when the restart flag changes
    useEffect(() => {
        // Reset the seconds to the initial value
        setSeconds(initialSeconds);
    }, [restart]); // The effect depends on the restart flag and the initial value

    // Return an object with the remainingseconds and a function to toggle the restart flag
    return {
        seconds: seconds,
        restartTimer: () => setRestart((prevRestart) => !prevRestart), // A reducer function that toggles the restart flag
    };
}
import React, { useRef } from 'react';
import './clientLayoutPreview.scss';
import { Layout, Menu, Row, Col, Button, PageHeader, Steps, Modal } from 'antd';
import { Route, Link, BrowserRouter as Router, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Constant } from './../../services/services'
import BookingSummary from './../../pages/bookingSummary/bookingSummary';
import { SearchOutlined, RedoOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Stepper } from '@progress/kendo-react-layout';
import { useGlobalState } from '../../services/state';
import { Appointment, Customer, Location, Vehicle, ServiceAdvisor, BookingData } from './../../models/appModels';
import { ClientService } from '../../services/services'

const { Header, Content, Footer, Sider } = Layout;
const { confirm } = Modal;

ClientService.registerIntercepter()

const MainLayout = (props: any) => {
    const [current, setCurrent] = React.useState<number>(0)
    const { t } = useTranslation();
    const [appointmentDetailData, setAppointmentDetailData] = useGlobalState('appointmentDetailData');
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [jobLineData, setJobLineData] = useGlobalState('jobLineData');
    const [customerDetailData, setCustomerDetailData] = useGlobalState('customerDetailData');
    const [vehicleDetailData, setVehicleDetailData] = useGlobalState('vehicleDetailData');
    const [isInit] = useGlobalState('isInit');
    const childRef = useRef<any>();
    const [useAnimate, setUseAnimate] = React.useState<boolean>(false)
    const [, setReservationToken] = useGlobalState('reservationToken');
    const [, setbookingData] = useGlobalState('bookingData');
    const summaryRef = useRef<any>();


    const iconsWithLabel = [
        { icon: 'k-i-osb k-i-customer', label: t("steps.your_detail") },
        { icon: 'k-i-osb k-i-vehicle', label: t("steps.your_vehicle") },
        { icon: 'k-i-osb k-i-service', label: t("steps.service_details") },
        { icon: 'k-i-osb k-i-booking', label: t("steps.booking_details") },
        { icon: 'k-i-osb k-i-summary', label: t("steps.confirm_details") }
    ];

    return (

        <Layout className="layout-preview">
            <div className="layout-header background-background">
                {/* <Header className="layout-header background-background"> */}
                {/* <div className="layout-info">
                    <div className="logo">
                        <img src={process.env.PUBLIC_URL + '/TiTan_Logo_Main.png'} />
                    </div>
                    <p className="app_title" dangerouslySetInnerHTML={{ __html: t("common.app_title") }}></p>

                    <div className="btn-group">
                        <span>
                            <Button className="btn secondary-btn" key="2" icon={<SearchOutlined />}>
                                <span className="btn-description">{t("common.search")}</span></Button>
                            <Button className="btn m-l-5 secondary-btn" key="1" icon={<RedoOutlined />}>
                                <span className="btn-description">{t("common.reset")}</span></Button>
                        </span>
                    </div>
                </div> */}
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 16, offset: 3 }}>
                        <Stepper {...props} value={3} items={iconsWithLabel} />
                    </Col>
                </Row>
                {/* </Header> */}
            </div>

            <Layout className={`layout-container background-background ${props.showStepper ? "" : "hide-stepper"}`}>
                <Row>
                    {
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
                            <div className="content">
                                {props.children}
                            </div>
                        </Col>
                    }
                    {/* <Col className="summary-column" lg={{ span: 7}} md={{ span: 0 }} xs={{ span: isConfirmDetailsPage() ? 24 : 0 }}>
                            <BookingSummary ref={summaryRef} isConfirmDetailsPage={isConfirmDetailsPage()} className={useAnimate ? (isConfirmDetailsPage() ? "summary-expand" : "summary-collapse") : ""}></BookingSummary>
                        </Col> */}
                </Row>
            </Layout>
        </Layout>

    )

}

export default MainLayout
import React, { useEffect, useState } from 'react';
import './themeSetup.scss'
import { Button, Form, Row, Col, Select, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppSettingService, CommonService, Constant } from '../../services/services'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { DealerStyle } from '../../models/appModels';
import { ColorPicker } from '../../components/colorPicker/colorPicker';
import BookingDetail from "../bookingDetail/bookingDetail";
import ClientLayoutPreview from "../../layouts/clientLayoutPreview/clientLayoutPreview";

const { Option } = Select;

const layout = {
    labelCol: { span: 18 },
    wrapperCol: { span: 6 },
};

const colLayouts = {
    xs: { span: 24 },
    sm: { span: 12 },
    md: { span: 24 }
}

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 6 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 18 }
}


const btnLayout = {
    wrapperCol: {
        xs: { span: 24 },
        md: { span: 23 }
    }
};

function ThemeSetup(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [themeName, setThemeName] = useState<string>()
    const [allowModify, setAllowModify] = useState<boolean>(false)

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.GeneralSetup))

        showLoading()
        AppSettingService.getDealerStyle()
            .finally(() => dismissLoading())
            .then(result => {
                if (result && result.data) {
                    form.setFieldsValue(result.data)
                    setThemeName(result.data.ThemeName)
                    CommonService.setDealerStyle(result.data, true)
                }
            })

        return () => {
            AppSettingService.cancelRequest()
        }
    }, [])

    const onSubmit = () => {
        let dealerStyle = form.getFieldsValue()
        dealerStyle.ThemeName = themeName
        showLoading()
        AppSettingService.addUpdateDealerStyle(dealerStyle)
            .finally(() => dismissLoading())
            .then(() => {
                CommonService.presentToast("success", t("general_setup.save_success"), t("common.notification_header"))
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            })
    };

    const handleColorChange = () => {
        let value = form.getFieldsValue()
        if (value) {
            CommonService.setDealerStyle(value, true)
            form.setFieldsValue(value)
        }
    }

    const resetStyle = () => {
        form.setFieldsValue(Constant.defaultStyle)
        CommonService.setDealerStyle(Constant.defaultStyle as DealerStyle, true)
    }

    return (
        <div className="theme-setup">
            <Row>
                <Col {...firstCols} className="first-col">
                    <Form
                        {...layout}
                        form={form}
                        name="dealerStyleForm"
                        onFinish={onSubmit}
                    >
                        <Row>
                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.primary_color")}
                                    name="PrimaryColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.background_color")}
                                    name="BackgroundColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.background_color2")}
                                    name="BackgroundColor2" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.focus_color")}
                                    name="FocusColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.label_color")}
                                    name="LabelColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.text_color")}
                                    name="TextColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>

                            <Col {...colLayouts}>
                                <Form.Item
                                    label={t("general_setup.input_background_color")}
                                    name="InputBackgroundColor" colon={false} labelAlign="left"
                                    valuePropName="color"
                                >
                                    <ColorPicker onChange={handleColorChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            allowModify &&
                            <>
                                <Form.Item style={{ textAlign: 'right', marginTop: '30px', float: 'right' }}>
                                    <Button type="primary" htmlType="submit" className="primary-btn">
                                        {t("common.save")}
                                    </Button>
                                </Form.Item>

                                <Form.Item style={{ textAlign: 'right', marginTop: '30px', marginRight: '10px', float: 'right' }}>
                                    <Button type="text" className="btn" onClick={resetStyle}>
                                        {t("general_setup.reset")}
                                    </Button>
                                </Form.Item>
                            </>
                        }
                    </Form>
                    <Divider className="vertical-divider" type="vertical" />

                </Col>

                <Col {...secondCols}>
                    <Divider className="horizontal-divider" />
                    <div className="preview-title">Preview</div>
                    <div className="page-preview">
                        <ClientLayoutPreview><BookingDetail ignoreBackend={true}></BookingDetail></ClientLayoutPreview>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ThemeSetup
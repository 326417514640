import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import './bookingSummary.scss'
import { Card, Col, Row, Collapse, Tag, Button } from 'antd';
import { DownOutlined, MailFilled, PhoneFilled } from '@ant-design/icons';
import { useGlobalState } from '../../services/state';
import JobList from '../serviceDetail/jobList/jobList';
import { CommonService } from '../../services/services'
import ViewLocation from './viewLocation/viewLocation';
import { Constant } from '../../services/services'
const { Panel } = Collapse;

const BookingSummary = forwardRef((props: any, ref) => {
    const { t } = useTranslation();
    const [customerDetail] = useGlobalState('customerDetailData');
    const [customerType] = useGlobalState('customerType');
    const [vehicleDetail] = useGlobalState('vehicleDetailData');
    const [serviceDetailData] = useGlobalState('serviceDetailData');
    const [jobLineData] = useGlobalState('jobLineData');
    const [appointmentDetailData] = useGlobalState('appointmentDetailData');
    const [visibleModal, setVisibleModal] = useState(false);
    const [activeCollapseKeys, setActiveCollapseKeys] = useState(['1', '3']);
    const [visibleViewLocationModal, setVisibleViewLocationModal] = useState(false);
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');
    useImperativeHandle(ref, () => ({
        resetCollapse() {
            setActiveCollapseKeys(['1', '3'])
        }
    }));

    const handleCancel = () => {
        setVisibleModal(false)
    };

    const viewJobs = () => {
        setVisibleModal(true)
    }

    const onCollapseChange = (event: any) => {
        setActiveCollapseKeys(event)
    }

    const viewLocation = () => {
        setVisibleViewLocationModal(true)
    }

    const handleViewLocationCancel = () => {
        setVisibleViewLocationModal(false)
    };

    return (
        <div className={`booking-summary ${props.className}`}>
            <Card size="small" title={t("booking_summary.title")}>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1', '3']}
                    activeKey={activeCollapseKeys}
                    expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                    className="site-collapse-custom-collapse"
                    expandIconPosition="right"
                    onChange={onCollapseChange}
                >
                    <Panel header={t("booking_summary.customer_info")} key="1" className="site-collapse-custom-panel">
                        {                                                                              
                            customerType !== Constant.customerTypes.Organization && 
                        (                                                  
                                <Row>
                                    {
                                        process.env.REACT_APP_COUNTRY_CODE === Constant.countryCodes.JP && (
                                    <>
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.last_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.LastName || '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.first_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.FirstName || '_'}</label>
                                    </Col>
                                    </>
                                       )
                                    }
                                    {process.env.REACT_APP_COUNTRY_CODE !== Constant.countryCodes.JP && (
                                    <>                                    
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.first_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.FirstName || '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.last_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.LastName || '_'}</label>
                                    </Col>
                                    </>
                                       )
                                    }                                       
                                         {customerDetail?.Mobile &&
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: props.isConfirmDetailsPage ? 12 : 24 }}>
                                            <label className="text-field m-b-5"><PhoneFilled className="m-r-10" />{customerDetail?.Mobile || '_'}</label>
                                        </Col>
                                    }
                                    {customerDetail?.Email &&
                                        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: props.isConfirmDetailsPage ? 12 : 24 }}>
                                            <label className="text-field m-b-5"><MailFilled className="m-r-10" />{customerDetail?.Email || '_'}</label>
                                        </Col>
                                    }
                                </Row>
                            )
                        }
                        {
                            customerType === Constant.customerTypes.Organization && (
                                <Row>
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.first_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.ContactCustomer ? customerDetail?.ContactCustomer.FirstName : '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 12 }}>
                                        <label className="title-field uppercase-text">{t("customer_detail.last_name")}</label>
                                        <label className="text-field m-b-10">{customerDetail?.ContactCustomer ? customerDetail?.ContactCustomer.LastName : '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: props.isConfirmDetailsPage ? 12 : 24 }}>
                                        <label className="text-field m-b-5"><PhoneFilled className="m-r-10" />{customerDetail?.ContactCustomer && customerDetail?.ContactCustomer.Mobile ? customerDetail?.ContactCustomer.Mobile : '_'}</label>
                                    </Col>
                                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: props.isConfirmDetailsPage ? 12 : 24 }}>
                                        <label className="text-field m-b-5"><MailFilled className="m-r-10" />{customerDetail?.ContactCustomer &&  customerDetail?.ContactCustomer.Email ? customerDetail?.ContactCustomer.Email : '_'}</label>
                                    </Col>
                                </Row>
                            )
                        }
                    </Panel>
                    <Panel header={t("steps.your_vehicle")} key="2" className="site-collapse-custom-panel">
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("vehicle_detail.car_registration")}</label>
                                <label className="text-field m-b-10">{vehicleDetail?.RegoNo || '_'}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field uppercase-text">{t("vehicle_detail.make")}</label>
                                <label className="text-field m-b-10">{vehicleDetail?.Make?.MakeId || '_'}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field uppercase-text">{t("vehicle_detail.model")}</label>
                                <label className="text-field m-b-10">{vehicleDetail?.Description || '_'}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field uppercase-text">{t("vehicle_detail.year")}</label>
                                <label className="text-field m-b-10">{vehicleDetail?.ModelYear || '_'}</label>
                            </Col>
                            <Col xs={{ span: 12 }}>
                                <label className="title-field uppercase-text">{t("vehicle_detail.odometer_short")}</label>
                                <label className="text-field m-b-10">{vehicleDetail?.OdometerReading || '_'}</label>
                            </Col>
                        </Row>
                    </Panel>
                    <Panel header={t("steps.service_details")} key="3" className="site-collapse-custom-panel">
                        <Row className="align-center">
                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("location_management.header_dealership")}</label>
                                <label className="text-field m-b-10">{serviceDetailData.location?.DealershipName || '_'}</label>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("service_detail.service_location")}</label>
                                <a className='m-b-10' onClick={() => viewLocation()}>{serviceDetailData.location?.LocationName || '_'}</a>
                            </Col>

                            <Col xs={{ span: 18 }} md={{ span: props.isConfirmDetailsPage ? 8 : 18 }}>
                                <label className="title-field uppercase-text m-t-10">{t("service_detail.service_jobs")}</label>
                            </Col>
                            {//show number of job line
                                jobLineData.length > 1 &&
                                <Col xs={{ span: 6 }} md={{ span: props.isConfirmDetailsPage ? 8 : 6 }} className={`num-of-jobs ${props.isConfirmDetailsPage ? "" : "text-right"}`}>
                                    <Tag>{jobLineData.length}</Tag>
                                </Col>
                            }
                            {
                                jobLineData.length > 1 &&
                                <Col xs={{ span: 24 }} md={{ span: props.isConfirmDetailsPage ? 8 : 24 }} className={props.isConfirmDetailsPage ? "" : "m-t-10"}>
                                    <Button type="link" block onClick={viewJobs}>
                                        {t("booking_summary.view_jobs")}
                                    </Button>
                                </Col>
                            }

                            {
                                jobLineData.length == 1 &&
                                <Col xs={{ span: 24 }}>
                                    <Row className="job-line-row m-t-10">
                                        <Col xs={{ span: 6 }}>
                                            <label className="title-field">{t("service_detail.job")}</label>
                                        </Col>
                                        <Col xs={{ span: 18 }}>
                                            <label className="text-field">{jobLineData[0].JobLineId}</label>
                                        </Col>
                                        <Col xs={{ span: 6 }}>
                                            <label className="title-field">{t("service_detail.operation_code")}</label>
                                        </Col>
                                        <Col xs={{ span: 18 }}>
                                            <label className="text-field">{jobLineData[0].ServiceOperationCode?.ServiceCode || '_'}</label>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <label className="title-field">{t("service_detail.operation_description")}</label>
                                            <label className="text-field">{jobLineData[0].ServiceOperationCode?.Name}</label>
                                        </Col>
                                    </Row>
                                </Col>

                            }
                        </Row>
                    </Panel>
                    <Panel header={t("booking_summary.booking_details")} key="4" className="site-collapse-custom-panel">
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("booking_detail.booking_date")}</label>
                                <label className="text-field m-b-10">{CommonService.getDateString(appointmentDetailData?.DropOffTime,antLanguage) || '_'}</label>
                            </Col>

                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("booking_detail.drop_off")}</label>
                                <label className="text-field m-b-10">{CommonService.getTimeString(appointmentDetailData?.DropOffTime) || '_'}</label>
                            </Col>
                            {/* <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("booking_detail.pick_up")}</label>
                                <label className="text-field m-b-10">{CommonService.getTimeString(appointmentDetailData?.PickUpTime) || '_'}</label>
                            </Col> */}
                            <Col xs={{ span: 24 }}>
                                <label className="title-field uppercase-text">{t("booking_detail.note")}</label>
                                <label className="text-field m-b-10">{appointmentDetailData?.Note || '_'}</label>
                            </Col>
                        </Row>

                    </Panel>
                </Collapse>
            </Card>
            {
                visibleModal &&
                <JobList handleCancel={handleCancel} jobList={jobLineData}></JobList>
            }
            {
                visibleViewLocationModal &&
                <ViewLocation handleCancel={handleViewLocationCancel}></ViewLocation>
            }
        </div>
    )
})

export default BookingSummary
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LandingPage from "../landingPage/landingPage";
import ClientLayout from "./../../layouts/clientLayout/clientLayout";
import LoginLayout from "./../../layouts/loginLayout/loginLayout";
import PortalLayout from "./../../layouts/portalLayout/portalLayout";
import BookingSearch from "./../bookingSearch/bookingSearch";
import SelectCustomer from "./../customerSelect/customerSelect";
import { Constant, AuthService, CommonService } from "./../../services/services"
import CustomerDetail from "../customerDetail/customerDetail";
import VehicleDetail from "../vehicleDetail/vehicleDetail";
import ServiceDetail from "../serviceDetail/serviceDetail";
import BookingDetail from "../bookingDetail/bookingDetail";
import ConfirmationDetail from "../confirmationDetail/confirmationDetail";
import Login from "../login/login";
import UserManagement from "../userManageMent/userManagement";
import RoleManagement from "../roleManagement/roleManagement";
import Configuration from "./../configuration/configuration";
import ForgotPassword from "./../forgotPassword/forgotPassword";
import ResetPassword from "./../resetPassword/resetPassword";
import ConfirmEmail from "./../confirmEmail/confirmEmail";
import LocationManagement from "./../locationManagement/locationManagement";
import GeneralSetup from "./../generalSetup/generalSetup";
import EmailTemplateSetup from "./../emailTemplateSetup/emailTemplateSetup";
import ThemeSetup from "./../themeSetup/themeSetup";
import BookingManagement from "./../bookingManagement/bookingManagement";
import ServiceManagement from "./../serviceManagement/serviceManagement"
import {NotFoundPage, NoPermissionPage} from "../invalidPage/invalidPage";
import DealershipSelection from "../dealershipSelection/dealershipSelection";
import BookingSuccess from "../bookingSuccess/bookingSuccess";
import SmsTemplateSetup from "../smsTemplateSetup/smsTemplateSetup";
import AdvisorManagement from "../advisorManageMent/advisorManagement";

export default function Routes(props: any) {

    function appRoute(pathName: string, Component: any, LayoutComponent: any, isShowStepper: boolean = true, needAuthentication: boolean = false) {
        if (!needAuthentication) {
            return (
                <Route exact path={pathName} render={props => <LayoutComponent {...props} showStepper={isShowStepper}><Component /></LayoutComponent>} />
            )
        }
        else {
            return (
                <Route exact path={pathName} render={(props => AuthService.hasAuthorized() ?
                    (CommonService.hasAccessPageByPathName(pathName) ? <LayoutComponent {...props}><Component {...props} /></LayoutComponent> : <LayoutComponent {...props}><NoPermissionPage></NoPermissionPage></LayoutComponent>)
                    : <Redirect to={{ pathname: Constant.pages.Login, state: { from: props.location } }} />)} />
            )
        }
    }

    return (
        <Switch>

            {/* <Route exact path="/" component={LandingPage} /> */}
            <Route exact path="/" render={props => <ClientLayout {...props} showStepper={false}><DealershipSelection /></ClientLayout>} />

            {appRoute(Constant.pages.SearchBooking, BookingSearch, ClientLayout, false)}
            {appRoute(Constant.pages.SelectCustomer, SelectCustomer, ClientLayout, false)}
            {/* {appRoute(Constant.pages.DealershipSelection, DealershipSelection, ClientLayout, false)} */}
            {appRoute(Constant.pages.CustomerDetail, CustomerDetail, ClientLayout)}
            {appRoute(Constant.pages.VehicleDetail, VehicleDetail, ClientLayout)}
            {appRoute(Constant.pages.ServiceDetail, ServiceDetail, ClientLayout)}
            {appRoute(Constant.pages.BookingDetail, BookingDetail, ClientLayout)}
            {appRoute(Constant.pages.ConfirmDetail, ConfirmationDetail, ClientLayout)}
            {appRoute(Constant.pages.BookingSuccess, BookingSuccess, ClientLayout, false)}
            {appRoute(Constant.pages.Login, Login, LoginLayout)}
            {appRoute(Constant.pages.Config, Configuration, LoginLayout)}
            {appRoute(Constant.pages.ForgotPassword, ForgotPassword, LoginLayout)}
            {appRoute(Constant.pages.ResetPassword, ResetPassword, LoginLayout)}
            {appRoute(Constant.pages.ConfirmEmail, ConfirmEmail, LoginLayout)}
            {appRoute(Constant.pages.UserManagement, UserManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.RoleManagement, RoleManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.LocationManagement, LocationManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.AdvisorManagement, AdvisorManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.GeneralSetup, GeneralSetup, PortalLayout, false, true)}
            {appRoute(Constant.pages.ThemeSetup, ThemeSetup, PortalLayout, false, true)}
            {appRoute(Constant.pages.EmailTemplateSetup, EmailTemplateSetup, PortalLayout, false, true)}
            {appRoute(Constant.pages.BookingManagement, BookingManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.ServiceManagement, ServiceManagement, PortalLayout, false, true)}
            {appRoute(Constant.pages.NoPermission, NoPermissionPage, PortalLayout, false, true)}
            {appRoute(Constant.pages.SmsTemplateSetup, SmsTemplateSetup, PortalLayout, false, true)}
            <Route component={NotFoundPage} />
        </Switch>
    );
}
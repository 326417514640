import axios from 'axios';
import moment from 'moment';
import { ServiceBooking, BaseObjectManagement, CancelBookingRequest, CancellationCode, ConfirmBookingRequest } from '../models/appModels';
import { ServiceBookingHistory } from '../models/serviceBookingHistory';

import { axiosPortal } from './../services/authService'

const source: any[] = [];
let baseURL = "/Booking"
export const BookingManagementService = {
    getBookings: function (pageNumber: number, pageSize: number, sort: string, filterList: any[], filterBookingStatusCode: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        let filters = ""
        if (filterList && filterList.length > 0) {
            filters = JSON.stringify(filterList)
        }

        return axiosPortal.get<BaseObjectManagement<ServiceBooking>>(`${baseURL}/BookingList?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}&Filters=${filters}&FilterBookingStatusCode=${filterBookingStatusCode}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelBooking: function (cancelRequest: CancelBookingRequest) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Booking/CancelBooking`
        return axiosPortal.post(url, cancelRequest, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelDmsBooking: function (cancelRequest: CancelBookingRequest) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Booking/CancelDmsBooking`
        return axiosPortal.post(url, cancelRequest, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getCancellationCode: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Booking/CancellationCode`
        return axiosPortal.get<CancellationCode[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getServiceBookingSummary: function (filterList: any[]) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let filters = ""
        if (filterList && filterList.length > 0) {
            filters = JSON.stringify(filterList)
        }
        let url = `/Booking/GetBookingSummary?filters=${filters}`
        return axiosPortal.get<CancellationCode[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getServiceBookingHistory: function (bookingId: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetServiceBookingHistory?serviceBookingId=${bookingId}`

        return axiosPortal.get<ServiceBookingHistory[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    },
    confirmBooking: function (confirmRequest: ConfirmBookingRequest) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Booking/ConfirmBooking`
        return axiosPortal.post(url, confirmRequest, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateBookingManagement: function (bookingData: ServiceBooking) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        if (bookingData && bookingData.Appointment) {
            bookingData.Appointment.DropOffTime = moment(bookingData.Appointment.DropOffTime).format("YYYY-MM-DD HH:mm:ss")
            // if (bookingData.Appointment.PickUpTime)
            //     bookingData.Appointment.PickUpTime = moment(bookingData.Appointment.PickUpTime).format("YYYY-MM-DD HH:mm:ss")
            // if (bookingData.Appointment.OriginalDropOffTime)
            //     bookingData.Appointment.OriginalDropOffTime = moment(bookingData.Appointment.OriginalDropOffTime).format("YYYY-MM-DD HH:mm:ss")
        }

        let url = `/Booking/UpdateBookingManagement`
        return axiosPortal.post(url, bookingData, {
            cancelToken: cancelToken.token,
            paramsSerializer: function (params) {
                console.log(params)
                return params
            }
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
}
import React, { useEffect, useState } from 'react';
import './serviceBookingDetail.scss'
import { Modal, Row, Col, Table, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import EditableInput from '../../../components/editableInput/editableInput';
import { Constant } from '../../../services/constant';
import { ClientService, CommonService } from '../../../services/services'
import LoadingContext from '../../utils/loadingCompt/loadingContext'


const ServiceBookingDetail = (props: any) => {
    const { t } = useTranslation();

    const [phoneNumber, setPhoneNumber] = useState(props.bookingData?.Customer?.Mobile);
    const [email, setEmail] = useState(props.bookingData?.Customer?.Email);
    const [isCustomerUpdated, setIsCustomerUpdated] = useState(false);

    const columns = [
        {
            title: t('booking_management.header_line'),
            dataIndex: 'JobLineId',
            key: 'JobLineId',
            ellipsis: true,
            width: '50px'
        },
        {
            title: t('booking_management.header_schedule'),
            render: (value: any, record: any) => (
                <label>{record && record.ServiceOperationCodeId ? record.Description : ''}</label>
            ),
            ellipsis: true
        },
        {
            title: t('booking_management.header_other'),
            render: (value: any, record: any) => (
                <label>{record && !record.ServiceOperationCodeId ? record.Description : ''}</label>
            ),
            ellipsis: true
        }
    ]

    useEffect(() => {
        saveCustomer();
    }, [phoneNumber, email])


    const saveCustomer = () => {
        const customer = props.bookingData?.Customer;
        if (!customer) return;


        if (customer.Mobile != phoneNumber || customer.Email != email) {
            let originalMobile = customer.Mobile;
            customer.Mobile = phoneNumber;
            customer.Email = email;
            ClientService.addUpdateCustomer(customer)
                .then(() => {
                    setIsCustomerUpdated(true);
                    CommonService.presentToast('success', originalMobile == phoneNumber ? t("confirmation_detail.save_customer_email_success") : t("confirmation_detail.save_customer_mobile_success"), t("common.notification_header"))
                })
                .catch(error => {
                    if (error && error.response) {
                        CommonService.handleErrorResponse(error)
                    }
                });
        }

    };


    const closeForm = () => {
        props.handleCancel(isCustomerUpdated)
    }

    return (
        <Modal
            title={t("booking_management.title_booking") + ' ' + props.bookingData?.IdPrefix}
            visible={true}
            onOk={closeForm}
            confirmLoading={false}
            onCancel={closeForm}
            // okText={t("common.ok")}
            // cancelText={t("common.cancel")}
            //closable={false}
            className="service-modal"
            maskClosable={false}
            footer={null}
        >
            <div>
                <label className="text-field m-b-10">{t("booking_management.header_rego")}: {props.bookingData?.Vehicle?.RegoNo}</label>
            </div>
            {
                props.bookingData?.Customer?.IsOrganisation &&
                <>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_customer")}: {props.bookingData?.Driver?.Name}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_owner")}: {props.bookingData?.Vehicle?.OwnerCustomer?.Name}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_phone_no")}: {props.bookingData?.Driver?.Mobile}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_email")}: {props.bookingData?.Driver?.Email}</label>
                    </div>
                </>
            }
            {
                !props.bookingData?.Customer?.IsOrganisation &&
                <>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_customer")}: {props.bookingData?.Customer?.Name}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_owner")}: {props.bookingData?.Vehicle?.OwnerCustomer?.Name}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_phone_no")}: {props.bookingData?.Customer?.Mobile}</label>
                    </div>
                    <div>
                        <label className="text-field m-b-10">{t("booking_management.header_email")}: {props.bookingData?.Customer?.Email}</label>
                    </div>
                </>
            }
            {props.bookingData?.DmsBookingId && <div>
                <label className="text-field m-b-10">{t("booking_management.header_dms_booking_id")}: {props.bookingData?.DmsBookingId}</label>
            </div>
            }
            <div className="m-t-10">
                <label className="title-field bold-text">{t("booking_management.header_service")}</label>
            </div>

            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table size="small"
                        columns={columns}
                        dataSource={props.bookingData?.Jobs}
                        className="custom-table"
                        rowKey="Id"
                        pagination={false}
                        scroll={{ y: 240 }} />
                </Col>
            </Row>
        </Modal>
    )
}

export default ServiceBookingDetail
import axios from 'axios';
import { BaseObjectManagement,ServiceAdvisor } from '../models/appModels';
import { axiosPortal } from './authService'

const source: any[] = [];
let baseURL = "/Advisor"
export const AdvisorManagementService = {
    getAdvisors: function (pageNumber: number, pageSize: number, sort: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<BaseObjectManagement<ServiceAdvisor>>(`${baseURL}/GetAllAdvisors?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateAdvisor: function (data: ServiceAdvisor) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post<string>(`${baseURL}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }

}
import queryString from 'query-string';
import { History } from 'history'

function preserveQueryParameters(history: any, preserve: any, location: any) {
    const currentQuery = queryString.parse(history.location.search, {decode: false});
    if (currentQuery) {
        const preservedQuery = <any>{};
        for (let p of preserve) {
            let key = Object.keys(currentQuery).find(key => key.toLowerCase() === p.toLowerCase())
            if(key){
                const v = currentQuery[key];
                if (v) {
                    preservedQuery[p] = v;
                }
            }         
        }
        if (location.search) {
            Object.assign(preservedQuery, queryString.parse(location.search));
        }
        location.search = queryString.stringify(preservedQuery, { encode: false });
    }
    return location;
  }
  
  function createLocationDescriptorObject(location: any, state: any) {
    return typeof location === 'string' ? { pathname: location, state } : location;
  }
  
  export function createPreserveQueryHistory(createHistory: any, queryParameters: any) {
    return (options: any) => {
        const history = createHistory({
          ...options,
          basename: process.env.REACT_APP_BASENAME
        });
        const oldPush = history.push, oldReplace = history.replace;
        history.push = (path: any, state: any) => oldPush.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
        history.replace = (path: any, state: any) => oldReplace.apply(history, [preserveQueryParameters(history, queryParameters, createLocationDescriptorObject(path, state))]);
        return history;
    };
  }
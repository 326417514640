import React, { forwardRef } from 'react';
import './customerSelect.scss';
import { Row, Col } from 'antd';
import { Constant } from '../../services/services';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../services/state';
import { UserOutlined, BankOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
// import LoadingContext from './../utils/loadingCompt/loadingContext';

const CustomerSelect = forwardRef((props: any, ref) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [, setCustomerType] = useGlobalState('customerType');
    const selectCustomerType = (type: string) => {
        setCustomerType(type)
        history.push(Constant.pages.SearchBooking);
    }
    return (
        <div className="select-customer-page">
            <div className="service-selection">
                <h2 className="text-center" dangerouslySetInnerHTML={{ __html: t("customer_select.person_or_org") }}></h2>
                <Row className="service">
                    <Col xs={{ span: 22, offset: 1 }} sm={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }}>
                        <Row>
                            <Col xs={{ span: 24 }} sm={{ span: 11 }}>
                                <div className="service-section" onClick={() => selectCustomerType(Constant.customerTypes.Person)}>
                                    <UserOutlined />
                                    <p>{t("customer_select.person")}</p>
                                </div>
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}>
                                <div className="service-section" onClick={() => selectCustomerType(Constant.customerTypes.Organization)}>
                                    <BankOutlined />
                                    <p>{t("customer_select.organization")}</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
})

export default CustomerSelect
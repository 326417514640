import EmailTemplateSetup from "../pages/emailTemplateSetup/emailTemplateSetup";
import th_TH from 'antd/es/locale/th_TH';
import en_US from 'antd/es/locale/en_US';
import ja_JP from 'antd/es/locale/ja_JP';
import moment from 'moment';
import 'moment/locale/zh-cn';

export const ServerCodes = {
    None: -1,
    Oceania: 1,
    Oceania_Mobile: 2,
    Sea: 3,
    Africa: 4,
    Eu: 5,
    Me: 6,
    Oceania_Aws: 7
}

export const Constant = {
    bookingStep: {
        vehicleCustomerSearch: -1,
        customerDetail: 0,
        vehicleDetail: 1,
        serviceDetail: 2,
        bookingDetail: 3,
        confirmDetail: 4,
        bookingSuccess: 5
    },
    pages: {
        //LandingPage: "/",
        SearchBooking: "/search-booking",
        SelectCustomer: "/select-customer",
        CustomerDetail: '/customer-detail',
        VehicleDetail: '/vehicle-detail',
        BookingDetail: '/booking-detail',
        ServiceDetail: '/service-detail',
        ConfirmDetail: '/confirmation-detail',
        Login: '/login',
        Config: '/config',
        ForgotPassword: '/forgot-password',
        ResetPassword: '/reset-password',
        UserManagement: '/user-management',
        RoleManagement: '/role-management',
        UserConfiguration: '/user-configuration',
        LocationManagement: '/location-management',
        GeneralSetup: '/general-setup',
        BookingManagement: '/booking-management',
        ServiceManagement: '/service-management',
        NoPermission: '/no-permission',
        ConfirmEmail: '/confirm-email',
        ThemeSetup: '/theme-setup',
        EmailTemplateSetup: '/email-template',
        // DealershipSelection: '/dealership-selection'
        DealershipSelection: '/',
        BookingSuccess: '/booking-success',
        SmsTemplateSetup: '/sms-template',
        AdvisorManagement: '/advisor-management',
    },
    errorCodes: {
        InvalidReservationToken: 600,
        ChangedReservationToken: 601,
        MaxReservationReach: 602,
        BadRequest: 400
    },
    bookingStatusCode: {
        All: 0,
        Active: 1,
        Cancelled: 2,
        Complete: 3,
        Pending:4
    },
    serverList: [
        {
            Description: "servers.none",
            Url: "",
            Code: ServerCodes.None
        },
        {
            Description: "servers.oceania",
            Url: "https://interfaces.titandms.net.au/api/v1",
            Code: ServerCodes.Oceania
        },
        {
            Description: "servers.oceania_mobile",
            Url: "https://interfaces.titandms.net.au:8091/api/v1",
            Code: ServerCodes.Oceania_Mobile
        },
        {
            Description: "servers.oceania_aws",
            Url: "https://au-web-01-api.titandms.net.au:57865/api/v1",
            Code: ServerCodes.Oceania_Aws
        },
        {
            Description: "servers.south_east_asia",
            Url: "https://api.titandms.co.th:57865/api/v1",
            Code: ServerCodes.Sea
        },
        {
            Description: "servers.africa",
            Url: "https://interfaces.v10analytics.co.za/api/v1",
            Code: ServerCodes.Africa
        },
        {
            Description: "servers.europe",
            Url: "https://api.titandms.co.uk:57865/api/v1",
            Code: ServerCodes.Eu
        },
        {
            Description: "servers.middle_east",
            Url: "https://api.titandms.ae:57865/api/v1",
            Code: ServerCodes.Me
        }
    ],
    appPageIds: {
        GeneralSetup: "GENERAL_SETUP",
        LocationManagement: "LOCATION_MANAGEMENT",
        ServiceManagement: "SERVICE_MANAGEMENT",
        UserManagement: "USER_MANAGEMENT",
        BookingManagement: "BOOKING_MANAGEMENT",
        AdvisorManagement: "ADVISOR_MANAGEMENT"
    },
    statusCode: {
        Active: 1,
        Inactive: 2
    },
    pageSize: 10,
    cellType: {
        Input: 1,
        TimeRange: 2,
        Checkbox: 3
    },
    daysOfWeek: [
        "location_management.sunday",
        "location_management.monday",
        "location_management.tuesday",
        "location_management.wednesday",
        "location_management.thursday",
        "location_management.friday",
        "location_management.saturday",
    ],
    dateFormat: "DD/MM/YYYY",
    limitLength: {
        length512: 512,
        length256: 256
    },
    dmsIntegrationOnValue: "1",
    defaultStyle: {
        ThemeName: "Main Theme",
        PrimaryColor: "#F58020",
        BackgroundColor: "#fff",
        BackgroundColor2: "#fff",
        FocusColor: "#40a9ff",
        TextColor: "#222529",
        LabelColor: "#343C5C",
        InputBackgroundColor: "#F3F6F9"
    },
    cssVariables: {
        ModalHeightScrollList: "--modal-height-scroll-list"
    },
    modalClass: {
        Content: "ant-modal-content",
        Header: "ant-modal-header",
        Footer: "ant-modal-footer"
    },
    emailDefaultDesign: {
        "counters": {
            "u_column": 1,
            "u_row": 1
        },
        "body": {
            "rows": [
                {
                    "cells": [
                        1
                    ],
                    "columns": [
                        {
                            "contents": [],
                            "values": {
                                "backgroundColor": "",
                                "padding": "0px",
                                "border": {},
                                "_meta": {
                                    "htmlID": "u_column_1",
                                    "htmlClassNames": "u_column"
                                }
                            }
                        }
                    ],
                    "values": {
                        "displayCondition": null,
                        "columns": false,
                        "backgroundColor": "",
                        "columnsBackgroundColor": "",
                        "backgroundImage": {
                            "url": "",
                            "fullWidth": true,
                            "repeat": false,
                            "center": true,
                            "cover": false
                        },
                        "padding": "0px",
                        "hideDesktop": false,
                        "_meta": {
                            "htmlID": "u_row_1",
                            "htmlClassNames": "u_row"
                        },
                        "selectable": true,
                        "draggable": true,
                        "duplicatable": true,
                        "deletable": true
                    }
                }
            ],
            "values": {
                "backgroundColor": "#e7e7e7",
                "backgroundImage": {
                    "url": "",
                    "fullWidth": true,
                    "repeat": false,
                    "center": true,
                    "cover": false
                },
                "contentWidth": "500px",
                "contentAlign": "center",
                "fontFamily": {
                    "label": "Arial",
                    "value": "arial,helvetica,sans-serif"
                },
                "preheaderText": "",
                "linkStyle": {
                    "body": true,
                    "linkColor": "#0000ee",
                    "linkHoverColor": "#0000ee",
                    "linkUnderline": true,
                    "linkHoverUnderline": true
                },
                "_meta": {
                    "htmlID": "u_body",
                    "htmlClassNames": "u_body"
                }
            }
        },
        "schemaVersion": 5
    },
    requiredVinLength: 17,
    phoneNoLength: 10,
    bookingActionType: {
        Submit: 1,
        Update: 2,
        Cancel: 3, 
        Confirm: 4,
        CancelFromDms: 5,
        CompleteFromDms: 6
    },
    inputType: {
        Email: "email"
    },
    countryCodes:{
        TH:"TH",
        JP: "JPN"
    },
    languageCodes:[
        {
            Code: 'th',
            Description: 'th',
            AntdLanguage: th_TH,
           DateFormat: "DD/MM/YYYY"
        },
        {
            Code: 'en',
            Description: 'en',
            AntdLanguage: en_US,
            DateFormat: "DD/MM/YYYY"
        },
        {
            Code: 'ja',
            Description: 'ja',
            AntdLanguage: ja_JP,
            DateFormat: "YYYY/MM/DD"
        }
    ],
    customerTypes: {
        Person: "person",
        Organization: "organization"
    },
    translateServerUrl: 'https://translate.lotigara.ru'
}

export declare type IconType = 'success' | 'info' | 'error' | 'warning';

export declare type AlignType = 'left' | 'center' | 'right';
export declare type PaginationSize = 'default' | 'small';
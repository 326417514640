import { useEffect, useRef } from "react";

type Func = (...args: any[]) => any;

function useDebounceFnc<T extends Func>(
  callback: T,
  delay: number
): [T, () => void] {
  const timeoutRef = useRef<number | undefined>();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function debouncedFunction(...args: Parameters<T>) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      callback(...args);
    }, delay);
  }

  function cancelDebounce() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  return [debouncedFunction as T, cancelDebounce];
}

export default useDebounceFnc;

import React, { useContext } from 'react'
import LoadingContext from '../utils/loadingCompt/loadingContext'
import './spinner.scss'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

const Spinner = (props: any) => {
  const { isLoading } = useContext(LoadingContext)

  return (
    <>
      {isLoading && (
        <div className="spinner">
          <Loader
            type="BallTriangle"
            color={props.color}
            height={80}
            width={80}
          />
        </div>
      )}
    </>
  )
}

export default Spinner
import axios from 'axios';
import moment from 'moment'

import {
  DealerStyle,
  BookingData,
  ServiceOperationCode,
  TimeslotReservation,
  UnavailableDate,
  Location, Model, Make,
  CountryState,
  ServiceAdvisor,
  CancelBookingRequest,
  CancellationCode,
  Customer,
  OTPVerificationRequest,
  OTPVerificationResponse,
  OTPConfiguration,
  OTPValidateRequest,
  OTPValidateResponse,
  Bureaucracy,
  AreaCode
} from '../models/appModels'

import { Constant, StorageService } from './services'

const CancelToken = axios.CancelToken;
const source: any[] = [];
export const axiosClient = axios.create();

export const ClientService = {
  registerIntercepter: function () {
    axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
    axiosClient.interceptors.request.use(function (config) {
      // Do something before request is sent
      let key = StorageService.getClientKey()
      config.headers["Authorization"] = "Basic " + (key ? key : process.env.REACT_APP_KEY)
      config.headers["Language"] = StorageService.getLanguage()
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    axiosClient.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
  },
  registerIntercepterWithAuth: function (history: any) {
    axiosClient.defaults.baseURL = process.env.REACT_APP_BASE_URL
    axiosClient.interceptors.request.use(function (config) {
        let authData = StorageService.getAuthData()
        if (authData && config.url!.indexOf("token") === -1 && config.url!.indexOf("ForgotPassword") === -1
            && config.url!.indexOf("ResetPassword") === -1) {
            config.headers["Authorization"] = `bearer ${authData.token}`
        }

        if (config.url!.indexOf("ForgotPassword") >= 0) {
            config.headers= {
                DealerId: StorageService.getDealerIdData()
            }
        }

        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    axiosClient.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        if (error.response && error.response.status === 401) {
            history.push(Constant.pages.Login);
        }

        return Promise.reject(error);
    });
},
  getTest: function () {
    return axiosClient.get("https://localhost:44351/sample")
  },
  getDealerStyle: function () {
    // let cancelToken = axios.CancelToken.source()
    // source.push(cancelToken)
    let url = "/Dealership/DealerStyle"
    // return axios.get<DealerStyle>(url, {
    //   cancelToken: cancelToken.token
    // }).finally(() => {
    //   let index = source.indexOf(cancelToken)
    //   if( index > -1){
    //     source.splice(index, 1)
    //   }
    // })
    return axiosClient.get<DealerStyle>(url)
  },
  getUnavailalbleBookingDays: function (locationId: number) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/UnavailableBookingDays?locationId=${locationId}`
    return axiosClient.get<UnavailableDate>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getTimeSlotForBooking: function (locationId: number, bookingDate: Date, reservationToken: string | null = null) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/TimeSlots?locationId=${locationId}&bookingDate=${bookingDate.getFullYear()}-${bookingDate.getMonth() + 1}-${bookingDate.getDate()}`
    if(reservationToken){
      url += `&reservationToken=${reservationToken}`
    }

    return axiosClient.get<Date[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  addUpdateTimeSlotReservation: function (reservation: TimeslotReservation) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)

    if (reservation) {
      reservation.DropOffTime = moment(reservation.DropOffTime).format("YYYY-MM-DD HH:mm:ss")
      if (reservation.PickUpTime) {
        reservation.PickUpTime = moment(reservation.PickUpTime).format("YYYY-MM-DD HH:mm:ss")
      }
    }

    let url = "/Booking/TimeslotReservation"
    return axiosClient.post<string>(url, reservation, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  searchBooking: function (regoNo: string, phoneNo: string, dealershipId?: number, isOrganisation?: boolean) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `Booking/SearchBooking?regoNo=${encodeURIComponent(regoNo)}&phoneNo=${encodeURIComponent(phoneNo)}`
    if (dealershipId && dealershipId > 0) {
      url += `&dealershipId=${dealershipId}`
    }
    if (isOrganisation) {
      url += `&isOrganisation=true`
    }

    return axiosClient.get<BookingData>(url, {
      cancelToken: cancelToken.token
    })
      .then(result => {
        return result?.data
      }).finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
  searchCustomer: function (phoneNo: string, dealershipId?: number, isOrganisation?: boolean) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `Booking/SearchCustomer?phoneNo=${encodeURIComponent(phoneNo)}`
    if (dealershipId && dealershipId > 0) {
      url += `&dealershipId=${dealershipId}`
    }
    if (isOrganisation) {
      url += `&isOrganisation=true`
    }
    return axiosClient.get<Customer>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  searchVehicle: function (regoNo: string, phoneNo: string, dealershipId?: number, isOrganisation?: boolean) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `Booking/SearchVehicle?regoNo=${encodeURIComponent(regoNo)}&phoneNo=${encodeURIComponent(phoneNo)}`
    if (dealershipId && dealershipId > 0) {
      url += `&dealershipId=${dealershipId}`
    }
    if (isOrganisation) {
      url += `&isOrganisation=true`
    }

    return axiosClient.get<BookingData>(url, {
      cancelToken: cancelToken.token
    })
      .then(result => {
        return result?.data
      }).finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
  getServiceOperationCodes: function (makeId: string, locationCode?: string) {
    let url = "/Booking/ServiceOperationCodes"
    if (makeId) {
      url += `?makeId=${makeId}`
    }
    if (locationCode) {
      if (url.indexOf("?") >= 0) {
        url += `&locationCode=${locationCode}`
      } else {
        url += `?locationCode=${locationCode}`
      }
    }
    if (url.indexOf("?") >= 0) {
      url += `&includeNonMake=true`
    } else {
      url += `?includeNonMake=true`
    }

    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    return axiosClient.get<ServiceOperationCode[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getLocations: function () {
    let url = "/Booking/Locations"
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    return axiosClient.get<Location[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getMakes: function () {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = "/Vehicle/MakeFromModels?isRequireModel=false"
    return axiosClient.get<Make[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getBMWThailandMakes: function () {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = "/Vehicle/Makes"
    return axiosClient.get<Make[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getMakesByCountry(countryCode?:string)
  {
    if(countryCode == Constant.countryCodes.TH)
      return this.getBMWThailandMakes()

     return this.getMakes();
  },
  getModels: function (makeId: string) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Vehicle/Models?makeId=${makeId}`
    return axiosClient.get<Model[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  addUpdateBooking: function (bookingData: BookingData) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)

    if (bookingData && bookingData.Booking && bookingData.Booking.Appointment) {
      bookingData.Booking.Appointment.DropOffTime = moment(bookingData.Booking.Appointment.DropOffTime).format("YYYY-MM-DD HH:mm:ss")
      if (bookingData.Booking.Appointment.PickUpTime) {
        bookingData.Booking.Appointment.PickUpTime = moment(bookingData.Booking.Appointment.PickUpTime).format("YYYY-MM-DD HH:mm:ss")
      }
    }

    return axiosClient.post("/Booking", bookingData, {
      cancelToken: cancelToken.token,
      paramsSerializer: function (params) {
        console.log(params)
        return params
      }
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getServiceAdvisors: function (locationId: number) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/GetServiceAdvisorsByLocationId?locationId=${locationId}`
    return axiosClient.get<ServiceAdvisor[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  cancelBooking: function (cancelRequest: CancelBookingRequest) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/CancelBooking`
    return axiosClient.post<ServiceAdvisor[]>(url, cancelRequest, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  cancelDmsBooking: function (cancelRequest: CancelBookingRequest) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/CancelDmsBooking`
    return axiosClient.post<ServiceAdvisor[]>(url, cancelRequest, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getCancellationCode: function () {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Booking/CancellationCode`
    return axiosClient.get<CancellationCode[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getCustomersByName: function (firstName: string, lastName: string, dealershipId?: number, phoneNumber?: string, isExcludeOrganisation: boolean = false) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)

    let url = `Customer/GetCustomerByName?isExcludeOrganisation=${isExcludeOrganisation}`
    if(firstName){
      url += `&firstName=${firstName}`
    }

    if(lastName){
      url += `&lastName=${lastName}`
    }
    
    if (dealershipId && dealershipId > 0) {
      url += `&dealershipId=${dealershipId}`
    }

    if(phoneNumber){
      url += `&phoneNumber=${phoneNumber}`
    }

    return axiosClient.get<Customer[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  addUpdateCustomer: function (customer: Customer) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)

    return axiosClient.post("Customer", customer, {
      cancelToken: cancelToken.token,
      paramsSerializer: function (params) {
        console.log(params)
        return params
      }
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getCountryStates: function (countryCode?:string) {
    let url = `/Location/CountryStates?countryCode=${countryCode}`
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    return axiosClient.get<CountryState[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  cancelRequest: function () {
    if (source && source.length > 0) {
      for (var i = 0; i < source.length; i++) {
        source[i].cancel('Operation canceled.')
      }
      source.splice(0, source.length)
    }
  },
  getServiceOperationCodesByLocationId: function (makeId: string, locationId?: number) {
    let url = "/Booking/ServiceOperationCodesByLocationId"
    if (makeId) {
      url += `?makeId=${makeId}`
    }
    if (locationId) {
      if (url.indexOf("?") >= 0) {
        url += `&locationId=${locationId}`
      } else {
        url += `?locationId=${locationId}`
      }
    }
    if (url.indexOf("?") >= 0) {
      url += `&includeNonMake=true`
    } else {
      url += `?includeNonMake=true`
    }

    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    return axiosClient.get<ServiceOperationCode[]>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  getPrefix: function () {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `/Dealership/Prefix`
    return axiosClient.get<string>(url, {
      cancelToken: cancelToken.token
    }).finally(() => {
      let index = source.indexOf(cancelToken)
      if (index > -1) {
        source.splice(index, 1)
      }
    })
  },
  sendOTPVerification: function (request: OTPVerificationRequest) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken);
    let url = `/Booking/SendOTPVerification`
    return axiosClient.post<OTPVerificationResponse>(url, request, { cancelToken: cancelToken.token })
      .finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
  validateOTP: function (request: OTPValidateRequest) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken);
    let url = `/Booking/ValidateOTP`
    return axiosClient.post<OTPValidateResponse | null>(url, request, { cancelToken: cancelToken.token })
      .finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
  getBureaucracies: function (dealershipId?: number) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `Booking/Bureaucracies`
    if (dealershipId && dealershipId > 0) {
      url += `?dealershipId=${dealershipId}`
    }
    return axiosClient.get<Bureaucracy[]>(url, {
      cancelToken: cancelToken.token
    })
      .then(result => {
        return result?.data
      }).finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
  getAreaCodeByBureaucracy: function (bureaucracyCode: string, dealershipId?: number) {
    let cancelToken = axios.CancelToken.source()
    source.push(cancelToken)
    let url = `Booking/AreaCode`
    if (dealershipId && dealershipId > 0) {
      url += `?dealershipId=${dealershipId}`
    }
    url += `&bureaucracyCode=${bureaucracyCode}`

    return axiosClient.get<AreaCode[]>(url, {
      cancelToken: cancelToken.token
    })
      .then(result => {
        return result?.data
      }).finally(() => {
        let index = source.indexOf(cancelToken)
        if (index > -1) {
          source.splice(index, 1)
        }
      })
  },
}
import React, { useEffect, useState } from 'react';
import './jobList.scss'
import { ModalOptions, RepairOrderJob } from '../../../models/appModels';
import { useTranslation } from 'react-i18next';
import { Form, Input, Modal, Select, Card, Row, Col, List, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export interface JobListModalOptions extends ModalOptions<RepairOrderJob> {
    jobList: RepairOrderJob[]
}

const JobList = (props: JobListModalOptions) => {
    const { t } = useTranslation();
    const [jobData, setJobData] = React.useState<RepairOrderJob[]>([])

    const closeForm = () => {
        props.handleCancel()
    }

    const onSearch = (e: any) => {
        let value = String(e.target.value)
        let jobs = props.jobList.filter(x => x.Description.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        setJobData(jobs)
    }

    useEffect(() => {
        let jobs = props.jobList.map(x => Object.assign({}, x))
        setJobData(jobs)
    }, [])

    return (
        <Modal
            title={t("service_detail.service_jobs")}
            visible={true}
            confirmLoading={false}
            onCancel={closeForm}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <Input className="search-input" placeholder={t("vehicle_detail.filter_by_code")} onInput={onSearch} prefix={<SearchOutlined />} />
            <List
                dataSource={jobData}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                renderItem={(item, i) => (
                    <List.Item>
                        <Card className="card-selection card-details" key={i}>
                            <Row>
                                <Col xs={{ span: 3 }}>
                                    <label className="title-field">{t("service_detail.job")}</label>
                                </Col>
                                <Col xs={{ span: 4 }}>
                                    <label className="text-field semi-bold-text">{item.JobLineId}</label>
                                </Col>
                                <Col xs={{ span: 3 }}>
                                    <label className="title-field">{t("service_detail.operation_code")}</label>
                                </Col>
                                <Col xs={{ span: 14 }}>
                                    <label className="text-field truncate semi-bold-text">{item.ServiceOperationCode?.ServiceCode}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("service_detail.operation_description")}</label>
                                    <label className="text-field truncate semi-bold-text">{item.Description}</label>
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default JobList
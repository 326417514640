import React, { useRef, useEffect } from "react";

export function useOutFocus(ref: any, ref2: any, handler: any) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target) && (!ref2 || (ref2.current && !ref2.current.contains(event.target)))) {
                handler()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
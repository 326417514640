import { createGlobalState } from 'react-hooks-global-state';
import { Appointment, BookingData, Customer, Location, Make, Model, ModelType, RepairOrderJob, Vehicle, ServiceAdvisor, Dealership, DealerStyle, BookingSearchRequest, Bureaucracy } from '../models/appModels'
import { Locale } from 'antd/es/locale-provider';
import en_US from 'antd/es/locale/en_US';

export const { useGlobalState } = createGlobalState({
    reservationToken: "",
    appointmentDetailData: <Appointment>{},
    serviceDetailData: {
        location: <Location>{},
        serviceAdvisor: <ServiceAdvisor | null>{}
    },
    isInit: false,
    jobLineData: <RepairOrderJob[]>[],
    customerDetailData: <Customer>{},
    vehicleDetailData: <Vehicle>{},
    originalVehicleMake: <number | undefined>undefined,
    makeList: <Make[]>[],
    BureaucracyList: <Bureaucracy[]>[],
    locationList: <Location[]>[],
    modelTypeList: <ModelType[]>[],
    modelList: <Model[]>[],
    modelYeahList: <number[]>[],
    bookingData: <BookingData>{
        Booking: {},
        IsFromDMS: false
    },
    countryStates: <{ value: string }[]>[],
    dealerships: <Dealership[]>[],
    dealerStyle: <DealerStyle>{},
    dealershipId: <number | undefined>undefined,
    ownedVehiclesData: <Vehicle[]>[],
    logoUrl: "",
    bookingSearchData: <any>{},
    languageList: <string[]>[],
    prefix: "",
    antLanguage: en_US,
    dateFormat:"DD/MM/YYYY",
    customerType: <string | null>null,
    customerPhoneNumber: <string | null>null,
    bookingSearchDataRequest: <BookingSearchRequest>{}
});
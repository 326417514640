import React, { useEffect, useContext } from 'react';
import './resetPassword.scss';
import './../../layouts/loginLayout/loginLayout.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Constant, AuthService, CommonService } from './../../services/services'
import { SettingFilled, LeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { useLocation } from "react-router-dom";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ResetPassword = (props: any) => {
    console.log(props)
    const { t } = useTranslation();
    let history = useHistory();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const location = useLocation();

    useEffect(() => {
        let code = location.search.match("Token=(.*)&Email=")
        let email = location.search.match("&Email=(.*)&DealerId=")
        let dealerId = location.search.match("&DealerId=(.*)")
        if ( !code || !code[1] || !email || !email[1] || !dealerId || !dealerId[1]) {
            CommonService.presentToast('error', "No token found.", t("common.notification_header"))
            history.push(Constant.pages.ForgotPassword)
        }
    }, [])

    const onSubmit = (data: any) => {
        if (data) {
            showLoading()
            let code = location.search.match("Token=(.*)&Email=")
            let email = location.search.match("&Email=(.*)&DealerId=")
            let dealerId = location.search.match("&DealerId=(.*)")

            AuthService.resetPassword(email![1], data.NewPassword, code![1], dealerId![1]).finally(() => dismissLoading()).then(() => {
                CommonService.presentToast('success', t("login_page.change_password_success"), t("common.notification_header"))
                history.push(Constant.pages.Login)
            }).catch(error => {
                CommonService.handleErrorResponse(error)
            })
        }

    };

    const goBack = () => {
        history.push(Constant.pages.Login);
    }

    return (
        <div className="reset-password-page">
            <p className="page-title">{t('login_page.change_password')}</p>
            <Form
                {...layout}
                form={form}
                name="resetPasswordForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={t("login_page.new_password")}
                            name="NewPassword" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("login_page.new_password_required") }]}
                        >
                            <Input.Password className="no-border" />
                        </Form.Item>
                        <Form.Item
                            label={t("login_page.new_password_confirm")}
                            name="NewPasswordConfirm" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("login_page.new_password_confirm_required") },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value) {
                                        let password = getFieldValue('NewPassword')
                                        if (password != value) {
                                            return Promise.reject(t("login_page.new_password_not_match"));
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}
                        >
                            <Input.Password className="no-border" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="page-footer">
                        <Button className="primary-btn submit-btn" htmlType="submit">
                            {t("common.submit")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ResetPassword
import React, { useEffect, useContext, useState } from 'react';
import './confirmEmail.scss';
import './../../layouts/loginLayout/loginLayout.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Constant, AuthService, CommonService } from './../../services/services'
import { SettingFilled, LeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { useLocation } from "react-router-dom";

const ConfirmEmail = (props: any) => {
    const { t } = useTranslation();
    let history = useHistory();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const location = useLocation();

    const statuses = Object.freeze({
        processing: 0,
        success: 1,
        fail: 2,
        resendEmailSuccess: 3
    })
    const [status, setStatus] = useState<number>(statuses.processing)

    useEffect(() => {
        setTimeout(() => {
            let code = location.search.match("Code=(.*)&Email=")
            let email = location.search.match("&Email=(.*)&DealerId=")
            let dealerId = location.search.match("&DealerId=(.*)")
            if (!code || !code[1] || !email || !email[1] || !dealerId || !dealerId[1]) {
                setStatus(statuses.fail)
                return
            }
            setStatus(statuses.processing)
            showLoading()
            AuthService.confirmEmail(code[1], email[1], dealerId[1])
                .finally(() => dismissLoading())
                .then(() => setStatus(statuses.success))
                .catch(error => {
                    setStatus(statuses.fail)
                })
        }, 200);
    }, [])

    const onSubmit = (data: any) => {
        if (status == statuses.success) {
            history.push(Constant.pages.Login)
        } else {
            showLoading()

            let code = location.search.match("Code=(.*)&Email=")
            let email = location.search.match("&Email=(.*)&DealerId=")
            let dealerId = location.search.match("&DealerId=(.*)")

            if (!code || !code[1] || !email || !email[1] || !dealerId || !dealerId[1]) {
                dismissLoading()
                return
            }
            AuthService.resendConfirmEmail(code[1], email[1], dealerId[1])
                .finally(() => dismissLoading())
                .then(() => {
                    setStatus(statuses.resendEmailSuccess)
                })
                .catch(error => {
                    CommonService.handleErrorResponse(error)
                })
        }
    };

    return (
        <div className="confirm-email-page">
            <p className="page-title">{t("confirm_email.title")}</p>
            {status == statuses.success && <p className="success-text">{t("confirm_email.confirm_success")}</p>}
            {status == statuses.fail && <p className="success-text">{t("confirm_email.confirm_fail")}</p>}
            {status == statuses.processing && <p className="success-text">{t("confirm_email.confirm_process")}</p>}
            {status == statuses.resendEmailSuccess && <p className="success-text">{t("confirm_email.resend_email_success")}</p>}
            {
                status != statuses.processing && status != statuses.resendEmailSuccess &&
                <span className="hyperlink" style={{ float: "right" }} onClick={() => onSubmit(Constant.pages.ForgotPassword)}>
                    {status == statuses.success ? t('confirm_email.to_login') : t('confirm_email.resend_email')}
                </span>
            }
        </div>
    )
}

export default ConfirmEmail
import React, { useState, useEffect } from 'react';
import './configuration.scss';
import './../../layouts/loginLayout/loginLayout.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { CommonService, Constant, ServerCodes, StorageService } from './../../services/services'
import { LeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import { Server } from 'http';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const Configuration = (props: any) => {
    const { t } = useTranslation();
    let history = useHistory();
    const [form] = Form.useForm();
    const [showWebServer, setShowWebServer] = useState(false)

    useEffect(() => {
        let dealerId = StorageService.getDealerIdData()
        if(dealerId){
            form.setFieldsValue({
                DealerId: dealerId
            })
        }      
    }, [])

    const onSubmit = (data: any) => {
        if(data && data.DealerId){
            StorageService.setDealerIdData(data.DealerId)
            history.push(Constant.pages.Login);
        }
    };

    const onServerCodeChange = (newValue: any) => {
        if (newValue) {
            if (isCustomURL(newValue)) {
                form.setFieldsValue({
                    ["Server"]: ""
                });
            }
            setShowWebServer(isCustomURL(newValue))
        }
    }

    const isCustomURL = (value: any) => {
        if (value == ServerCodes.None)
            return true
        return false
    }

    const goBack = () => {
        history.push(Constant.pages.Login);
    }

    return (
        <div className="config-page">
            <p className="page-title"><span className="back-icon"><LeftOutlined onClick={goBack} /></span>{t('configuration.dealer_config')}</p>
            <Form
                {...layout}
                form={form}
                name="configForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label={t("configuration.dealer_id")}
                            name="DealerId" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("configuration.dealer_id_required") }]}
                            normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                        >
                            <Input />
                        </Form.Item>
                        {/* <Form.Item
                            label={t("configuration.server")}
                            name="ServerCode" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("configuration.server_required") }]}
                        >
                            <Select onChange={onServerCodeChange}>
                                {
                                    Constant.serverList.map((n: any, index: any) => <Option key={index} value={n.Code}>{t(n.Description)}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        {showWebServer && <Form.Item
                            label={t("configuration.web_server")}
                            name="Server" colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("configuration.web_server_required") }]}
                        >
                            <TextArea autoSize={{ minRows: 1, maxRows: 5 }} />
                        </Form.Item>} */}
                    </Col>
                </Row>
                <Row className="page-footer">
                    <Col span={10}>
                        <Button htmlType="button" className="full-width" onClick={goBack}>
                            {t("common.cancel")}
                        </Button>
                    </Col>
                    <Col span={14}>
                        <Button className="primary-btn submit-btn" htmlType="submit">
                            {t("common.save")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Configuration
import axios from 'axios';
import { BaseObjectManagement, Role, RoleCategory, User } from '../models/appModels';
import { axiosPortal } from './../services/authService'

const source: any[] = [];
let baseURL = "/UserManagement"
export const UserManagementService = {
    getRoles: function (pageNumber: number, pageSize: number, sort: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<BaseObjectManagement<Role>>(`${baseURL}/GetRoles?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getRoleCategories: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<RoleCategory[]>(`${baseURL}/GetRoleCategories`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addRole: function (data: Role) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post<string>(`${baseURL}/AddRole`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateRole: function (data: Role) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put<string>(`${baseURL}/UpdateRole/${data.Id}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllRoles: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<Role[]>(`${baseURL}/GetAllRoles`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getUsers: function (pageNumber: number, pageSize: number, sort: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<BaseObjectManagement<User>>(`${baseURL}/GetUsers?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUser: function (data: User) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post<string>(`Account/PortalUser/Register`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateUser: function (data: User) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put<string>(`Account/UpdateUser/${data.Id}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }

}
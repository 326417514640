import { Card, Col, Input, Modal, Row } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalOptions, ServiceOperationCode } from '../../../models/appModels';
import { CommonService, Constant } from '../../../services/services';
import { SearchOutlined } from '@ant-design/icons';
import useDebounce from './../../utils/useDebounce'
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from "react-virtualized";
export interface ServiceCodeOptions extends ModalOptions<ServiceOperationCode> {
    serviceOperationCodes: ServiceOperationCode[],
    serviceCodeId?: number
}

const ServiceCodeSelection = (props: ServiceCodeOptions) => {
    const { t } = useTranslation();
    const [serviceCodeData, setServiceCodeData] = useState<ServiceOperationCode[]>([])
    const [cache, setCache] = useState<CellMeasurerCache>(new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100
    }));
    const [filterValue, setFilterValue] = useState('');
    const [scrollToIndex, setScrollToIndex] = useState((props && props.serviceCodeId) ? props.serviceCodeId : -1);
    const debouncedSearchTerm = useDebounce(filterValue, 200);

    const modalClass = "vertical-modal"
    const filterGrid = "filterGrid"

    const calculateScrollMaxHeight = (gridId: string, modalClass: string) => {
        return CommonService.calculateScrollMaxHeight(gridId, modalClass)
    }

    const calculateScrollHeight = () => {
        document.documentElement.style.setProperty(Constant.cssVariables.ModalHeightScrollList, calculateScrollMaxHeight(filterGrid, modalClass));
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const rowHeightCalc = (idx: any) => {
        let height = cache.rowHeight(idx) + 10
        return height
    }

    const onServiceCodeClick = (service: ServiceOperationCode) => {
        props.handleCancel(service)
    };

    const renderItem = ({ index, key, style, parent }: any) => {
        const item = serviceCodeData[index];
        return (
            <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}>
                <div key={key} style={style} onClick={() => { onServiceCodeClick(item) }} id={item.Id?.toString()}>
                    <Card className="card-selection">
                        <Row>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field">{t("service_detail.operation_code")}</label>
                                <label className="text-field semi-bold-text">{item.ServiceCode}</label>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field">{t("service_detail.service_name")}</label>
                                <label className="text-field semi-bold-text">{item.Name}</label>
                            </Col>
                            <Col xs={{ span: 24 }}>
                                <label className="title-field">{t("common.description")}</label>
                                <label className="text-field truncate semi-bold-text">{item.Description}</label>
                            </Col>

                        </Row>
                    </Card>
                </div>

            </CellMeasurer>
        );
    };

    useEffect(() => {
        setServiceCodeData(props.serviceOperationCodes.map(x => Object.assign({}, x)))
        calculateScrollHeight()
        
    }, [])

    useEffect(
        () => {
            if (debouncedSearchTerm) {
                let result = props.serviceOperationCodes.filter(x => x.ServiceCode.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) >= 0 ||
                    x.Name.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) >= 0 ||
                    x.Description?.toLowerCase().indexOf(debouncedSearchTerm.toLowerCase()) >= 0)
                setServiceCodeData(result)
            }
            else {
                setServiceCodeData(props.serviceOperationCodes)
            }
        },
        [debouncedSearchTerm]
    );
    return (
        <Modal
            title={t("service_management.service_code_selection")}
            visible={true}
            confirmLoading={false}
            onCancel={closeForm}

            className={`portal-modal modal-open ${modalClass}`}
            maskClosable={false}
            footer={null}
            transitionName=""
            maskTransitionName=""
        >
            <Row id={filterGrid} className="p-b-10">
                <Input className="search-input m-t-5" name="modelCode"
                    placeholder={t("common.filter")}
                    onChange={e => setFilterValue(e.target.value)} prefix={<SearchOutlined />} />
            </Row>

            {serviceCodeData.length > 0 &&
                <div className="list-container">
                    <AutoSizer>
                        {({ width, height }) => {
                            return <List
                                width={width}
                                height={height}
                                rowHeight={rowHeightCalc}
                                deferredMeasurementCache={cache}
                                rowRenderer={renderItem}
                                rowCount={serviceCodeData.length}
                                overscanRowCount={3}
                                scrollToIndex={scrollToIndex}
                            />
                        }
                        }
                    </AutoSizer>
                </div>
            }
        </Modal>
    )
}

export default ServiceCodeSelection
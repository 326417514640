import React, { useEffect, useState } from 'react';
import './otpVerification.scss';
import { Input, Modal, Row, Col, Button } from 'antd';
import { MessageOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import { ClientService } from './../../services/clientService';
import { CommonService } from './../../services/commonService'
import { useCountdown } from '../../services/useCountdown';
import { OTPVerificationConfig, OTPConfiguration } from '../../models/appModels';
import { Typography } from 'antd';
import LoadingContext from '../../pages/utils/loadingCompt/loadingContext';
const { Text } = Typography;

export function OTPVerification(props: OTPVerificationConfig) {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext);
    const [regexInputValue, setRegexInputValue] = useState<RegExp>(/[0-9]/);
    const [inputValues, setInputValues] = useState<string[]>([]);
    const [disableValidation, setDisableValidation] = useState<boolean>(false);
    const [otpVerificationId, setOTPVerificationId] = useState<string>("");
    const [otpConfig, setOTPConfig] = useState<OTPConfiguration>({
        NumberOfCharacters: 0,
        UseNumeric: false,
        UseAlphabet: false,
        ExpiryTime: 0,
        CustomerServicePhone: ""
    });
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [timer, setTimer] = useState<any>(otpConfig.ExpiryTime);
    const { seconds: timerSeconds, restartTimer } = useCountdown(otpConfig.ExpiryTime);
    const resetInputData = () => {
        const _fields: string[] = [];
        if (otpConfig && otpConfig.NumberOfCharacters >= 0) { }
        for (let i = 0; i < otpConfig?.NumberOfCharacters; i++) {
            _fields.push("");
        }
        setInputValues(_fields);
    }
    const sendOTP = () => {
        showLoading();
        ClientService.sendOTPVerification({ PhoneNumber: props.phoneNumber, DealershipId: props.dealershipId })
            .finally(() => { dismissLoading(); })
            .then((result) => {
                if (result && result.data) {
                    if (result.data.OTPConfiguration) {
                        let _config = result.data.OTPConfiguration;
                        //2s for sending/receiving OTP
                        _config.ExpiryTime = _config.ExpiryTime - 2;
                        setOTPConfig(_config);
                        setVisibleModal(true);
                        if (_config.UseNumeric && _config.UseAlphabet) {
                            setRegexInputValue(/[0-9a-zA-Z]/);
                        } else if (_config.UseAlphabet) {
                            setRegexInputValue(/[a-zA-Z]/);
                        } else {
                            setRegexInputValue(/[0-9]/);
                        }
                    } else {
                        setErrorMessage(t("common.otp_not_configured"));
                    }
                    if (result.data.ExceededNumberOfMessagesPerHour) {
                        setErrorMessage(t("common.otp_exceeded_number_of_messages_per_hour"));
                    } else if (result.data.ExceededNumberOfMessagesPerDay) {
                        setErrorMessage(t("common.otp_exceeded_number_of_messages_per_day"));
                    } else {
                        setErrorMessage("");
                        setOTPVerificationId(result.data.Id);
                        setDisableValidation(false);
                        restartTimer();
                    }
                } else {
                    setVisibleModal(false);
                    CommonService.presentToast('error', t("common.otp_create_error"));
                }
            })
            .catch((error) => {
                CommonService.presentToast('error', error);
            });
    }
    useEffect(() => {
        sendOTP();
    }, []);
    useEffect(() => {
        resetInputData();
    }, [otpConfig])
    useEffect(() => {
        setTimer(timerSeconds);
        if (timerSeconds === 0) {
            setDisableValidation(true);
            setErrorMessage(t("common.otp_expired"));
        }
    }, [timerSeconds]);

    const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = event.target;
        // Make a copy of the inputValues array
        const newInputValues = [...inputValues];
        if (regexInputValue.test(inputValue)) {
            // Update the value of the element at the given index
            newInputValues[index] = event.target.value;
        } else {
            // Update the value of the element at the given index
            newInputValues[index] = "";
        }
        // Set the state with the updated array
        setInputValues(newInputValues);
        if (newInputValues[index] !== "") {
            const nextInput = document.getElementById("txt" + (index + 1));
            if (nextInput) {
                nextInput.focus();
            }
        }
    }
    const handlePaste = (index: number, event: React.ClipboardEvent<HTMLInputElement>) => {
        const data = event.clipboardData.getData('text');
        if (data) {
            let arr = data.split("");
            const newInputValues = [...inputValues];
            if (arr.length > 0) {
                for (let i = 0; i < arr.length; i++) {
                    if (regexInputValue.test(arr[i])) {
                        newInputValues[index] = arr[i];
                        index++;
                    }
                    if (index === otpConfig.NumberOfCharacters) {
                        break;
                    }
                }
                const currInput = document.getElementById("txt" + (index - 1));
                if (currInput) {
                    currInput.focus();
                }
                setInputValues(newInputValues);
            }
        }
    };
    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace" && inputValues[index] === "") {
            // If the input is empty and the user presses backspace, focus on the previous input
            const prevInput = document.getElementById("txt" + (index - 1));
            if (prevInput) {
                prevInput.focus();
            }
        } else if (event.key === "ArrowLeft") {
            const prevInput = document.getElementById("txt" + (index - 1));
            if (prevInput) {
                prevInput.focus();
            }
        } else if (event.key === "ArrowRight") {
            const nextInput = document.getElementById("txt" + (index + 1));
            if (nextInput) {
                nextInput.focus();
            }
        }
        else if (event.key === "Enter") {
            submitOTP();
        }
    };
    const handleFocus = (index: number, event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value !== "") {
            event.target.select();
        }
    };
    const closeForm = () => {
        props.handleCancel();
    }
    const resetOTP = () => {
        resetInputData();
        const firstInput = document.getElementById("txt" + 0);
        if (firstInput) {
            firstInput.focus();
        }
    }
    const submitOTP = () => {
        let otpValue = inputValues.join("");
        showLoading();
        ClientService.validateOTP({
            DealershipId: props.dealershipId,
            Id: otpVerificationId,
            PhoneNumber: props.phoneNumber,
            Code: otpValue,
        }).finally(() => { dismissLoading(); })
            .then((result) => {
                if (result && result.data) {
                    if (result.data.IsVerified === true) {
                        setVisibleModal(false);
                        props.handleSuccess();
                    } else if (result.data.IsExpired === true) {
                        setDisableValidation(true);
                        setErrorMessage(t("common.otp_expired"));
                    } else if (result.data.ExceededNumberOfFailures === true) {
                        setDisableValidation(true);
                        setErrorMessage(t("common.otp_exceeded_number_of_failures"));
                    } else {
                        setErrorMessage(t("common.otp_verify_failed"));
                        resetOTP();
                    }
                } else {
                    setDisableValidation(true);
                    setErrorMessage(t("common.otp_not_found"));
                }
            })
            .catch((error) => {
                setErrorMessage(error);
            });
    }
    return (
        <Modal className={'otp-verification-modal'} title={t("common.otp_verification")} visible={visibleModal} confirmLoading={false} onCancel={closeForm} footer={null} maskClosable={false}>
            <Row className={'row-flex-center otp-icon'}>
                <MessageOutlined />
            </Row>
            <Row className={'row-flex-center'}>
                <Text>{t("common.otp_message")}</Text>
            </Row>
            <Row className={'row-flex-center'}>
                <Typography.Title level={4}>{props.phoneNumber}</Typography.Title>
            </Row>
            <Row className={'row-flex-center otp-input'} gutter={2}>
                {inputValues.map((p, index) =>
                    <Col key={"col" + index} className="gutter-row" span={2}>
                        <Input id={"txt" + index} key={"txt" + index} maxLength={1} size={'large'} value={p} defaultValue={p} disabled={disableValidation}
                            onChange={(event) => handleChange(index, event)}
                            onPaste={event => handlePaste(index, event)}
                            onKeyDown={(event) => handleKeyDown(index, event)}
                            onFocus={(event) => handleFocus(index, event)}
                        ></Input>
                    </Col>
                )}
            </Row>
            {
                errorMessage && (
                    <Row className={'row-flex-center otp-timing'}>
                        <Text type="danger">{errorMessage}</Text>
                    </Row>
                )
            }
            {
                !disableValidation && (
                    <Row className={'row-flex-center otp-timing'}>
                        <Text>{t("common.otp_resend_time", { second: timer })}</Text>
                    </Row>
                )
            }
            {
                disableValidation && (
                    <Row className={'row-flex-center otp-timing'}>
                        <Button type="link" onClick={sendOTP} className={'btn-resend'} size={'large'}>{t("common.otp_resend")}</Button>
                    </Row>
                )
            }
            <Row className={'row-flex-center otp-btn-submit'}>
                <Button disabled={disableValidation} onClick={submitOTP} className={'btn-submit'} type="primary" size={'large'}>{t("common.submit")}</Button>
            </Row>
            {/* <Row className={'row-flex-center otp-end-form'}>
                <Button disabled={disableValidation} onClick={resetOTP} className={'btn-submit'} type="default" size={'large'}>{t("common.reset")}</Button>
            </Row> */}
        </Modal>
    );
}

import React, { forwardRef, useEffect } from 'react';
import './confirmationDetail.scss'
import { Constant, ClientService, CommonService } from '../../services/services'
import { useGlobalState } from '../../services/state';
import { Button, Checkbox, Modal, Input, Select, Form, Row, Col, Space } from 'antd';
import { BookingData, Appointment, CancelBookingRequest, CancellationCode } from "../../models/appModels";
import { useTranslation } from 'react-i18next';
import LoadingContext from './../utils/loadingCompt/loadingContext'
const { Option } = Select;
const { TextArea } = Input;

const btnLayout = {
    xs: { span: 24 }
};

const ConfirmationDetail = forwardRef((props: any, ref) => {
    const [appointmentDetail, setAppointmentDetail] = useGlobalState('appointmentDetailData');
    const [vehicleDetail] = useGlobalState('vehicleDetailData');
    const [originalVehicleMake] = useGlobalState('originalVehicleMake');
    const [bookingData,  setBookingData] = useGlobalState('bookingData');
    const [customerDetailData] = useGlobalState('customerDetailData');
    const [jobLineData] = useGlobalState('jobLineData');
    const [serviceDetailData] = useGlobalState('serviceDetailData');
    const [reservationToken] = useGlobalState('reservationToken');
    const [customerType] = useGlobalState('customerType');
    const [isSendMail, setIsSendMail] = React.useState<boolean>(false);
    const [isSendSms, setIsSendSms] = React.useState<boolean>(false);
    const [isConsent, setIsConsent] = React.useState<boolean>(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [cancelModelVisible, setCancelModelVisible] = React.useState<boolean>(false);
    const [cancellationCodes, setCancellationCodes] = React.useState<CancellationCode[]>([]);
    const [isPdpaAgree, setIsPdpaAgree] = React.useState<boolean>(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    useEffect(() => {
        if (bookingData && bookingData.Booking) {
            setIsSendMail(bookingData.Booking.IsSendConfirmationBooking)
            setIsSendMail(bookingData.Booking.IsSendSmsConfirmationBooking)
            setIsConsent(bookingData.Booking.IsConsentConfirmationBooking)

            if (bookingData.Booking.StatusCode == Constant.bookingStatusCode.Active) {
                ClientService.getCancellationCode().then(data => {
                    setCancellationCodes(data.data)
                }).catch(error => {
                    CommonService.handleErrorResponse(error)
                })
            }
        }

        return () => {
            ClientService.cancelRequest()
        }
    }, [])

    function submit() {
        if (bookingData && bookingData.Booking) {
            bookingData.Booking.ReservationToken = reservationToken
            bookingData.Booking.IsSendConfirmationBooking = isSendMail
            bookingData.Booking.IsSendSmsConfirmationBooking = isSendSms
            bookingData.Booking.IsConsentConfirmationBooking = isConsent
            if (customerDetailData) {
                bookingData.Booking.Customer = customerDetailData;
            }
            if (vehicleDetail) {
                if (vehicleDetail.Id > 0 && vehicleDetail.MakeId != originalVehicleMake) {
                    vehicleDetail.Id = 0
                    vehicleDetail.OwnerCustomerId = 0
                    vehicleDetail.OwnerCustomer = undefined
                }
                bookingData.Booking.Vehicle = vehicleDetail
            }
            if (appointmentDetail) {
                bookingData.Booking.Appointment = appointmentDetail
                if (appointmentDetail.Note) {
                    bookingData.Booking.CustomerComment = appointmentDetail.Note
                }
            }
            if (serviceDetailData) {
                if (serviceDetailData.location) {
                    if (serviceDetailData.location.Id > 0) {
                        bookingData.Booking.Appointment.LocationId = serviceDetailData.location.Id
                    } else {
                        bookingData.Booking.Appointment.Location = serviceDetailData.location
                    }
                }
                if (serviceDetailData.serviceAdvisor) {
                    if (serviceDetailData.serviceAdvisor.Id > 0) {
                        bookingData.Booking.ServiceAdvisorId = serviceDetailData.serviceAdvisor.Id
                    } else {
                        bookingData.Booking.ServiceAdvisor = serviceDetailData.serviceAdvisor
                    }
                }
            }

            if(bookingData.Booking.ServiceAdvisor){
                bookingData.Booking.ServiceAdvisor.WorkshopTimeslotBookings = []
            }

            if (jobLineData) {
                bookingData.Booking.Jobs = jobLineData
            }

            if (validateData(bookingData)) {
                showLoading()
                ClientService.addUpdateBooking(bookingData)
                    .finally(() => dismissLoading())
                    .then(res => {
                        //CommonService.presentToast('success', t( bookingData.Booking.StatusCode == Constant.bookingStatusCode.Active?"confirmation_detail.booking_successful":"common.email_confirmation"))
                        //props.backToMain(true, true)
                        bookingData.Booking.Id = res.data
                        setBookingData(bookingData)
                        props.changeStep(Constant.bookingStep.bookingSuccess, false)
                    }).catch(error => {
                        console.log("error", error);
                        if (error && error.response) {
                            if (error.response.status == Constant.errorCodes.InvalidReservationToken) {
                                CommonService.presentToast("error", t("confirmation_detail.invalid_reservation_token"), t("common.notification_header"))
                                setAppointmentDetail({} as Appointment)
                                props.changeStep(Constant.bookingStep.bookingDetail, true)
                            } else if (error.response.status == Constant.errorCodes.ChangedReservationToken) {
                                CommonService.presentToast("error", t("confirmation_detail.changed_reservation_token"), t("common.notification_header"))
                                setAppointmentDetail({} as Appointment)
                                props.changeStep(Constant.bookingStep.bookingDetail, true)
                            }
                            else if (error.response.status == Constant.errorCodes.BadRequest){
                                CommonService.presentToast("error", error.response.data)
                            }
                        } else {
                            CommonService.handleErrorResponse(error)
                        }
                    });
            }
        }
    }

    function validateData(bookingData: BookingData) {
        if (!bookingData.Booking.Customer
            || (
                customerType === Constant.customerTypes.Organization
                && !bookingData.Booking.Customer.Name
            )
            || (
                customerType === Constant.customerTypes.Person
                && (
                    !bookingData.Booking.Customer.FirstName
                    || !bookingData.Booking.Customer.LastName
                    || !bookingData.Booking.Customer.Email
                )
            )
            || !bookingData.Booking.Customer.Mobile
        ) {
            props.changeStep(Constant.bookingStep.customerDetail, true)
            return false
        }
        if (!bookingData.Booking.Vehicle || !bookingData.Booking.Vehicle.RegoNo
            || (!bookingData.Booking.Vehicle.MakeId && !bookingData.Booking.Vehicle.Make)) {
            props.changeStep(Constant.bookingStep.vehicleDetail, true)
            return false
        }
        if (!serviceDetailData || !serviceDetailData.location || !serviceDetailData.location.LocationCode ||
            !bookingData.Booking.Jobs || bookingData.Booking.Jobs.length == 0) {
            props.changeStep(Constant.bookingStep.serviceDetail, true)
            return false
        }
        if (!bookingData.Booking.Appointment || !bookingData.Booking.Appointment.DropOffTime) {
            props.changeStep(Constant.bookingStep.bookingDetail, true)
            return false
        }
        if (!bookingData.Booking.Appointment || !bookingData.Booking.Appointment.DropOffTime) {
            props.changeStep(Constant.bookingStep.bookingDetail, true)
            return false
        }
        return true
    }

    function cancelBooking() {
        form.submit()
    }

    function doCancelBooking(data: any) {
        if (!bookingData.IsFromDMS) {
            let cancelRequest = {
                ServiceBookingId: bookingData.Booking.Id,
                CancellationCode: data.Code,
                CancellationComment: data.Comment
            } as CancelBookingRequest

            showLoading()
            ClientService.cancelBooking(cancelRequest)
                .finally(() => dismissLoading())
                .then(() => {
                    CommonService.presentToast('success', t("confirmation_detail.booking_cancel"), t("common.notification_header"))
                    props.backToMain(true, true)
                }).catch(error => {
                    CommonService.handleErrorResponse(error)
                })
        } else {
            let cancelRequest = {
                DmsBooking: bookingData.Booking,
                CancellationCode: data.Code,
                CancellationComment: data.Comment
            } as CancelBookingRequest

            showLoading()
            ClientService.cancelDmsBooking(cancelRequest)
                .finally(() => dismissLoading())
                .then(() => {
                    CommonService.presentToast('success', t("confirmation_detail.booking_cancel"), t("common.notification_header"))
                    props.backToMain(true, true)
                }).catch(error => {
                    CommonService.handleErrorResponse(error)
                })
        }

    }

    function closeCancelModel() {
        setCancelModelVisible(false)
    }

    return <div className="confirmation-detail">
        <Row>
            <Col xs={{ span: 24 }}>
                <Checkbox checked={isSendMail} onChange={c => setIsSendMail(c.target.checked)}>{t("customer_detail.send_confirmation_email")}</Checkbox>
            </Col>
        </Row>
        <Row>
            <Col xs={{ span: 24 }} className="m-t-10">
                <Checkbox checked={isSendSms} onChange={c => setIsSendSms(c.target.checked)}>{t("customer_detail.send_confirmation_sms")}</Checkbox>
            </Col>
        </Row>
        <Row>
            {bookingData.Booking.StatusCode != Constant.bookingStatusCode.Active && <Col xs={{ span: 24 }} className="m-t-10">
                <Checkbox checked={isPdpaAgree} onChange={c => setIsPdpaAgree(c.target.checked)}>{t("confirmation_detail.pdpa_note")}
                    <Button className="p-l-0" type='link' href='https://www.bmw.co.th/en/footer/legal-information/privacy-policy.html' target="_blank">{t("confirmation_detail.links")}</Button>
                </Checkbox>
            </Col>
            }
        </Row>
        <Row>
            <Col xs={{ span: 24 }} className="m-t-10 m-b-10">
                <Checkbox checked={isConsent} onChange={c => setIsConsent(c.target.checked)}>{t("confirmation_detail.consent_confirm")}</Checkbox>
            </Col>
        </Row>
        <Row className='custom-footer' justify='end'>
            <Col {...btnLayout} className="text-right flex-center justify-end">
                {bookingData.Booking.StatusCode == Constant.bookingStatusCode.Active &&
                    <Button className="btn m-r-10 cancel-btn" type="text" onClick={() => setCancelModelVisible(true)}>{t("confirmation_detail.lable_cancel")}</Button>
                }
                <Button disabled={!isPdpaAgree && bookingData.Booking.StatusCode != Constant.bookingStatusCode.Active} className="btn" style={{ float: 'right' }} type="primary" onClick={submit}>{bookingData.Booking.StatusCode == Constant.bookingStatusCode.Active ?
                    t("confirmation_detail.label_update") : t("confirmation_detail.label_submit")}
                </Button>
            </Col>
        </Row>
        {
            cancelModelVisible &&
            <Modal
                title={t("confirmation_detail.cancel_booking_title")}
                visible={true}
                onOk={cancelBooking}
                confirmLoading={false}
                onCancel={closeCancelModel}
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                closable={false}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="cancelBookingForm"
                    onFinish={doCancelBooking}
                    form={form}
                >
                    <Form.Item
                        label={t("confirmation_detail.cancel_reason")}
                        name="Code" colon={false} labelAlign="left"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!getFieldValue('Comment') && (value === 0 || !value)) {
                                        return Promise.reject(t("confirmation_detail.reason_missing"));
                                    }
                                    return Promise.resolve();
                                },
                            })
                        ]}
                    >
                        <Select className="full-width"
                        >
                            <Option value={0}>{t("common.none")}</Option>
                            {
                                cancellationCodes.map((n, index) => <Option key={index} value={n.Code}>{n.Code}-{n.Description}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={t("confirmation_detail.cancel_comment")}
                        name="Comment" colon={false} labelAlign="left"
                    >
                        <TextArea className="m-t-5" autoSize={{ minRows: 2, maxRows: 2 }} />
                    </Form.Item>
                </Form>
            </Modal>
        }
    </div>;
})

export default ConfirmationDetail
import React, { useState, useEffect } from 'react';

export default function useDebounce(value: any, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
        
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value] 
    );
  
    return debouncedValue;
  }
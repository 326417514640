import React, { useState } from 'react';
import './locationManagement.scss';
import { Table, Empty, Button, Row, Col, List, Card } from 'antd';

import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    LocationManagementService,
    AlignType
} from '../../services/services'
import { Location, Country } from '../../models/appModels';
import { PlusOutlined, EditFilled } from '@ant-design/icons'
import AddUpdateLocation from './addUpdateLocation/addUpdateLocation';
import { StatusCell, EditCommandCell, GetTableConfigs, GetColumnTextFilterProps } from './../utils/gridColumn/gridColumn'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import InfiniteScroll from 'react-infinite-scroller';

const LocationManagement = (props: any) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [locationData, setLocationData] = useState<Location[]>([])
    const [totalItems, setTotalItems] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<Location | null>();
    const [allowModify, setAllowModify] = useState<boolean>(false)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState<any>({
        pageNumber: 1,
        pageSize: Constant.pageSize,
        sort: "",
        isLoadMore: true,
        showLoading: false
    })
    const [countryData, setCountryData] = useState<Country[]>([])

    const statusList = [
        {
            Code: Constant.statusCode.Active,
            Description: t('role_management.current')
        },
        {
            Code: Constant.statusCode.Inactive,
            Description: t('role_management.closed')
        }
    ]

    const editData = (data: any) => {
        setSelectedObject(data)
        setVisibleModal(true)
    }

    const getColumns = () => {
        let columns = [
            {
                title: t('location_management.header_code'),
                dataIndex: 'LocationCode',
                key: 'LocationCode',
                sorter: true,
                ellipsis: true,
                width: 100,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('location_management.header_name'),
                dataIndex: 'LocationName',
                key: 'LocationName',
                sorter: true,
                ellipsis: true,
                width: 300,
                ...GetColumnTextFilterProps()
            },
            {
                title: t('location_management.header_booking_start'),
                render: (value: any, record: any) => (
                    <label>{record ? CommonService.getTimeString(record.BookingStartTime) : ""}</label>
                ),
                ellipsis: true,
                width: 150,
                align: 'center' as AlignType
            },
            {
                title: t('location_management.header_booking_end'),
                render: (value: any, record: any) => (
                    <label>{record ? CommonService.getTimeString(record.BookingEndTime) : ""}</label>
                ),
                ellipsis: true,
                width: 150,
                align: 'center' as AlignType
            },
            {
                title: t('location_management.header_status'),
                dataIndex: 'StatusCode',
                key: 'StatusCode',
                sorter: true,
                render: (value: any, record: any) => (
                    <StatusCell data={record}
                        editFunction={editData}
                        activeStatus={t('role_management.current')}
                        inactiveStatus={t('role_management.closed')} />
                ),
                align: 'center' as AlignType,
                width: 100
            },
            {
                title: t('location_management.header_stop_booking'),
                render: (value: any, record: any) => (
                    <label>{record && record.StopBookingAtUsageRate ? record.StopBookingAtUsageRate + '%' : ''}</label>
                ),
                ellipsis: true,
                width: 140
            },
            {
                title: t('location_management.header_unavailable_days'),
                dataIndex: 'LeadTimeInDays',
                key: 'LeadTimeInDays',
                sorter: true,
                ellipsis: true,
                width: 150
            }
        ];

        if (allowModify) {
            columns.push({
                title: t('common.action'),
                dataIndex: 'Id',
                key: 'Id',
                sorter: false,
                render: (value: any, record: any) => (
                    <EditCommandCell data={record} editFunction={editData} />
                ),
                align: 'center' as AlignType,
                width: 80
            })
        }
        return columns
    }

    const getLocations = (pageNumber: number, pageSize: number, sort: string, filters: any[], isShowLoading: boolean = false, isScroll: boolean = false) => {
        if (isShowLoading)
            showLoading()
        LocationManagementService.getLocations(pageNumber, pageSize, sort, filters)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isScroll) {
                        let list = locationData.map(x => Object.assign({}, x))
                        list = list.concat(result.data.ObjectList)
                        setLocationData(list)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: pageNumber,
                            pageSize: pageSize,
                            showLoading: false
                        })
                    } else {
                        setLocationData(result.data.ObjectList)
                    }

                    setTotalItems(result.data.TotalItems)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    React.useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.LocationManagement))
        getLocations(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
        LocationManagementService.getCountries().then(result => {
            if (result.data) {
                setCountryData(result.data)
            }
        }).catch(error => {
            CommonService.handleErrorResponse(error)
        })
        return () => {
            LocationManagementService.cancelRequest()
        }
    }, [])

    const getSortString = (sortObject?: any) => {
        if (!sortObject || !sortObject.column)
            return ''
        return `${sortObject.field}-${sortObject.order}`
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        let filterList = CommonService.getFilterList(filters)
        getLocations(pagination.current, pagination.pageSize, getSortString(sorter), filterList)
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: getSortString(sorter),
            filters: filterList
        })
    }

    const handleCancel = (objectData?: Location) => {
        setVisibleModal(false)
        if (objectData)
            getLocations(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, true)
    };

    const onAddNew = () => {
        setVisibleModal(true)
        setSelectedObject(null)
    }

    const handleInfiniteOnLoad = (pageNumber: any) => {
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            showLoading: true
        })
        if (locationData.length >= totalItems) {
            setGridConfigOptionsValue({
                ...gridConfigOptions,
                isLoadMore: false,
                showLoading: false
            });
            return;
        }
        getLocations(pageNumber, Constant.pageSize, gridConfigOptions.sort, gridConfigOptions.filters, false, true)
    }

    return (
        <div className="role-management">
            {
                allowModify &&
                <div className="action-btn-group">
                    <Button className="primary-btn" icon={<PlusOutlined />} onClick={onAddNew}>
                        {t("common.add")}
                    </Button>
                </div>
            }

            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(getColumns(), locationData, totalItems, "Id", onTableChange)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <div className="infinite-scroll-container">
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!gridConfigOptions.showLoading && gridConfigOptions.isLoadMore}
                            useWindow={false}
                        >
                            <List
                                className="card-list"
                                dataSource={locationData}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                renderItem={(item: Location, i: any) => (
                                    <List.Item>
                                        <Card className="card-selection card-details" key={i}>
                                            <Row className="flex-center">
                                                <Col xs={{ span: 5 }}>
                                                    <label className="title-field">{t("location_management.header_code")}</label>
                                                </Col>
                                                <Col xs={{ span: 17 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.LocationCode}</label>
                                                </Col>
                                                {
                                                    allowModify &&
                                                    <Col xs={{ span: 2 }} className="actions text-right">
                                                        <EditFilled className="action-icon" onClick={() => editData(item)} />
                                                    </Col>
                                                }

                                                <Col xs={{ span: 5 }}>
                                                    <label className="title-field">{t("location_management.header_name")}</label>
                                                </Col>
                                                <Col xs={{ span: 19 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.LocationName}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("location_management.header_booking_start_small")}</label>
                                                    <label className="text-field truncate semi-bold-text">{CommonService.getTimeString(item.BookingStartTime)}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("location_management.header_booking_end_small")}</label>
                                                    <label className="text-field truncate semi-bold-text">{CommonService.getTimeString(item.BookingEndTime)}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("location_management.header_interval_small")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.BookingInterval}</label>
                                                </Col>
                                                <Col xs={{ span: 12 }}>
                                                    <label className="title-field">{t("location_management.header_stop_booking")}</label>
                                                    <label className="text-field truncate semi-bold-text">{item.LeadTimeInDays}</label>
                                                </Col>

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>

            {
                visibleModal &&
                <AddUpdateLocation handleCancel={handleCancel} objectData={selectedObject} statusList={statusList} countryList={countryData} ></AddUpdateLocation>
            }
        </div>

    )
}

export default LocationManagement
import React, { useEffect, useState, useRef } from 'react';
import './colorPicker.scss'
import { SketchPicker } from 'react-color';
import { Button } from 'antd';
import { useOutFocus } from '../../services/useOutFocus'

export function ColorPicker(props: any) {
    const [color, setColor] = useState<string>()
    const [colorStyle, setColorStyle] = useState({
        backgroundColor: "#FFFFFF"
    })
    const [showPicker, setShowPicker] = useState<boolean>(false)
    const pickerRef = useRef(null);  
    const buttonRef = useRef<any>(null);  

    // useEffect(() => {
    //     if(props.color){
    //         setColorStyle({
    //             backgroundColor: props.color
    //         })
    
    //         setTimeout(() => {
    //             setColor(props.color)
    //         }, 100);
    //     }     
    // }, [])

    useEffect(() => {
        if(props.color){
            setColorStyle({
                backgroundColor: props.color
            })
            setColor(props.color)
        } else{
            setColorStyle({
                backgroundColor: "#FFFFFF"
            })
        }      
    }, [props.color])

    const handleChangeComplete = (color: any) => {
        setColorStyle({
            backgroundColor: color.hex
        })

        setColor(color.hex)
        if (props.onChange) {
            props.onChange(color.hex)
        }
    }

    const togglePicker = () => {
        setShowPicker(!showPicker) 
    }

    useOutFocus(pickerRef, buttonRef, () => {setShowPicker(false)});

    return (
        <div className="color-picker">
            <Button ref={buttonRef} style={colorStyle} className="button-pick" onClick={togglePicker}></Button>
            {showPicker && <div ref={pickerRef} className="picker"><SketchPicker color={color} onChangeComplete={handleChangeComplete} /></div>}
        </div>
    )
}

import { stringify } from "querystring";

export const StorageService = {
    authKey: "authorizationData",
    dealerIdKey: "dealerIdData",
    dmsIntegrationData: "dmsIntegrationData",
    applicationData: "applicationData",
    clientKey: "clientKey",
    logoUrlKey: "logoUrl",
    languageKey: "languageKey",
    setAuthData: function (authData : any) {
        localStorage.setItem(this.authKey, JSON.stringify(authData));
    },
    getAuthData: function () {
        let data = localStorage.getItem(this.authKey)
        if (data) {
            return JSON.parse(data);
        }
        return null
    },
    removeAuthData: function () {
        localStorage.removeItem(this.authKey);
    },
    setDealerIdData: function (dealerIdData : string) {
        localStorage.setItem(this.dealerIdKey, dealerIdData);
    },
    getDealerIdData: function () {
        return localStorage.getItem(this.dealerIdKey)
    },
    setDmsIntegrationData: function (value : any) {
        localStorage.setItem(this.dmsIntegrationData, value);
    },
    getDmsIntegrationData: function () {
        return localStorage.getItem(this.dmsIntegrationData)
    },
    setApplicationData: function (value : any) {
        localStorage.setItem(this.applicationData, JSON.stringify(value));
    },
    getApplicationData: function () {
        let data = localStorage.getItem(this.applicationData)
        if(data)
            return JSON.parse(data);
    },
    setClientKey: function (value : any) {
        localStorage.setItem(this.clientKey, value);
    },
    getClientKey: function () {
        return localStorage.getItem(this.clientKey)
    },
    removeClientKey: function () {
        return localStorage.removeItem(this.clientKey)
    },
    setLogoUrl: function (value : any) {
        localStorage.setItem(this.logoUrlKey, value);
    },
    getLogoUrl: function () {
        return localStorage.getItem(this.logoUrlKey)
    },
    setLanguage: function (value : any) {
        localStorage.setItem(this.languageKey, value);
    },
    getLanguage: function () {
        return localStorage.getItem(this.languageKey)
    }
}
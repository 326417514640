import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Dropdown, Layout, Menu, Row, Tag, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import { Constant, StorageService } from '../../services/services';
import UserInfo from './userInfo';

const UserDropdown = () => {
    const { t } = useTranslation();
    let history = useHistory();
    const [visibleModal, setVisibleModal] = useState(false)

    const getDealerId = () => {
        return StorageService.getDealerIdData()
    }

    const logout = () => {
        StorageService.removeAuthData()
        history.push(Constant.pages.Login);
    }

    const showUserInfo = (e: any) => {
        setVisibleModal(true)
        //closeDropdown()
    }

    const handleCancel = () => {
        setVisibleModal(false)
    };

    const closeDropdown = () => {
        let dropdowns = document.getElementsByClassName("user-info");
        if (dropdowns) {
            console.log(dropdowns)
            for (let i = 0; i < dropdowns.length; i++) {
                if (dropdowns[i].classList.contains("ant-dropdown-open")) {
                    dropdowns[i].classList.remove("ant-dropdown-open")
                }
            }
        }

        let modal = document.querySelector('.user-info-dropdown');
        if (modal && modal.parentElement){
            modal.parentElement.classList.add('ant-dropdown-hidden')
        }
            
    }

    return (
        <div>
            <Menu className="user-info-dropdown">
                <Menu.Item className="dealer-item">
                    <span style={{ marginRight: 8 }}>{t('configuration.dealer_id')}</span>
                    <Tag color="default">{getDealerId()}</Tag>
                </Menu.Item>
                <Menu.Item onClick={(e) => showUserInfo(e)}>
                    {t('common.user_info')}
                </Menu.Item>
                <Menu.Item onClick={logout} className="logout-item">
                    {t('login_page.logout')}
                </Menu.Item>
            </Menu>
            {
                visibleModal && <UserInfo handleCancel={handleCancel} />
            }
        </div>

    )
}

export default UserDropdown
import React, { useEffect, useRef, useState } from 'react';
import './emailTemplateSetup.scss'
import { Select, Form, Input, Row, Col, List, Empty, Tooltip, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppSettingService, CommonService, Constant } from '../../services/services'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { EmailTemplate, EmailTemplateParameter } from '../../models/appModels';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import EmailEditor from 'react-email-editor';
import { useGlobalState } from '../../services/state';

const emailTemplateParamModel = CommonService.propToString<EmailTemplateParameter>()
const { Option } = Select;

function EmailTemplateSetup(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[]>([]);
    const [currentEmailTemplate, setCurrentEmailTemplate] = React.useState<EmailTemplate>();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [editParameterVisible, setEditParameterVisible] = React.useState<boolean>(false);
    const [templateType, setTemplateType] = React.useState<any>();
    const [antLanguage] = useGlobalState('antLanguage');
    const [allowModify, setAllowModify] = useState<boolean>(false)

    const emailEditorRef = useRef<any>();
    const templateSelectionRef = useRef<any>();

    let clickTimeout: any = null

    let history = useHistory();

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.GeneralSetup))
        GetTemplates()

        return () => {
            AppSettingService.cancelRequest()
        }
    }, [])

    useEffect(() => {
        GetTemplates()
    }, [antLanguage])

    const GetTemplates = () => {
        showLoading()
        AppSettingService.getEmailTemplates()
            .finally(() => dismissLoading())
            .then(result => {
                if (result && result.data) {
                    //form.setFieldsValue(result.data)
                    setEmailTemplates(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const saveTemplate = () => {
        if (emailEditorRef && emailEditorRef.current && currentEmailTemplate) {
            emailEditorRef.current.editor.exportHtml((data: any) => {
                const { design, html } = data;
                currentEmailTemplate.Content = html
                currentEmailTemplate.JsonContent = JSON.stringify(design)

                showLoading()
                AppSettingService.addUpdateEmailTemplate(currentEmailTemplate)
                    .finally(() => dismissLoading())
                    .then(() => {
                        CommonService.presentToast("success", t("email_template_setup.save_success"), t("common.notification_header"))
                    })
                    .catch(error => CommonService.handleErrorResponse(error))
            });
        }

    };

    const handleChange = (value: any) => {
        let template = emailTemplates.filter(e => e.EmailTemplateType == value)
        if (template && template.length > 0) {
            setCurrentEmailTemplate(template[0])
            setTemplateType(template[0].EmailTemplateType)

            if (emailEditorRef && emailEditorRef.current) {
                if (template[0].JsonContent) {
                    let design = JSON.parse(template[0].JsonContent)
                    emailEditorRef.current.editor.loadDesign(design);
                }
                else {
                    emailEditorRef.current.editor.loadDesign(Constant.emailDefaultDesign);
                }

            }
        }
    };

    const copyToClipboard = (value: string) => {
        if (clickTimeout == null) {
            clickTimeout = setTimeout(() => {
                clickTimeout = null
                navigator.clipboard.writeText(value)
                CommonService.presentToast("info", value + t("email_template_setup.copied_clipboard"), t("common.notification_header"))
            }, 300);
        }

    }

    const openParamModal = (paramKey: string | null) => {
        clearTimeout(clickTimeout)
        clickTimeout = null
        if (currentEmailTemplate) {
            if (paramKey) {
                let param = currentEmailTemplate?.EmailTemplateParameter.filter(p => p.ParameterKey == paramKey)
                if (param && param.length > 0) {
                    form.setFieldsValue(param[0])
                }
            }
            setEditParameterVisible(true)
        }
    }

    const closeModal = () => {
        form.resetFields()
        setEditParameterVisible(false)
    }

    const addUpdateParameter = () => {
        form.submit()
    }

    const doAddUpdateParameter = (data: any) => {
        if (currentEmailTemplate) {
            let params = currentEmailTemplate.EmailTemplateParameter.filter(p => p.ParameterKey == data.ParameterKey)
            if (params && params.length > 0) {
                Object.assign(params[0], data)
            } else {
                data.EmailTemplateId = currentEmailTemplate.Id
                currentEmailTemplate.EmailTemplateParameter.push(data)
            }

        }
        setEditParameterVisible(false)
    }

    const reset = () => {
        setTemplateType(null)
        setCurrentEmailTemplate(undefined)
        if (emailEditorRef && emailEditorRef.current) {
            emailEditorRef.current.editor.loadDesign(Constant.emailDefaultDesign);
        }

        GetTemplates()
    }

    return (
        <div className="email-template-setup">
            <Row>
                <Col span={4}>
                    <label>{t("email_template_setup.header_email_template")}</label>
                    <Select value={templateType} className="text-body" style={{ width: "100%" }} onChange={handleChange}>
                        {
                            emailTemplates.map(n => <Option key={n.EmailTemplateType} value={n.EmailTemplateType}>{n.Description}</Option>)
                        }
                    </Select>
                </Col>
                {
                    allowModify &&
                    <Col span={4} className="button-col">
                        <div className="btn-container">
                            <Button className="btn m-l-10" type="text" onClick={reset}>{t("common.reset")}</Button>
                            <Button className="primary-btn m-l-10" onClick={saveTemplate}>{t("common.save")}</Button>
                        </div>
                    </Col>
                }

            </Row>
            <Row className="m-t-10">
                <Col span={18}>
                    <div className="mail-editor">
                        <EmailEditor ref={emailEditorRef} style={{ width: "100%" }} />
                    </div>
                </Col>
                <Col className="p-l-20" span={6} style={{ maxHeight: "600px" }}>
                    {
                        allowModify &&
                        <div>
                            <label>{t("email_template_setup.header_parameters")}</label>
                            <PlusOutlined className="m-l-10 param-add-btn" onClick={() => openParamModal(null)} />
                        </div>
                    }

                    <List
                        className="scroll-list"
                        dataSource={currentEmailTemplate?.EmailTemplateParameter}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                        renderItem={(item, i) => (
                            <List.Item className="param-item" onClick={() => copyToClipboard(item.ParameterKey)} onDoubleClick={() => openParamModal(item.ParameterKey)}>
                                <Tooltip placement="topLeft" className="tooltip" title={t("email_template_setup.copy_clipboard")}>
                                    <div className="ant-list-item-meta-content">
                                        <h4 className="ant-list-item-meta-title">{item.ParameterKey}</h4>
                                        <div className="ant-list-item-meta-description">{item.Description}</div>
                                    </div>
                                </Tooltip>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>

            {
                editParameterVisible &&
                <Modal
                    title={t("email_template_setup.parameter_title")}
                    visible={true}
                    onOk={addUpdateParameter}
                    confirmLoading={false}
                    onCancel={closeModal}
                    okText={t("common.ok")}
                    cancelText={t("common.cancel")}
                    closable={false}
                >
                    <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="cancelBookingForm"
                        onFinish={doAddUpdateParameter}
                        form={form}
                    >
                        <Form.Item
                            label={t("email_template_setup.header_parameter_key")}
                            name={emailTemplateParamModel.ParameterKey} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_table")}
                            name={emailTemplateParamModel.TableName} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_field")}
                            name={emailTemplateParamModel.FieldName} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_query")}
                            name={emailTemplateParamModel.Query} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_separator")}
                            name={emailTemplateParamModel.Separator} colon={false} labelAlign="left"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_description")}
                            name={emailTemplateParamModel.Description} colon={false} labelAlign="left"
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </div>
    )
}

export default EmailTemplateSetup
import React, { useState, useEffect } from 'react';
import { Form, Input, Modal, Select, Row, Col, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalOptions, ServiceAdvisor } from '../../../models/appModels';
import { useGlobalState } from '../../../services/state';
import {
    CommonService as commonService,
    Constant,
    ClientService,
    AdvisorManagementService,
    CommonService
} from '../../../services/services'
import LoadingContext from '../../utils/loadingCompt/loadingContext'

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const advisorModel = commonService.propToString<ServiceAdvisor>()

export interface AdvisorModalOptions extends ModalOptions<ServiceAdvisor> {
    statusList: any[]
}

const AddUpdateAdvisor = (props: AdvisorModalOptions) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [locationData, setLocationData] = useGlobalState('locationList');
    const [form] = Form.useForm();

    const onSubmit = (advisor: ServiceAdvisor) => {
        addUpdateAdvisor(advisor);
    };

    const addUpdateAdvisor = (advisor: ServiceAdvisor) => {
        showLoading()
        if (props.objectData) {
            advisor.Id = props.objectData.Id
        }
        AdvisorManagementService.addUpdateAdvisor(advisor)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('advisor_management.update_successful'), t("common.notification_header"))
                    props.handleCancel(advisor)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }

    useEffect(() => {
        if (!props.objectData) {
            form.setFieldsValue({
                [advisorModel.StatusCode]: Constant.statusCode.Active
            });
        }

        if (!locationData || locationData.length == 0) {
            getLocations()
        }
        return () => {
            AdvisorManagementService.cancelRequest()
        }
    }, [])


    const getLocations = () => {
        ClientService.getLocations()
            .then(function (results) {
                if (results) {
                    setLocationData(results.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            });
    }

    return (
        <Modal
            title={!props.objectData ? t("advisor_management.add_advisor") : t("advisor_management.update_advisor")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className="portal-modal"
            maskClosable={false}
        >
            <Form
                {...layout}
                form={form}
                name="roleForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t("advisor_management.advisor_info")} key="1" className="m-l-5 m-r-5">
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("advisor_management.name")}
                                    name={advisorModel.Name} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("advisor_management.username_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input disabled={props.objectData ? true : false} maxLength={Constant.limitLength.length256} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("common.status")}
                                    name={advisorModel.StatusCode} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("common.status_required") }]}
                                >
                                    <Select>
                                        {
                                            props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("advisor_management.first_name")}
                                    name={advisorModel.FirstName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("advisor_management.firstname_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("advisor_management.last_name")}
                                    name={advisorModel.LastName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("advisor_management.lastname_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>
                            </Col>

                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("advisor_management.dms_user")}
                                    name={advisorModel.DmsUserId} colon={false} labelAlign="left"
                                    rules={[  { required: false}]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                            <Form.Item
                                    label={t("advisor_management.location")}
                                    name={advisorModel.LocationId} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t('advisor_management.location_required') }]}
                                >
                                    <Select>
                                        {
                                            locationData.map((n, index) => <Option key={index} value={n.Id}>{n.LocationName}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>


            </Form>
        </Modal>
    )
}

export default AddUpdateAdvisor


import React, { useState } from 'react'
import LoadingContext from './loadingContext'

const LoadingProvider = ({ children }: any) => {

  const [isLoading, toggleLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{
        isLoading: isLoading, showLoading: () => toggleLoading(true), dismissLoading: () => toggleLoading(false)}} >
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider

import axios from 'axios';
import { AppSetting, DealerStyle, EmailTemplate, EmailTemplateParameter, SmsTemplate } from '../models/appModels';
import { axiosPortal } from './../services/authService'

const source: any[] = [];
let baseURL = "/Booking"
export const AppSettingService = {
    getAppSetting: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<AppSetting>(`${baseURL}/AppSetting`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateAppSetting: function (setting: AppSetting) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post(`${baseURL}/AppSetting`, setting, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDealerStyle: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/DealerStyle"
        return axiosPortal.get<DealerStyle>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateDealerStyle: function (dealerStyle: DealerStyle) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/DealerStyle"
        return axiosPortal.post(url, dealerStyle, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateDealerStyles: function (dealerStyles: DealerStyle[]) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/DealerStyles"
        return axiosPortal.post(url, dealerStyles, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getEmailTemplates: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/EmailTemplates"
        return axiosPortal.get<EmailTemplate[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateEmailTemplate: function (template: EmailTemplate) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/EmailTemplate"
        return axiosPortal.post<number>(url, template, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getSmsTemplates: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/SmsTemplates"
        return axiosPortal.get<SmsTemplate[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateSmsTemplate: function (template: SmsTemplate) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Dealership/SmsTemplate"
        return axiosPortal.post<number>(url, template, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translation from './assets/locales/en/translation.json'
import th_translation from './assets/locales/th/translation.json'
import ja_translation from "./assets/locales/ja/translation.json";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react:{
        useSuspense: false
    },
    resources: {
      en: {translation: translation},
      th: {translation: th_translation},
      ja: {translation: ja_translation},
    },
    fallbackLng: 'en',
    debug: true,
    

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
    // backend: {
    //   loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    // }
  });


export default i18n;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Constant,
    CommonService,
    AdvisorManagementService,
    AlignType
} from '../../services/services'
import LoadingContext from '../utils/loadingCompt/loadingContext'
import { ServiceAdvisor} from '../../models/appModels';
import { StatusCell, EditCommandCell, GetTableConfigs } from '../utils/gridColumn/gridColumn'
import { Table, Empty, Button, Row, Col, List, Card, Spin } from 'antd';
import { PlusOutlined, EditFilled } from '@ant-design/icons'
import AddUpdateAdvisor from './addUpdateAdvisor/addUpdateAdvisor';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';

const AdvisorManagement = (props: any) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [advisorData, setAdvisorData] = useState<ServiceAdvisor[]>([])
    const [totalItems, setTotalItems] = useState(0)
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<ServiceAdvisor | null>();
    const [allowModify, setAllowModify] = useState<boolean>(false)
    const [gridConfigOptions, setGridConfigOptionsValue] = useState<any>({
        pageNumber: 1,
        pageSize: Constant.pageSize,
        sort: "",
        isLoadMore: true,
        showLoading: false
    })


    const statusList = [
        {
            Code: Constant.statusCode.Active,
            Description: t('common.active')
        },
        {
            Code: Constant.statusCode.Inactive,
            Description: t('common.inactive')
        }
    ]

    const editData = (data: any) => {
        let obj = Object.assign({}, data)
        setSelectedObject(obj)
        setVisibleModal(true)
    }

    const getColumns = () => {
        let columns = [
            {
                title: t('advisor_management.name'),
                dataIndex: 'Name',
                key: 'Name',
                sorter: true,
                ellipsis: true,
                width: 200,
                fixed: true
            },
            {
                title: t('advisor_management.first_name'),
                dataIndex: 'FirstName',
                key: 'FirstName',
                sorter: true,
                ellipsis: true,
                width: 150,
            },
            {
                title: t('advisor_management.last_name'),
                dataIndex: 'LastName',
                key: 'LastName',
                sorter: true,
                ellipsis: true,
                width: 150,
            },
            {
                title: t('advisor_management.dms_user'),
                dataIndex: 'DmsUserId',
                key: 'DmsUserId',
                sorter: true,
                ellipsis: true,
                width: 200,
            },
            {
                title: t('advisor_management.location'),
                dataIndex: 'LocationName',
                key: 'LocationName',
                sorter: true,
                ellipsis: true,
                width: 150,
            },
        
            {
                title: t('common.status'),
                dataIndex: 'StatusCode',
                key: 'StatusCode',
                sorter: true,
                render: (value: any, record: any) => (
                    <StatusCell data={record}
                        activeStatus={t('common.active')}
                        inactiveStatus={t('common.inactive')} />
                ),
                align: 'center' as AlignType,
                width: 100
            }
        ];
        if (allowModify) {
            columns.push({
                title: t('common.action'),
                dataIndex: 'Id',
                key: 'Id',
                sorter: false,
                render: (value: any, record: any) => (
                    <EditCommandCell data={record} editFunction={editData} />
                ),
                align: 'center' as AlignType,
                width: 80
            })
        }
        return columns
    }
    const getAdvisors = (pageNumber: number, pageSize: number, sort: string, isShowLoading: boolean = false, isScroll: boolean = false) => {
        if (isShowLoading)
            showLoading()
        AdvisorManagementService.getAdvisors(pageNumber, pageSize, sort)
            .finally(() => {
                if (isShowLoading)
                    dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    if (isScroll) {
                        let list = advisorData.map(x => Object.assign({}, x))
                        list = list.concat(result.data.ObjectList)
                        setAdvisorData(list)
                        setGridConfigOptionsValue({
                            ...gridConfigOptions,
                            pageNumber: pageNumber,
                            showLoading: false
                        })
                    }
                    else {
                        setAdvisorData(result.data.ObjectList)
                    }
                    setTotalItems(result.data.TotalItems)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onTableChange = (pagination: any, filters: any, sorter: any, extra: any) => {
        getAdvisors(pagination.current, pagination.pageSize, CommonService.getSortString(sorter))
        setGridConfigOptionsValue({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            sort: CommonService.getSortString(sorter)
        })
    }

    const handleCancel = (objectData?: ServiceAdvisor) => {
        setVisibleModal(false)
        if (objectData)
            getAdvisors(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
    };

    const addUser = () => {
        setVisibleModal(true)
        setSelectedObject(null)
    }

    const handleInfiniteOnLoad = (pageNumber: any) => {
        setGridConfigOptionsValue({
            ...gridConfigOptions,
            showLoading: true
        })
        if (advisorData.length >= totalItems) {
            setGridConfigOptionsValue({
                ...gridConfigOptions,
                isLoadMore: false,
                showLoading: false
            });
            return;
        }
        getAdvisors(pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, false, true)
    }

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.UserManagement))
        getAdvisors(gridConfigOptions.pageNumber, gridConfigOptions.pageSize, gridConfigOptions.sort, true)
        return () => {
            AdvisorManagementService.cancelRequest()
        }
    }, [])


    return (
        <div >
            {
                allowModify &&
                <div className="action-btn-group">
                    <Button className="primary-btn" icon={<PlusOutlined />} onClick={addUser}>
                        {t("common.add")}
                    </Button>
                </div>
            }

            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        {...GetTableConfigs(getColumns(), advisorData, totalItems, "Id", onTableChange)}
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <div className="infinite-scroll-container">
                        <InfiniteScroll
                            pageStart={1}
                            initialLoad={false}
                            loadMore={handleInfiniteOnLoad}
                            hasMore={!gridConfigOptions.showLoading && gridConfigOptions.isLoadMore}
                            useWindow={false}
                        >
                            <List
                                className="card-list"
                                dataSource={advisorData}
                                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                renderItem={(item: ServiceAdvisor, i: any) => (
                                    <List.Item>
                                        <Card className="card-selection card-details" key={i}>
                                            <Row className="flex-center">
                                                <Col xs={{ span: 6 }}>
                                                    <label className="title-field truncate">{t("advisor_management.name")}</label>
                                                </Col>
                                                <Col xs={{ span: 16 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.Name}</label>
                                                </Col>
                                                 <Col xs={{ span: 6 }}>
                                                    <label className="title-field truncate">{t("advisor_management.location")}</label>
                                                </Col>
                                                <Col xs={{ span: 18 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.LocationName}</label>
                                                </Col>

                                                <Col xs={{ span: 6 }}>
                                                    <label className="title-field truncate">{t("advisor_management.dms_user")}</label>
                                                </Col>
                                                <Col xs={{ span: 18 }}>
                                                    <label className="text-field truncate semi-bold-text">{item.DmsUserId}</label>
                                                </Col>
                                                <Col xs={{ span: 5 }}>
                                                    <StatusCell data={item}
                                                        activeStatus={t('role_management.current')}
                                                        inactiveStatus={t('role_management.closed')} />
                                                </Col>
                                                {
                                                    allowModify &&
                                                    <Col xs={{ span: 2 }} className="actions text-right">
                                                        <EditFilled className="action-icon" onClick={() => editData(item)} />
                                                    </Col>
                                                }

                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                            {gridConfigOptions.showLoading && gridConfigOptions.isLoadMore &&
                                <div className="infinite-scroll-loading-container">
                                    <Spin />
                                </div>
                            }
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
            {
                visibleModal &&
                <AddUpdateAdvisor handleCancel={handleCancel} objectData={selectedObject} statusList={statusList}></AddUpdateAdvisor>
            }
        </div>
    )
}

export default AdvisorManagement
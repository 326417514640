import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './bookingDetail.scss'
import { Calendar, Select, Typography, Form, Input, Button, Row, Col, Modal } from 'antd';
import { Constant, ClientService, CommonService } from '../../services/services'
import { UnavailableDate, BookingDetailData, TimeslotReservation, Appointment, ServiceAdvisor } from '../../models/appModels'
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../services/state';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment'
import { useLocation } from "react-router-dom";
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

const { confirm } = Modal;

const { Option } = Select;
const { TextArea } = Input;

const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};
const calendarFormLayout = {
    wrapperCol: { span: 24 }
};
const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10, offset: 1 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 10, offset: 2 }
}

const btnLayout = {
    xs: { span: 24 },
    md: { span: 23 }
};

var unavailableDate: UnavailableDate

const BookingDetail = forwardRef((props: any, ref) => {
    const [form] = Form.useForm();
    const { t, i18n } = useTranslation();

    const [, reRender] = React.useState<null>();
    const [timeSlots, setTimeSlots] = React.useState<Date[]>([]);

    const [bookingDetail, setBookingDetail] = useGlobalState('appointmentDetailData');
    const [reservationToken, setReservationToken] = useGlobalState('reservationToken');
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const location = useLocation();
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [bookingData] = useGlobalState('bookingData');
    const [advisors, setAdvisors] = useState<ServiceAdvisor[]>([]);
    const [availableAdvisors, setAvailableAdvisors] = useState<ServiceAdvisor[]>([]);
    const [currentAdvisor, setCurrentAdvisor] = useState<ServiceAdvisor | null>(null);
    const [selectedBookingTime, setSelectedBookingTime] = React.useState<any>(null);
    React.useEffect(() => {
        let state: any = location.state
        if (state && state.doSubmit) {
            setTimeout(() => {
                form.validateFields()
            }, 100)
        }
    }, [location])

    useImperativeHandle(ref, () => ({
        formHasValue() {
            let data = form.getFieldsValue()
            if (data && (data.DropOffTime || data.PickupTime || data.Note)) {
                return true
            }
            return false
        },
        saveData(nextStep: number) {
            if (isDirty) {
                form.validateFields().then(() => {
                    let location = form.getFieldsValue() as Location
                    onFinish(location, nextStep)
                }, reject => {
                    props.doChangeStep(nextStep)
                })
            } else {
                props.doChangeStep(nextStep)
            }
        }
    }));

    React.useEffect(() => {
        if (!props.ignoreBackend) {
            let formValue = null
            if (bookingDetail) {
                formValue = {
                    BookingDate: moment(bookingDetail.BookingDate),
                    DropOffTime: getTimeSlotName(bookingDetail.DropOffTime),
                    PickupTime: getTimeSlotName(bookingDetail.PickUpTime),
                    Note: ''
                }
            }
            if (bookingData && bookingData.Booking && bookingData.Booking.CustomerComment) {
                if (formValue) {
                    formValue.Note = bookingData.Booking.CustomerComment
                } else {
                    formValue = {
                        Note: bookingDetail.Note
                    }
                }
            }
            if (formValue) {
                form.setFieldsValue(formValue)
            }

            if (serviceDetailData && serviceDetailData.location && serviceDetailData.location.Id) {
                showLoading()
                Promise.all([
                    ClientService.getUnavailalbleBookingDays(serviceDetailData.location.Id),
                    ClientService.getServiceAdvisors(serviceDetailData.location.Id)
                ])
                .finally(() => dismissLoading())
                    .then(response => {
                        var defaultDay = new Date()
                        var disableToday = false

                        if (response[0] && response[0].data) {
                            unavailableDate = response[0].data
                            reRender(null)


                            if (bookingDetail && bookingDetail.DropOffTime) {
                                defaultDay = new Date(`${bookingDetail.DropOffTime.getFullYear()}-${bookingDetail.DropOffTime.getMonth() + 1}-${bookingDetail.DropOffTime.getDate()}`)
                            }

                            if (unavailableDate && unavailableDate.UnavailableDates && unavailableDate.UnavailableDates.length > 0) {
                                for (let i = 0; i < unavailableDate.UnavailableDates.length; i++) {
                                    var date = new Date(unavailableDate.UnavailableDates[i])
                                    let temp = unavailableDate.UnavailableDates[i].toString()
                                    if (temp.indexOf('T') >= 0) {
                                        let dateStr = temp.split('T')
                                        if (dateStr.length > 1) {
                                            date = new Date(dateStr[0])
                                        }
                                    }

                                    if (defaultDay.getDate() == date.getDate() && defaultDay.getMonth() == date.getMonth() && defaultDay.getFullYear() == date.getFullYear()) {
                                        disableToday = true
                                        break
                                    }
                                }
                            }
                            if (!disableDate && unavailableDate.UnavailableDaysOfWeek && unavailableDate.UnavailableDaysOfWeek.length > 0) {
                                if (unavailableDate.UnavailableDaysOfWeek.indexOf(defaultDay.getDay()) >= 0) {
                                    disableToday = true
                                }
                            }
                        }
                        if (response[1] && response[1].data) {
                            setAdvisors(response[1].data)
                            if (bookingDetail && bookingDetail.DropOffTime) {
                                setAvailableAdvisors(filterServiceAdvisor(response[1].data, (bookingDetail.DropOffTime as Date), true));
                            }
                            if (serviceDetailData && serviceDetailData.serviceAdvisor) {
                                let tempAdvisor

                                if (!serviceDetailData.serviceAdvisor.IsRandom) {
                                    if (serviceDetailData.serviceAdvisor.Name) {
                                        //setCurrentAdvisor(serviceDetailData.serviceAdvisor);
                                        tempAdvisor = serviceDetailData.serviceAdvisor
                                    } else if (serviceDetailData.serviceAdvisor.DmsUserId) {
                                        let advisor = response[1].data.filter(a => a.DmsUserId == serviceDetailData.serviceAdvisor!.DmsUserId)
                                        if (advisor && advisor.length > 0) {
                                            serviceDetailData.serviceAdvisor = advisor[0]
                                            //setCurrentAdvisor(advisor[0])
                                            tempAdvisor = advisor[0]
                                        }
                                    }
                                    if (tempAdvisor) {
                                        setCurrentAdvisor(tempAdvisor)
                                        let formValue = form.getFieldsValue()
                                        form.setFieldsValue({
                                            ...formValue,
                                            AdvisorId: tempAdvisor.DmsUserId
                                        })
                                    }
                                }
                            } else if (serviceDetailData.location.DefaultServiceAdvisor) {
                                let advisor = response[1].data.find(a => a.DmsUserId == serviceDetailData.location.DefaultServiceAdvisor?.DmsUserId)
                                if (advisor) {
                                    setCurrentAdvisor(advisor)
                                    let formValue = form.getFieldsValue()
                                    form.setFieldsValue({
                                        ...formValue,
                                        AdvisorId: advisor.DmsUserId
                                    })
                                }
                            } else {
                                let formValue = form.getFieldsValue()
                                form.setFieldsValue({
                                    ...formValue,
                                    AdvisorId: "random"
                                })
                            }
                        }

                        return {
                            DisableToday: disableToday,
                            DefaultDay: defaultDay,
                            Advisors: response[1].data
                        }
                    })
                    .then(function (data) {
                        if (!data.DisableToday) {
                            getTimeSlot(data.DefaultDay, data.Advisors)
                        } else {
                            CommonService.presentToast("info", t("booking_detail.timeslot_warning"), t("common.notification_header"))
                        }

                    })
                    .catch(error => CommonService.handleErrorResponse(error))

            } else {
                props.doChangeStep(Constant.bookingStep.serviceDetail, true)
            }

            return () => {
                ClientService.cancelRequest()
            }
        }
    }, [])

    function onPanelChange(value: any, mode: any) {
        console.log(value, mode);
    }

    function disableDate(currentDate: any) {
        if (unavailableDate && unavailableDate.UnavailableDates && unavailableDate.UnavailableDates.length > 0) {
            for (let i = 0; i < unavailableDate.UnavailableDates.length; i++) {
                let dateValue = new Date(unavailableDate.UnavailableDates[i]);
                let temp = unavailableDate.UnavailableDates[i].toString()
                if (temp.indexOf('T') >= 0) {
                    let dateStr = temp.split('T')
                    if (dateStr.length > 1) {
                        dateValue = new Date(dateStr[0])
                    }
                }
                if (currentDate.date() == dateValue.getDate() && currentDate.month() == dateValue.getMonth() && currentDate.year() == dateValue.getFullYear()) {
                    return true
                }
            }
        }
        if (unavailableDate && unavailableDate.UnavailableDaysOfWeek && unavailableDate.UnavailableDaysOfWeek.length > 0) {
            if (unavailableDate.UnavailableDaysOfWeek.indexOf(currentDate.day()) >= 0) {
                return true;
            }
        }

        let today = moment()
        if (today.isAfter(currentDate, 'day')) {
            return true
        }

        let nextYear = today.add(1, 'y')
        if (nextYear.isBefore(currentDate, 'day')) {
            return true
        }

        return false;
    }
    function filterServiceAdvisor(advisors: ServiceAdvisor[], selectedTimeSlot: Date | undefined, init = false) {
        let available = advisors.filter(a =>
            (!a.WorkshopTimeslotBookings || a.WorkshopTimeslotBookings.filter(x => {
                if (!selectedTimeSlot) {
                    return false;
                }
                const existBookingStart = moment(x.BookingStartTime);
                const existBookingEnd = moment(x.BookingEndTime);
                const selectedDate = moment(selectedTimeSlot);
                if (selectedDate.isSameOrAfter(existBookingStart) && selectedDate.isBefore(existBookingEnd)) {
                    return true;
                }
                return false;
            }).length == 0)
            || (init && serviceDetailData && serviceDetailData.serviceAdvisor && serviceDetailData.serviceAdvisor.DmsUserId == a.DmsUserId)
        )
        return available
    }
    function handleChange(value: any) {
        clearServiceAdvisorForm();
        let selectedTimeSlot = timeSlots.find(t => getTimeSlotName(t) == value)
        setAvailableAdvisors(filterServiceAdvisor(advisors, selectedTimeSlot))
        setIsDirty(true)
    }

    function onDateChange(value: any) {
        if (!props.ignoreBackend) {
            let invalidDate = disableDate(value)
            if (!invalidDate) {
                let dateStr = `${value.year()}-${(value.month() + 1) < 10 ? "0" + (value.month() + 1) : (value.month() + 1)}-${value.date() < 10 ? "0" + value.date() : value.date()}`
                console.log(dateStr)
                let selectedDate = new Date(dateStr)

                getTimeSlot(selectedDate, advisors)
                setIsDirty(true)
            } else {
                setTimeSlots([])
            }

            form.setFieldsValue({
                DropOffTime: undefined,
                PickupTime: undefined
            })

            setAvailableAdvisors([])
            setCurrentAdvisor(null);
        }
    }

    function getTimeSlot(date: Date, advisorList: ServiceAdvisor[]) {
        if (serviceDetailData && serviceDetailData.location) {
            showLoading()
            ClientService.getTimeSlotForBooking(serviceDetailData.location.Id, date, reservationToken)
            .finally(() => dismissLoading())
                .then(result => {
                    if (result.data && result.data.length > 0) {
                        let slots: Date[] = []
                        result.data.forEach(d => {
                            let slot = new Date(d)
                            let slotAdvisors = filterServiceAdvisor(advisorList, slot)
                            if (slotAdvisors && slotAdvisors.length > 0) {
                                slots.push(slot)
                            }
                        })
                        setTimeSlots(slots)
                    } else {
                        CommonService.presentToast("info", t("booking_detail.timeslot_warning"), t("common.notification_header"))
                    }
                })
                .catch(error => { });
        }
    }

    function getTimeSlotName(time?: Date) {
        if (time) {
            let hour = time.getHours()
            let minute = time.getMinutes()
            let hourStr = hour > 9 ? hour.toString() : "0" + hour.toString()
            let minuteStr = minute > 9 ? minute.toString() : "0" + minute.toString()
            return `${hourStr}:${minuteStr}`
        }
        return ""
    }

    function onFinish(values: any, nextStep?: number) {
        if (bookingData && bookingData.Booking && bookingData.Booking.Id && !isDirty) {
            if (!nextStep) {
                props.doChangeStep(Constant.bookingStep.confirmDetail)
            } else {
                props.doChangeStep(nextStep)
            }
        }
        if (serviceDetailData && serviceDetailData.location && serviceDetailData.location.Id > 0) {
            let advisor = currentAdvisor

            if ((!currentAdvisor || values.AdvisorId == "random") && availableAdvisors.length > 0) {
                advisor = availableAdvisors[0]
                advisor.IsRandom = true
            }

            if ((!advisor && serviceDetailData && serviceDetailData.serviceAdvisor && availableAdvisors.filter(x => x.DmsUserId == serviceDetailData.serviceAdvisor?.DmsUserId).length == 0)
                || (advisor && availableAdvisors.filter(x => x.DmsUserId == advisor?.DmsUserId).length == 0)
                || availableAdvisors.length == 0
            ) {
                form.setFields([{
                    name: "AdvisorId",
                    errors: [t("booking_detail.service_advisor_invalid")]
                }])
                return
            }

            setServiceDetailData({
                ...serviceDetailData,
                serviceAdvisor: advisor
            })
            var dropoff = timeSlots.filter(s => getTimeSlotName(s) == values.DropOffTime)
            var pickup = timeSlots.filter(s => getTimeSlotName(s) == values.PickupTime)

            if (dropoff.length > 0) {
                let data: Appointment = {
                    //BookingDate: values.BookingDate ? values.BookingDate : new Date(), => booking date get old value
                    BookingDate: dropoff[0] ? dropoff[0] : new Date(),
                    DropOffTime: dropoff[0],
                    PickUpTime: pickup.length > 0 ? pickup[0] : undefined,
                    Note: values.Note
                }

                setBookingDetail(data)

                let reservation: TimeslotReservation = {
                    ReservationToken: reservationToken,
                    BookingDate: data.BookingDate,
                    DropOffTime: data.DropOffTime,
                    PickUpTime: data.PickUpTime,
                    LocationId: serviceDetailData && serviceDetailData.location ? serviceDetailData.location.Id : 0
                }
                showLoading()
                ClientService.addUpdateTimeSlotReservation(reservation)
                    .finally(() => {
                        dismissLoading()
                    })
                    .then(result => {
                        if (result && result.data && result.data.length > 0) {
                            setReservationToken(result.data)
                        }
                        if (!nextStep) {
                            props.doChangeStep(Constant.bookingStep.confirmDetail)
                        } else {
                            props.doChangeStep(nextStep)
                        }
                    })
                    .catch(error => {
                        if (error && error.response && error.response.status == Constant.errorCodes.MaxReservationReach) {
                            form.setFieldsValue({
                                DropOffTime: undefined,
                                PickupTime: undefined
                            })
                            form.setFields([{
                                name: "DropOffTime",
                                errors: [t("booking_detail.max_reservation_reach")]
                            }])
                            getTimeSlot(data.DropOffTime, advisors)
                        } else {
                            CommonService.handleErrorResponse(error)
                        }
                    })
            }
        } else if (nextStep) {
            confirm({
                title: t("common.confirm"),
                icon: <ExclamationCircleOutlined />,
                content: t("booking_detail.change_step_confirm"),
                okText: t("common.ok"),
                cancelText: t("common.cancel"),
                onOk() {
                    props.doChangeStep(nextStep)
                },
                onCancel() {
                }

            });
        } else {
            CommonService.presentToast('error', t("booking_detail.location_missing"), t("common.notification_header"))
        }
    }

    const clearServiceAdvisorForm = () => {
        let formValue = form.getFieldsValue();
        form.setFieldsValue({ ...formValue, "AdvisorId": "" })
        setCurrentAdvisor(null);
    }
    const onAdvisorChange = (value: string) => {
        if (value) {
            let advisor = advisors.filter(a => a.DmsUserId == value)
            if (advisor && advisor.length > 0) {
                setCurrentAdvisor(advisor[0]);
            }
        } else {
            setCurrentAdvisor(null);
        }
    }

    const submit = () => {
        if (!props.ignoreBackend)
            form.submit()
    }

    return (
        <>
            <Form className="booling-detail" {...formLayout} form={form} labelAlign="left" name="control-hooks" onFinish={onFinish} colon={false}>
                <div>
                    <Row>
                        <Col {...firstCols}>
                            <Form.Item className="calendar-item" name="BookingDate">
                                <Calendar fullscreen={false} onChange={onDateChange} onPanelChange={onPanelChange} disabledDate={disableDate} />
                            </Form.Item>
                        </Col>
                        <Col {...secondCols}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="DropOffTime" label={t("booking_detail.drop_off")} rules={[{ required: true, message: t("common.required_field") }]}>
                                        <Select className="text-body" style={{ width: "100%" }} onChange={handleChange}>
                                            {
                                                timeSlots.map(n => <Option key={n.getTime()} value={getTimeSlotName(n)}>{getTimeSlotName(n)}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={t("service_detail.service_advisor")}
                                        colon={false} labelAlign="left"
                                        className="advisor-item"
                                        name="AdvisorId"
                                    >
                                        <Select showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={currentAdvisor ? currentAdvisor.DmsUserId : undefined}
                                            onChange={onAdvisorChange}
                                        >
                                            <Option value="random">{t("booking_detail.random_advisor")}</Option>
                                            {
                                                availableAdvisors.map((n, index) => <Option key={index} value={n.DmsUserId}>{n.Name}</Option>)
                                            }
                                        </Select>
                                    </Form.Item>
                                    <div style={{ fontStyle: "italic" }}>{t("service_detail.service_advisor_notice")}</div>
                                </Col>
                            </Row>
                            {/* <Row>
                        <Col span={24}>
                            <Form.Item name="PickupTime" label={t("booking_detail.pick_up")} rules={[({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value) {
                                        let dropoff = getFieldValue('DropOffTime')
                                        if (dropoff) {
                                            let dropOffTime = timeSlots.filter(s => getTimeSlotName(s) == dropoff);
                                            let pickupTime = timeSlots.filter(s => getTimeSlotName(s) == value);
                                            if (dropOffTime && dropOffTime.length > 0 && pickupTime && pickupTime.length > 0) {
                                                if (pickupTime[0] <= dropOffTime[0]) {
                                                    return Promise.reject(t("booking_detail.pick_up_error"));
                                                }
                                            }
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            })]}>
                                <Select className="text-body" style={{ width: "100%" }} onChange={handleChange}>
                                    {
                                        timeSlots.map(n => <Option key={n.getTime()} value={getTimeSlotName(n)}>{getTimeSlotName(n)}</Option>)
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> */}
                            <Row>
                                <Col span={24}>
                                    <Form.Item name="Note" label={t("booking_detail.note")}
                                        normalize={value => CommonService.excludeSpecialCharactersInput(value)}>
                                        <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* <Form.Item wrapperCol={{ xs: { span: 24 }, md: { span: 23 } }} className="footer-item">
                        <Button className="btn" type="primary" htmlType={props.ignoreBackend ? "button" : "submit"}>{t("common.next_label")}</Button>
                    </Form.Item> */}
                </div>
            </Form>
            <Row className='custom-footer'>
                <Col {...btnLayout} className="text-right">
                    <Button type="primary" className="btn" onClick={submit}>
                        {t("common.next_label")}
                    </Button>
                </Col>
            </Row>
        </>
    )
})

export default BookingDetail
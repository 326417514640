import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Table, Button, List, Empty, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { CommonService } from '../../../services/commonService';
import { BookingActionTypeCell, GetTableConfigs } from './../../utils/gridColumn/gridColumn'
import { ServiceBookingHistory } from '../../../models/serviceBookingHistory';
import { AlignType, Constant } from '../../../services/constant';
import LoadingContext from '../../utils/loadingCompt/loadingContext';
import { BookingManagementService } from '../../../services/bookingManagementService';
import { useGlobalState } from '../../../services/state';

const HistoryBookingDetail = (props: any) => {
    const { t } = useTranslation();
    const [bookingHistoryData, setBookingHistoryData] = useState<ServiceBookingHistory[]>([])
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');

    const getBookingActionDescription = (record: ServiceBookingHistory) => {
        if (record.BookingActionTypeCode == Constant.bookingActionType.Submit)
            return t("common.submit")
        else if (record.BookingActionTypeCode == Constant.bookingActionType.Update)
            return t("common.update")
        else if (record.BookingActionTypeCode == Constant.bookingActionType.Cancel)
            return t("common.cancel")
        else if (record.BookingActionTypeCode == Constant.bookingActionType.Confirm)
            return t("common.confirm")
        else if (record.BookingActionTypeCode == Constant.bookingActionType.CancelFromDms)
            return t("common.cancel_from_dms")
        else if (record.BookingActionTypeCode == Constant.bookingActionType.CompleteFromDms)
            return t("common.complete_from_dms")
    }
    const columns = [
        {
            title: t('booking_management.header_rego'),
            dataIndex: 'RegoNo',
            render: (value: any, record: any) => (
                <label>{record && record.Vehicle ? record.Vehicle.RegoNo : ''}</label>
            ),
            ellipsis: true,
            width: 170,
        },
        {
            title: t('booking_management.header_customer'),
            dataIndex: 'CustomerName',
            render: (value: any, record: any) => (
                <label>{record && record.Customer ? record.Customer.Name : ''}</label>
            ),
            sorter: true,
            ellipsis: true,
            width: 180,
        },
        {
            title: t('booking_management.header_location'),
            render: (value: any, record: any) => (
                <label>{record && record.Location ? (record.Location.LocationCode + " - " + record.Location.LocationName) : ''}</label>
            ),
            ellipsis: true,
            width: 180
        },

        {
            title: t('booking_management.service_codes'),
            dataIndex: 'ServiceOperationCodeListString',
            key: 'ServiceOperationCodeListString',
            width: 150
        },
        {
            title: t('booking_management.last_modified_user'),
            dataIndex: 'CreationUserName',
            key: 'CreationUserName',
            ellipsis: true,
            width: 150
        },
        {
            title: t('booking_management.last_modified'),
            dataIndex: 'CreationTimestamp',
            key: 'CreationTimestamp',
            render: (value: any, record: any) => (
                <label>{CommonService.getDateString(record.CreationTimestamp,antLanguage) + ' ' + CommonService.getTimeString(record.CreationTimestamp)}</label>
            ),
            sorter: true,
            ellipsis: true,
            width: 150
        },
        {
            title: t('common.action'),
            dataIndex: 'BookingActionTypeCode',
            key: 'BookingActionTypeCode',
            ellipsis: true,
            width: 130,
            align: 'center' as AlignType,
            render: (value: any, record: any) => (
                <BookingActionTypeCell data={record.BookingActionTypeCode}
                    description={getBookingActionDescription(record)} />
            )
        }
    ]

    const getBookingHistories = (bookingId: number) => {
        showLoading()
        BookingManagementService.getServiceBookingHistory(bookingId)
            .finally(() => {
                dismissLoading()
            })
            .then(result => {
                if (result.data) {
                    setBookingHistoryData(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }
    useEffect(() => {
        if (props.bookingData && props.bookingData.Id > 0)
            getBookingHistories(props.bookingData.Id)
    }, [])

    return (
        <Modal
            title={t("booking_management.booking_history")}
            visible={true}
            confirmLoading={false}
            onCancel={closeForm}
            footer={null}
            width={1300}
        >
            <Row>
                <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 24 }}>
                    <Table
                        //{...GetTableConfigs(columns, bookingHistoryData, 0, "Id", () => { })}
                        columns={columns}
                        dataSource={bookingHistoryData}
                        rowKey="Id"
                        pagination={false}
                        scroll={{ y: 240 }}
                        className="custom-table"
                    />
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 0 }}>
                    <List
                        className="card-list"
                        dataSource={bookingHistoryData}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                        renderItem={(item: ServiceBookingHistory, i: any) => (
                            <List.Item>
                                <Card className="card-selection card-details" key={i}>
                                    <Row>
                                        <Col xs={{ span: 12 }}>
                                            <label className="title-field">{t("booking_management.header_rego")}</label>
                                            <label className="text-field truncate semi-bold-text">{item.Vehicle?.RegoNo}</label>
                                        </Col>
                                        <Col xs={{ span: 12 }}>
                                            <label className="title-field">{t("booking_management.header_customer")}</label>
                                            <label className="text-field truncate semi-bold-text">{item.Customer?.Name}</label>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <label className="title-field">{t("booking_management.header_location")}</label>
                                            <label className="text-field semi-bold-text">{item.Location ? item.Location.LocationCode + ' - ' + item.Location.LocationName : ''}</label>
                                        </Col>
                                        <Col xs={{ span: 24 }}>
                                            <label className="title-field">{t("booking_management.service_codes")}</label>
                                            <label className="text-field semi-bold-text">{item.ServiceOperationCodeListString || '_'}</label>
                                        </Col>
                                        <Col xs={{ span: 12 }}>
                                            <label className="title-field">{t("booking_management.last_modified_user")}</label>
                                            <label className="text-field truncate semi-bold-text">{item.CreationUserName}</label>
                                        </Col>
                                        <Col xs={{ span: 12 }}>
                                            <label className="title-field">{t("booking_management.last_modified")}</label>
                                            <label className="text-field truncate semi-bold-text">{CommonService.getDateString(item.CreationTimestamp,antLanguage) + ' ' + CommonService.getTimeString(item.CreationTimestamp)}</label>
                                        </Col>
                                        <Col xs={{ span: 24 }} className="flex-display">
                                            <label className="title-field">{t("common.action")}</label>
                                            <BookingActionTypeCell data={item.BookingActionTypeCode} description={getBookingActionDescription(item)} />
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    )
}

export default HistoryBookingDetail
import React from "react";
import errorImg from './../../assets/images/error.png'
import lockImg from './../../assets/images/lock.png'
import './invalidPage.scss';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
    const { t } = useTranslation();
    return (
        <div className="invalid-page">
            <div>
                <img src={errorImg} />
                <div className="text-value">{t('common.page_not_found')}</div>
            </div>
        </div>
    )
}

export const NoPermissionPage = () => {
    const { t } = useTranslation();
    return (
        <div className="invalid-page">
            <div>
                <img src={lockImg} />
                <div className="text-value">{t('common.access_denied')}</div>
                <div className="description">{t('common.access_denied_message')}</div>
            </div>
        </div>
    )
}
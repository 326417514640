import React, { useEffect, useState } from 'react';
import './bookingSelection.scss'
import { Form, Input, Modal, Select, Card, Row, Col, List, Empty, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalOptions, ServiceBooking } from '../../../models/appModels';
import { CommonService } from '../../../services/services'
import { PlusOutlined } from '@ant-design/icons'

import { useGlobalState } from '../../../services/state'

export interface BookingSelectionModalOptions extends ModalOptions<ServiceBooking> {
    bookingList: ServiceBooking[],
    handleNewBooking: (booking: ServiceBooking) => void
}

const BookingSelection = (props: BookingSelectionModalOptions) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [modelData, setModelData] = React.useState<ServiceBooking[]>([])
    
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');


    const closeForm = () => {
        props.handleCancel()
    }

    const onBookingClick = (booking: ServiceBooking) => {
        props.handleCancel(booking)
    };

    const onHandleNewBookingClick = () => {
        if (modelData.length > 0)
            props.handleNewBooking(modelData[0])
    }
    useEffect(() => {
        let bookings = props.bookingList.map(x => Object.assign({}, x))
        setModelData(bookings)
    }, [])

    return (
        <Modal
            title={t("customer_search.select_booking")}
            visible={true}
            confirmLoading={false}
            onCancel={closeForm}
            footer={null}
            maskClosable={false}
            width={650}
        >
            <Row>
                <Col xs={24} className="text-right">
                    <Button type="primary" className="btn" icon={<PlusOutlined />} onClick={onHandleNewBookingClick}>
                        {t("booking_detail.new_booking")}
                    </Button>
                </Col>
            </Row>
            <List
                dataSource={modelData}
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                renderItem={(item, i) => (
                    <List.Item>
                        <Card className="card-selection" key={i} onClick={() => { onBookingClick(item) }}>
                            <Row>
                                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                                    <label className="title-field">{t("booking_management.header_booking_id")}</label>
                                    <label className="text-field semi-bold-text">{item.IdPrefix}</label>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                                    <label className="title-field">{t("booking_detail.booking_date")}</label>
                                    <label className="text-field semi-bold-text">{CommonService.getDateString(item.Appointment?.DropOffTime,antLanguage)}</label>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                                    <label className="title-field">{t("booking_detail.drop_off")}</label>
                                    <label className="text-field semi-bold-text">{CommonService.getTimeString(item.Appointment?.DropOffTime)}</label>
                                </Col>
                                <Col xs={{ span: 12 }} md={{ span: 6 }}>
                                    <label className="title-field">{t("booking_detail.pick_up")}</label>
                                    <label className="text-field semi-bold-text">{CommonService.getTimeString(item.Appointment?.PickUpTime)}</label>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <label className="title-field">{t("common.description")}</label>
                                    <label className="text-field truncate semi-bold-text">{item.Appointment?.Note}</label>
                                </Col>
                            </Row>
                        </Card>
                    </List.Item>
                )}
            />
        </Modal>
    )
}

export default BookingSelection

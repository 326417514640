import axios from 'axios';
import { ApplicationData } from '../models/appModels';
import { StorageService, Constant } from './services'

const source: any[] = [];
export const axiosPortal = axios.create();

export const AuthService = {
    registerIntercepter: function (history: any) {
        axiosPortal.defaults.baseURL = process.env.REACT_APP_BASE_URL
        axiosPortal.interceptors.request.use(function (config) {
            let authData = StorageService.getAuthData()
            if (authData && config.url!.indexOf("token") === -1 && config.url!.indexOf("ForgotPassword") === -1
                && config.url!.indexOf("ResetPassword") === -1) {
                config.headers["Authorization"] = `bearer ${authData.token}`
            }

            if (config.url!.indexOf("ForgotPassword") >= 0) {
                config.headers= {
                    DealerId: StorageService.getDealerIdData()
                }
            }

            config.headers["Language"] = StorageService.getLanguage()

            return config;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });

        // Add a response interceptor
        axiosPortal.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            if (error && error.response && error.response.status === 401) {
                history.push(Constant.pages.Login);
            }

            return Promise.reject(error);
        });
    },
    login: function (username: string, password: string, dealerId: string) {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', username);
            params.append('password', password);
            params.append('dealer_id', dealerId);

            return axiosPortal.post("/token", params).then(res => {
                var expiredTime = new Date();
                expiredTime.setSeconds(expiredTime.getSeconds() + res.data["expires_in"])
                let data = {
                    name: username,
                    token: res.data["access_token"],
                    expire: expiredTime
                };
                StorageService.setAuthData(data)

                resolve(res);
            }, (error) => {
                reject(error)
            })
        })
    },
    hasAuthorized: function () {
        let result = false
        let authData = StorageService.getAuthData()
        if (authData) {
            let expiredDate = new Date(authData.expire)
            let currentDate = new Date()
            if (expiredDate >= currentDate) {
                result = true
            }
        }
        if (!result) {
            StorageService.removeAuthData()
        }
        return result
    },
    forgotPassword: function (email: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/ForgotPassword`
        let data = {
            Email: email
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    resetPassword: function (email: string, password: string, code: string, dealerId: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/ResetPassword`
        let data = {
            Email: email,
            Password: password,
            ConfirmPassword: password,
            Code: code
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token,
            headers: {
                DealerId: dealerId
            }
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    confirmEmail: function (code: string, email: string, dealerId: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account?Code=${code}&email=${email}`
        return axiosPortal.get(url, {
            cancelToken: cancelToken.token,
            headers: {
                DealerId: dealerId
            }
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    resendConfirmEmail: function (code: string, email: string, dealerId: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Account/ResendEmailConfirm`
        let data = {
            Code: code,
            Email: email,
            DealerId: dealerId
        }
        return axiosPortal.post(url, data, {
            cancelToken: cancelToken.token,
            headers: {
                DealerId: dealerId
            }
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getApplicationData: function (username: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<ApplicationData>(`/Dealership/GetApplicationData?username=${username}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef, ChangeEvent } from 'react';
import './customerDetail.scss'
import { Button, Form, Input, AutoComplete, Row, Col, Radio, Modal, Select } from 'antd';
import { Constant, ClientService } from '../../services/services'
import { useGlobalState } from '../../services/state';
import { useTranslation } from 'react-i18next';
import { CommonService, CommonService as commonService } from './../../services/commonService'
import { ServiceBooking, BookingData, Customer, CustomerAddress, Bureaucracy } from '../../models/appModels';
import { useLocation } from "react-router-dom";
import CustomerSelection from './customerSelection/customerSelection';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const firstCols = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10, offset: 1 }
}

const secondCols = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 10, offset: 2 }
}


const btnLayout = {
  xs: { span: 24 },
  md: { span: 23 }
};

const customerModel = commonService.propToString<Customer>()

const CustomerDetail = forwardRef((props: any, ref) => {
  const [customerDetailData, setCustomerDetailData] = useGlobalState('customerDetailData');
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const location = useLocation();
  const [vehicleDetail, setVehicleDetail] = useGlobalState('vehicleDetailData');
  //Data get from Booking Search
  const [bookingSearchDataRequest] = useGlobalState('bookingSearchDataRequest');
  const [makeList] = useGlobalState('makeList');
  const [countryStates] = useGlobalState('countryStates');
  const [stateOptions, setStateOptions] = useState<{ value: string }[]>([]);
  const [addressRequired, setAddressRequired] = useState<boolean>(false);
  const [existedCustomer, setExistedCustomer] = useState<boolean>(false);
  const [existedOrganisation, setExistedOrganisation] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [bureaucracies, setBureaucracies] = useState<Bureaucracy[]>([]);
  const [customerSearchList, setCustomerSearchList] = useState<Customer[]>([]);
  const [customerType] = useGlobalState('customerType');
  const [customerPhoneNumber] = useGlobalState('customerPhoneNumber');
  const [dealershipId] = useGlobalState('dealershipId');
  const [formData, setFormData] = useState<Customer>(JSON.parse(JSON.stringify({})));
  const { showLoading, dismissLoading } = React.useContext(LoadingContext);
  const inputFirstName = useRef<any>()
  const inputLastName = useRef<any>()
  const inputMobile = useRef<any>()
  const { Option } = Select;

  let previousFormData: any

  useEffect(() => {
    let state: any = location.state
    if (state && state.doSubmit) {
      form.validateFields()
    }
  }, [location])

  useEffect(() => {
    let isShowLoading: boolean = false;
    ClientService.cancelRequest()
      if (isShowLoading) {
        showLoading()
      }
      ClientService.getBureaucracies(dealershipId)
        .finally(() => {
          if (isShowLoading) {
            dismissLoading()
          }
        })
        .then(result => {
          if(result && result.length > 0) {
            setBureaucracies(result);
          } else {
            setBureaucracies([]);
          }
        })
        .catch(error => commonService.handleErrorResponse(error));

    setStateOptions(countryStates);
    checkAddressRequired();

    return () => {
      ClientService.cancelRequest();
    }
  }, [])

  function changeStep(data: Customer, saveOnly: boolean = false) {
    if (customerType === Constant.customerTypes.Organization) {
      customerDetailData.Name = data.Name
      customerDetailData.Mobile = data.Mobile
      customerDetailData.IsOrganisation = true
      if (data.ContactCustomer) {
        if (!customerDetailData.ContactCustomer) {
          customerDetailData.ContactCustomer = {} as Customer
        }
        customerDetailData.ContactCustomer.FirstName = data.ContactCustomer.FirstName
        customerDetailData.ContactCustomer.LastName = data.ContactCustomer.LastName
        customerDetailData.ContactCustomer.Email = data.ContactCustomer.Email
        customerDetailData.ContactCustomer.Mobile = data.ContactCustomer.Mobile
        customerDetailData.ContactCustomer.StreetAddress = data.StreetAddress
      }
    } else {
      customerDetailData.FirstName = data.FirstName;
      customerDetailData.LastName = data.LastName;
      customerDetailData.Email = data.Email;
      customerDetailData.StreetAddress = data.StreetAddress;
      customerDetailData.IsOrganisation = false
      setCustomerDetailData(customerDetailData);
    }

    if (vehicleDetail && vehicleDetail.IsCarOwner) {
      let vehicle = vehicleDetail;
      if (data.Id > 0) {
        vehicle.OwnerCustomerId = data.Id;
      } else {
        vehicle.OwnerCustomer = data;
      }
      setVehicleDetail(vehicle);
    }
    if (!saveOnly) {
      props.changeStep(Constant.bookingStep.vehicleDetail)
    }
  }
  const resetForm = () => {
    form.resetFields();
    if (isPersonType()) {
      customerDetailData.FirstName = "";
      customerDetailData.LastName = "";
      customerDetailData.Email = "";
      customerDetailData.StreetAddress = {} as CustomerAddress;
    } else {
      if (customerDetailData.ContactCustomer) {
        customerDetailData.ContactCustomer.FirstName = "";
        customerDetailData.ContactCustomer.LastName = "";
        customerDetailData.ContactCustomer.Email = "";
        customerDetailData.ContactCustomer.Mobile = "";
        customerDetailData.ContactCustomer.StreetAddress = {} as CustomerAddress;
      }
    }
    form.setFieldsValue(customerDetailData);
    setCustomerDetailData(customerDetailData)
  };

  function stateSearch(searchText: string) {
    if (searchText) {
      let options = countryStates.filter(s => s.value.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
      setStateOptions(options)
    } else {
      setStateOptions(countryStates)
    }
  };

  useImperativeHandle(ref, () => ({
    formHasValue() {
      if (form.getFieldValue(customerModel.FirstName) ||
        form.getFieldValue(customerModel.LastName) ||
        form.getFieldValue(["StreetAddress", "StreetLine1"]) ||
        form.getFieldValue(["StreetAddress", "Suburb"]) ||
        form.getFieldValue(["StreetAddress", "State"]) ||
        form.getFieldValue(["StreetAddress", "PostCode"]) ||
        form.getFieldValue(customerModel.Mobile) ||
        form.getFieldValue(customerModel.Email))
        return true
      return false
    },
    resetForm() {
      resetForm()
    },
    saveData() {
      let data = form.getFieldsValue() as Customer
      changeStep(data, true)
    }

  }));

  function checkAddressRequired() {
    let address = form.getFieldValue(["StreetAddress", "StreetLine1"])
    if (address) {
      setAddressRequired(true)
    } else {
      setAddressRequired(false)
    }
  }

  const setpreviousFormData = () => {
    previousFormData = form.getFieldsValue()
  }

  const checkFieldChange = (fieldName: string, newValue: any) => {
    if (previousFormData && fieldName) {
      if (fieldName.length == 1 && previousFormData[fieldName[0]] != newValue) {
        return true;
      }
      if (fieldName.length == 2 && previousFormData[fieldName[0]][fieldName[1]] != newValue) {
        return true;
      }
    } else if (newValue) {
      return true;
    }
    return false;
  }

  const checkAndGetCustomersByMobile = (fieldData: string) => {
    if (customerType === Constant.customerTypes.Organization) {
      let value = form.getFieldValue(fieldData)
      if (checkFieldChange(fieldData, value)) {
        doGetCustomersByNameAndPhone(true);
      }
    }
  }

  //only allow search phone if customer type is organize
  const doGetCustomersByNameAndPhone = (isShowLoading: boolean = false) => {
    let isPerson = isPersonType()
    let firstName = form.getFieldValue(isPerson ? customerModel.FirstName : ["ContactCustomer", "FirstName"])
    let lastName = form.getFieldValue(isPerson ? customerModel.LastName : ["ContactCustomer", "LastName"])
    let phoneNumber = form.getFieldValue(isPerson ? customerModel.Mobile : ["ContactCustomer", "Mobile"])
    if (phoneNumber) {
      ClientService.cancelRequest()
      if (isShowLoading) {
        showLoading()
      }
      ClientService.getCustomersByName(firstName, lastName, dealershipId, phoneNumber, true)
        .finally(() => {
          if (isShowLoading) {
            dismissLoading()
          }
        })
        .then(result => {
          if (result.data && result.data.length > 0) {
            setCustomerSearchList(result.data)
            setVisibleModal(true)
          }
          else {
            //create new customer with phone, firstname, lastname
            let formValues = form.getFieldsValue();
            customerDetailData.ContactCustomer = {
              FirstName: formValues.ContactCustomer.FirstName,
              LastName: formValues.ContactCustomer.LastName,
              Email: formValues.ContactCustomer.Email,
              Mobile: formValues.ContactCustomer.Mobile,
              StreetAddress: formValues.StreetAddress
            } as Customer
            setCustomerDetailData(customerDetailData)
          }
        })
        .catch(error => commonService.handleErrorResponse(error))
    }
  }

  const onInputNameEnter = (input: any) => {
    input.current.blur()
  }

  const onInputMobileEnter = (input: any) => {
    input.current.blur()
  }

  const closeCustomerSelection = (obj?: Customer) => {
    setVisibleModal(false)
    setCustomerSearchList([])
    if (obj) {
      customerDetailData.ContactCustomer = obj
      form.setFieldsValue(customerDetailData)
      setCustomerDetailData(customerDetailData)
      setExistedCustomer(true)
    }
    else {
      //clear all contact person info
      resetForm()
    }
  }

  const submit = () => {
    form.submit()
  }

  const isPersonType = () => {
    if (customerType && customerType == Constant.customerTypes.Organization)
      return false
    return true

  }

  return (
    <>
      <Form
        {...layout}
        name="customerForm"
        initialValues={customerDetailData}
        onFinish={changeStep}
        form={form}
      >
        <Row>
          <Col {...firstCols}>
            <Form.Item colon={false} labelAlign="left">
              <Radio.Group name={customerModel.CustomerType} defaultValue={customerType}>
                <Radio disabled={customerType !== Constant.customerTypes.Person} value={'person'}>{t("customer_select.person")}</Radio>
                <Radio disabled={customerType !== Constant.customerTypes.Organization} value={'organization'}>{t("customer_select.organization")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {
          !isPersonType() && (
            <Row>
              <Col {...firstCols}>
                <Form.Item
                  label={t("customer_detail.organization_name")}
                  name={customerModel.Name} colon={false} labelAlign="left"
                  rules={[{ required: true, message: t("customer_detail.organization_name_required") }]}>
                  <Input disabled={customerDetailData && customerDetailData.Id ? true : false} />
                </Form.Item>
              </Col>
              <Col {...secondCols}>
                <Form.Item
                  label={t("customer_detail.organization_phone")}
                  name={customerModel.Mobile} colon={false} labelAlign="left"
                  rules={[{ required: true, message: t("customer_detail.organization_phone_required") }]}>
                  <Input disabled={customerType === Constant.customerTypes.Organization} />
                </Form.Item>
              </Col>
            </Row>
          )
        }
        { 
        process.env.REACT_APP_COUNTRY_CODE === Constant.countryCodes.JP && (
          <>
        <Row>
          <Col {...firstCols}>
          <Form.Item          
              label={t("customer_detail.last_name")}
              name={isPersonType() ? customerModel.LastName : ["ContactCustomer", "LastName"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("customer_detail.last_name_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input ref={inputLastName} onFocus={setpreviousFormData} onPressEnter={() => onInputNameEnter(inputLastName)} />
            </Form.Item>
          </Col>
          <Col {...secondCols}>
          <Form.Item 
              label={t("customer_detail.first_name")}
              name={isPersonType() ? customerModel.FirstName : ["ContactCustomer", "FirstName"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("customer_detail.first_name_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input ref={inputFirstName} onFocus={setpreviousFormData} onPressEnter={() => onInputNameEnter(inputFirstName)} />
            </Form.Item>
          </Col>
        </Row>
          </>
        )      
        }
        {process.env.REACT_APP_COUNTRY_CODE !== Constant.countryCodes.JP && ( 
          <>
        <Row> 
          <Col {...firstCols}>
          <Form.Item
              label={t("customer_detail.first_name")}
              name={isPersonType() ? customerModel.FirstName : ["ContactCustomer", "FirstName"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("customer_detail.first_name_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input ref={inputFirstName} onFocus={setpreviousFormData} onPressEnter={() => onInputNameEnter(inputFirstName)} />
            </Form.Item>
          </Col>
          <Col {...secondCols}>
          <Form.Item
              label={t("customer_detail.last_name")}
              name={isPersonType() ? customerModel.LastName : ["ContactCustomer", "LastName"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("customer_detail.last_name_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input ref={inputLastName} onFocus={setpreviousFormData} onPressEnter={() => onInputNameEnter(inputLastName)} />
            </Form.Item>
          </Col>
        </Row>
            </>
          )}

        <Row>
          <Col {...firstCols}>
            <Form.Item
              label={t("customer_detail.mobile_phone")}
              name={isPersonType() ? customerModel.Mobile : ["ContactCustomer", "Mobile"]} colon={false} labelAlign="left"
              rules={[{ required: true, message: t("customer_detail.mobile_phone_required") }]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input ref={inputMobile}
                disabled={customerType === Constant.customerTypes.Person}
              // TODO remove (TD-58557)
              // onFocus={setpreviousFormData} 
              // onPressEnter={() => onInputMobileEnter(inputMobile)} 
              // onBlur={() => checkAndGetCustomersByMobile(isPersonType() ? customerModel.Mobile : ["ContactCustomer", "Mobile"])} 
              />
            </Form.Item>

          </Col>
          <Col {...secondCols}>
            <Form.Item
              label={t("customer_detail.email")}
              name={isPersonType() ? customerModel.Email : ["ContactCustomer", "Email"]} colon={false} labelAlign="left"
              rules={[{ type: "email", message: t("customer_detail.email_invalid") },
              { required: true, message: t("customer_detail.email_required") }
              ]}
              normalize={value => CommonService.excludeSpecialCharactersInput(value)}
            >
              <Input />
            </Form.Item>
          </Col>

        </Row>

        {
          process.env.REACT_APP_COUNTRY_CODE === Constant.countryCodes.JP && (
            <>
              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.postcode")}
                    name={isPersonType() ? ["StreetAddress", "PostCode"] : ["ContactCustomer", "StreetAddress", "PostCode"]} colon={false} labelAlign="left"
                    rules={[{ required: addressRequired, message: t("customer_detail.post_code_required") }]}
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...secondCols}>
                  <Form.Item
                      label={t("customer_detail.state")}
                      name={isPersonType() ? ["StreetAddress", "State"] : ["ContactCustomer", "StreetAddress", "State"]}
                      colon={false} labelAlign="left"
                      rules={[{ required: addressRequired, message: t("customer_detail.state_required") }]}
                      normalize={value => CommonService.excludeSpecialCharactersInput(value)}>
                      <Select className="text-body" style={{ width: "100%" }} onChange={()=>{}}>
                          {
                              bureaucracies.map(n => <Option key={n.Name} value={n.Name}>{n.Name}</Option>)
                          }
                      </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.suburb")}
                    name={isPersonType() ? ["StreetAddress", "Suburb"] : ["ContactCustomer", "StreetAddress", "Suburb"]} colon={false} labelAlign="left"
                    rules={[{ required: addressRequired, message: t("customer_detail.suburb_required") }]}
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...secondCols}>
                  <Form.Item
                    label={t("customer_detail.street_line_second")}
                    name={isPersonType() ? ["StreetAddress", "StreetLine2"] : ["ContactCustomer", "StreetAddress", "StreetLine2"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.street_line_first")}
                    name={isPersonType() ? ["StreetAddress", "StreetLine1"] : ["ContactCustomer", "StreetAddress", "StreetLine1"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input onChange={checkAddressRequired} />
                  </Form.Item>
                </Col>
                <Col {...secondCols}>
                  <Form.Item
                    label={t("customer_detail.number")}
                    name={isPersonType() ? ["StreetAddress", "StreetNo"] : ["ContactCustomer", "StreetAddress", "StreetNo"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }
        {
          process.env.REACT_APP_COUNTRY_CODE !== Constant.countryCodes.JP && (
            <>
              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.number")}
                    name={isPersonType() ? ["StreetAddress", "StreetNo"] : ["ContactCustomer", "StreetAddress", "StreetNo"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...secondCols}>
                  <Form.Item
                    label={t("customer_detail.street_line_first")}
                    name={isPersonType() ? ["StreetAddress", "StreetLine1"] : ["ContactCustomer", "StreetAddress", "StreetLine1"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input onChange={checkAddressRequired} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.street_line_second")}
                    name={isPersonType() ? ["StreetAddress", "StreetLine2"] : ["ContactCustomer", "StreetAddress", "StreetLine2"]} colon={false} labelAlign="left"
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col {...secondCols}>
                  <Form.Item
                    label={t("customer_detail.suburb")}
                    name={isPersonType() ? ["StreetAddress", "Suburb"] : ["ContactCustomer", "StreetAddress", "Suburb"]} colon={false} labelAlign="left"
                    rules={[{ required: addressRequired, message: t("customer_detail.suburb_required") }]}
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...firstCols}>
                  <Form.Item
                    label={t("customer_detail.state")}
                    name={isPersonType() ? ["StreetAddress", "State"] : ["ContactCustomer", "StreetAddress", "State"]} colon={false} labelAlign="left"
                    rules={[{ required: addressRequired, message: t("customer_detail.state_required") }]}
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <AutoComplete options={stateOptions} onSearch={stateSearch} />
                  </Form.Item>

                </Col>
                <Col {...secondCols}>
                  <Form.Item
                    label={t("customer_detail.postcode")}
                    name={isPersonType() ? ["StreetAddress", "PostCode"] : ["ContactCustomer", "StreetAddress", "PostCode"]} colon={false} labelAlign="left"
                    rules={[{ required: addressRequired, message: t("customer_detail.post_code_required") }]}
                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )
        }
        {/* <Form.Item {...btnLayout} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" className="btn">
            {t("common.next_label")}
          </Button>
        </Form.Item> */}
      </Form>
      {
        visibleModal &&
        <CustomerSelection handleCancel={closeCustomerSelection} objectData={customerSearchList} ></CustomerSelection>
      }
      <Row className='custom-footer'>
        <Col {...btnLayout} className="text-right">
          <Button type="primary" className="btn" onClick={submit}>
            {t("common.next_label")}
          </Button>
        </Col>
      </Row>
    </>
  )
})

export default CustomerDetail
import React, { useState, useEffect } from 'react';
import './viewLocation.scss'
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'antd';
import { GoogleMapWrapper } from '../../../components/googleMapWrapper/googleMapWrapper';
import { useGlobalState } from '../../../services/state';

const ViewLocation = (props: any) => {
    const { t } = useTranslation();
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');

    const closeForm = () => {
        props.handleCancel()
    }

    useEffect(() => {
        console.log(serviceDetailData)
    }, [])

    return (
        <Modal
            title={t("location_management.header_location")}
            visible={true}
            confirmLoading={false}
            onCancel={closeForm}
            footer={null}
            maskClosable={false}
            width={800}
        >
            <Row>
                <Col xs={{ span: 24 }}>
                    {
                        serviceDetailData && serviceDetailData.location &&
                        <GoogleMapWrapper
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}`}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `500px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            latitude={serviceDetailData.location.Latitude ? serviceDetailData.location.Latitude : -27.2311}
                            longitude={serviceDetailData.location.Longitude ? serviceDetailData.location.Longitude : 153.0060}
                        >

                        </GoogleMapWrapper>
                    }

                </Col>
            </Row>
        </Modal>
    )
}

export default ViewLocation
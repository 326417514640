import { Avatar, Calendar, Card, Checkbox, Col, Empty, Form, Input, List, Modal, Row, Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RepairOrderJob, ServiceAdvisor, ServiceOperationCode, UnavailableDate } from '../../../models/appModels';
import { ServiceBooking } from '../../../models/serviceBooking';
import { BookingManagementService, ClientService, CommonService } from '../../../services/services';
import { useGlobalState } from '../../../services/state';
import './updateBooking.scss'
import moment from 'moment'
import LoadingContext from '../../utils/loadingCompt/loadingContext';

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

var unavailableDate: UnavailableDate

const UpdateBooking = (props: any) => {
    const bookingTab = {
        BookingDetails: 1,
        Customer: 2,
        Service: 3
    }

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [serviceOperationCodes, setServiceOperationCodes] = useState<ServiceOperationCode[]>([]);
    const [timeSlots, setTimeSlots] = React.useState<Date[]>([]);
    const [currentAdvisor, setCurrentAdvisor] = useState<ServiceAdvisor | null>(null);
    const [availableAdvisors, setAvailableAdvisors] = useState<ServiceAdvisor[]>([]);
    const [advisors, setAdvisors] = useState<ServiceAdvisor[]>([]);
    const [, reRender] = React.useState<null>();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [changeDropOffTime, setChangeDropOffTime] = React.useState<boolean>(false);

    const { Option } = Select;
    const { TabPane } = Tabs;
    const { TextArea } = Input;

    const [activeTabKey, setActiveTabKey] = useState(bookingTab.BookingDetails.toString());


    const closeForm = () => {
        props.handleCancel()
    }

    const onSubmit = () => {
        let formData = form.getFieldsValue()
        if (isValidationData()) {
            let booking = Object.assign({}, props.bookingData)
            let advisor = currentAdvisor
            if ((!currentAdvisor || formData.ServiceAdvisorId == "random") && availableAdvisors.length > 0) {
                advisor = availableAdvisors[0]
                advisor.IsRandom = true
            }

            if ((!advisor && booking.ServiceAdvisor && booking.ServiceAdvisor && availableAdvisors.filter(x => x.DmsUserId == booking.ServiceAdvisor?.DmsUserId).length == 0)
                || (advisor && availableAdvisors.filter(x => x.DmsUserId == advisor?.DmsUserId).length == 0)
                || availableAdvisors.length == 0
            ) {
                form.setFields([{
                    name: "ServiceAdvisorId",
                    errors: [t("booking_detail.service_advisor_invalid")]
                }])
                return
            }
            booking.ServiceAdvisor = advisor
            // if (formData.Customer) {
            //     booking.Customer.Email = formData.Customer.Email
            //     booking.Customer.Mobile = formData.Customer.Mobile
            // }


            booking.CustomerComment = formData.CustomerComment
            let selectedServices = serviceOperationCodes.filter(x => x.IsSelected)
            if (selectedServices.length > 0) {
                let jobs = selectedServices.map((x, i) => {
                    let obj: RepairOrderJob = {
                        JobLineId: i + 1,
                        ServiceOperationCode: x,
                        ServiceOperationCodeId: x.Id,
                        Description: x.Description,
                        Id: 0,
                        ServiceBookingId: 0,
                        ServiceOperationCodeServiceCode: x.ServiceCode
                    }
                    return obj
                })
                if (formData.Note) {
                    let obj = {
                        JobLineId: jobs.length + 1,
                        Description: formData.Note,
                        Id: 0,
                        ServiceBookingId: 0,
                    } as RepairOrderJob

                    jobs.push(obj)
                }
                booking.Jobs = jobs

            }
            var dropoff = timeSlots.filter(s => getTimeSlotName(s) == formData.Appointment.DropOffTime)
            if (dropoff.length > 0) {
                booking.Appointment.DropOffTime = dropoff[0]
            }
            else {
                booking.Appointment.DropOffTime = props.bookingData?.Appointment.OriginalDropOffTime
            }
            if (booking.Appointment.DropOffTime) {
                showLoading()
                BookingManagementService.updateBookingManagement(booking)
                    .finally(() => dismissLoading())
                    .then(() => {
                        CommonService.presentToast('success', t("booking_management.update_booking_message", { bookingId: booking.Id }), t("common.notification_header"))
                        props.handleCancel(booking)
                    }).catch(error => {
                        CommonService.handleErrorResponse(error)
                    });
            }

        }
    };

    const isValidationData = () => {
        // if (booking.Customer && (!booking.Customer.Mobile || !booking.Customer.Email)) {
        //     setActiveTabKey(bookingTab.Customer.toString())
        //     return false
        // }
        if (!serviceOperationCodes.some(x => x.IsSelected)) {
            setActiveTabKey(bookingTab.Service.toString())
            return false
        }
        return true
    }

    const onChangeServiceList = (item: ServiceOperationCode) => {
        let temp = [...serviceOperationCodes]
        let selectedItems = temp.filter(x => x.Id == item.Id)
        if (selectedItems.length > 0) {
            selectedItems[0].IsSelected = !selectedItems[0].IsSelected
        }
        setServiceOperationCodes(temp)
    }

    const onChangeTab = (activeKey: any) => {
        setActiveTabKey(activeKey);
    };

    function disableDate(currentDate: any) {
        if (currentDate && unavailableDate && unavailableDate.UnavailableDates && unavailableDate.UnavailableDates.length > 0) {
            for (let i = 0; i < unavailableDate.UnavailableDates.length; i++) {
                let dateValue = new Date(unavailableDate.UnavailableDates[i]);
                if (currentDate.date() == dateValue.getDate() && currentDate.month() == dateValue.getMonth() && currentDate.year() == dateValue.getFullYear()) {
                    return true
                }
            }
        }
        if (currentDate && unavailableDate && unavailableDate.UnavailableDaysOfWeek && unavailableDate.UnavailableDaysOfWeek.length > 0) {
            if (unavailableDate.UnavailableDaysOfWeek.indexOf(currentDate.day()) >= 0) {
                return true;
            }
        }

        let today = moment()
        if (today.isAfter(currentDate, 'day')) {
            return true
        }

        let nextYear = today.add(1, 'y')
        if (nextYear.isBefore(currentDate, 'day')) {
            return true
        }

        return false;
    }

    const onDateChange = (value: any) => {
        let invalidDate = disableDate(value)
        if (!invalidDate) {
            let dateStr = `${value.year()}-${value.month() + 1}-${value.date()}`
            let selectedDate = new Date(dateStr)

            getTimeSlot(selectedDate)
        } else {
            setTimeSlots([])
        }

        form.setFieldsValue({
            Appointment: { DropOffTime: undefined }
        })
        setAvailableAdvisors([])
        setCurrentAdvisor(null)
    }

    const getTimeSlot = (date: Date) => {
        if (props.bookingData && props.bookingData.Appointment && props.bookingData.Appointment.Location) {
            showLoading()
            ClientService.getTimeSlotForBooking(props.bookingData.Appointment.Location.Id, date)
                .finally(() => dismissLoading())
                .then(result => {
                    if (result.data && result.data.length > 0) {
                        setTimeSlots(result.data.map(d => new Date(d)))
                    } else {
                        CommonService.presentToast("info", t("booking_detail.timeslot_warning"), t("common.notification_header"))
                    }
                })
                .catch(error => { });
        }
    }

    const onAdvisorChange = (value: string) => {
        if (value) {
            let advisor = advisors.filter(a => a.DmsUserId == value)
            if (advisor && advisor.length > 0) {
                setCurrentAdvisor(advisor[0]);
            }
        } else {
            setCurrentAdvisor(null);
        }
    }

    const onPanelChange = (value: any, mode: any) => {
        console.log(value, mode);
    }
    function filterServiceAdvisor(advisors: ServiceAdvisor[], selectedTimeSlot: Date | undefined, init = false) {
        let available = advisors.filter(a =>
            (!a.WorkshopTimeslotBookings || a.WorkshopTimeslotBookings.filter(x => {
                if (!selectedTimeSlot) {
                    return false;
                }
                const existBookingStart = moment(x.BookingStartTime);
                const existBookingEnd = moment(x.BookingEndTime);
                const selectedDate = moment(selectedTimeSlot);
                if (selectedDate.isSameOrAfter(existBookingStart) && selectedDate.isBefore(existBookingEnd)) {
                    return true;
                }
                return false;
            }).length == 0)
            || (init && props.bookingData && props.bookingData.ServiceAdvisor && props.bookingData.ServiceAdvisor.DmsUserId == a.DmsUserId)
        )
        return available
    }

    const handleChange = (value: any) => {
        clearServiceAdvisorForm()
        let selectedTimeSlot = timeSlots.find(t => getTimeSlotName(t) == value)
        // let available = advisors.filter(a => !a.BookedTime || (new Date(a.BookedTime)).toISOString() != selectedTimeSlot?.toISOString())
        setAvailableAdvisors(filterServiceAdvisor(advisors, selectedTimeSlot))
        setChangeDropOffTime(true)
    }

    function getTimeSlotName(time?: Date) {
        if (time) {
            let hour = time.getHours()
            let minute = time.getMinutes()
            let hourStr = hour > 9 ? hour.toString() : "0" + hour.toString()
            let minuteStr = minute > 9 ? minute.toString() : "0" + minute.toString()
            return `${hourStr}:${minuteStr}`
        }
        return ""
    }

    const disableTimeSelection = () => {
        let date = form.getFieldValue(["Appointment", "BookingDate"])
        return disableDate(date)
    }

    const clearServiceAdvisorForm = () => {
        let formValue = form.getFieldsValue();
        form.setFieldsValue({ ...formValue, "ServiceAdvisorId": "" })
        setCurrentAdvisor(null);
    }

    useEffect(() => {
        let formValue = null
        let locationId = undefined
        let bookingDetails = props.bookingData
        if (formValue) {
            form.setFieldsValue(formValue)
        }
        if (bookingDetails.Appointment && bookingDetails.Appointment.Location) {
            if (bookingDetails.Appointment.Location)
                locationId = bookingDetails.Appointment.Location.Id

            if (bookingDetails.Appointment.Location.Id) {
                Promise.all([
                    ClientService.getUnavailalbleBookingDays(bookingDetails.Appointment.Location.Id),
                    ClientService.getServiceAdvisors(bookingDetails.Appointment.Location.Id),
                    ClientService.getServiceOperationCodesByLocationId(props.bookingData.Vehicle?.Make?.MakeId, locationId)
                ])
                    .finally()
                    .then(response => {
                        if (response[0] && response[0].data) {
                            unavailableDate = response[0].data
                            reRender(null)

                            var defaultDay = new Date()
                            if (bookingDetails.Appointment && bookingDetails.Appointment.OriginalDropOffTime) {
                                defaultDay = new Date(`${bookingDetails.Appointment.OriginalDropOffTime.getFullYear()}-${bookingDetails.Appointment.OriginalDropOffTime.getMonth() + 1}-${bookingDetails.Appointment.OriginalDropOffTime.getDate()}`)
                            }
                            var disableToday = false
                            if (unavailableDate && unavailableDate.UnavailableDates && unavailableDate.UnavailableDates.length > 0) {
                                for (let i = 0; i < unavailableDate.UnavailableDates.length; i++) {
                                    var date = new Date(unavailableDate.UnavailableDates[i])
                                    if (defaultDay.getDate() == date.getDate() && defaultDay.getMonth() == date.getMonth() && defaultDay.getFullYear() == date.getFullYear()) {
                                        disableToday = true
                                        break
                                    }
                                }
                            }
                            if (!disableDate && unavailableDate.UnavailableDaysOfWeek && unavailableDate.UnavailableDaysOfWeek.length > 0) {
                                if (unavailableDate.UnavailableDaysOfWeek.indexOf(defaultDay.getDay()) >= 0) {
                                    disableToday = true
                                }
                            }
                            if (!disableToday) {
                                getTimeSlot(defaultDay)
                            } else {
                                CommonService.presentToast("info", t("booking_detail.timeslot_warning"), t("common.notification_header"))
                            }
                        }
                        if (response[1] && response[1].data) {
                            setAdvisors(response[1].data)
                            if (bookingDetails.Appointment && bookingDetails.Appointment.OriginalDropOffTime) {
                                // let available = response[1].data.filter(a => !a.BookedTime || (new Date(a.BookedTime)).toISOString() != (bookingDetails.Appointment.OriginalDropOffTime as Date).toISOString() ||
                                //     (bookingDetails.ServiceAdvisor && bookingDetails.ServiceAdvisor.DmsUserId == a.DmsUserId))
                                // setAvailableAdvisors(available)
                                setAvailableAdvisors(filterServiceAdvisor(response[1].data, (bookingDetails.Appointment.OriginalDropOffTime as Date), true));
                            }
                            if (bookingDetails.ServiceAdvisor && bookingDetails.ServiceAdvisor) {
                                let tempAdvisor

                                if (!bookingDetails.ServiceAdvisor.IsRandom) {
                                    if (bookingDetails.ServiceAdvisor.Name) {
                                        tempAdvisor = bookingDetails.ServiceAdvisor
                                    } else if (bookingDetails.ServiceAdvisor.DmsUserId) {
                                        let advisor = response[1].data.filter(a => a.DmsUserId == bookingDetails.ServiceAdvisor!.DmsUserId)
                                        if (advisor && advisor.length > 0) {
                                            bookingDetails.ServiceAdvisor = advisor[0]
                                            tempAdvisor = advisor[0]
                                        }
                                    }
                                    if (tempAdvisor) {
                                        setCurrentAdvisor(tempAdvisor)
                                        let formValue = form.getFieldsValue()
                                        form.setFieldsValue({
                                            ...formValue,
                                            ServiceAdvisorId: tempAdvisor.DmsUserId
                                        })
                                    }
                                }
                            } else if (bookingDetails.Appointment.Location.DefaultServiceAdvisor) {
                                let advisor = response[1].data.find(a => a.DmsUserId == bookingDetails.Appointment.Location.DefaultServiceAdvisor?.DmsUserId)
                                if (advisor) {
                                    setCurrentAdvisor(advisor)
                                    let formValue = form.getFieldsValue()
                                    form.setFieldsValue({
                                        ...formValue,
                                        ServiceAdvisorId: advisor.DmsUserId
                                    })
                                }
                            } else {
                                let formValue = form.getFieldsValue()
                                form.setFieldsValue({
                                    ...formValue,
                                    ServiceAdvisorId: "random"
                                })
                            }
                        }

                        if (response[2] && response[2].data) {
                            let temp = response[2].data.map(x => {
                                if (x.Id && bookingDetails.Jobs && bookingDetails.Jobs.length > 0) {
                                    if (bookingDetails.Jobs.some((y: any) => y.ServiceOperationCodeId && y.ServiceOperationCodeId == x.Id))
                                        x.IsSelected = true
                                }
                                return x
                            })
                            setServiceOperationCodes(temp)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        CommonService.handleErrorResponse(error)
                    })

            }
        }

        if (bookingDetails.Jobs && bookingDetails.Jobs.length > 0) {
            let otherJobs = bookingDetails.Jobs.filter((j: any) => !j.ServiceOperationCodeId && !j.DmsOperationCode)
            if (otherJobs && otherJobs.length > 0) {
                let note = ""
                otherJobs.forEach((job: any) => {
                    if (note) {
                        note += "\r\n"
                    }
                    note += job.Description
                })

                let formValue = {
                    Note: note
                }
                form.setFieldsValue(formValue)
            }
        }
        return () => {
            form.resetFields()
            ClientService.cancelRequest()
        }
    }, [])

    return (
        <Modal
            title={t("booking_management.edit_booking") + ' ' + props.bookingData?.Id}
            visible={true}
            onOk={onSubmit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.update")}
            cancelText={t("common.cancel")}
            className="update-booking-modal"
            maskClosable={false}
            width={1000}
        >
            <Form
                {...layout}
                name="customerForm"
                initialValues={props.bookingData}
                //onFinish={onSubmit}
                form={form}
            >
                <Tabs activeKey={activeTabKey} onChange={onChangeTab}>
                    <TabPane tab={t("booking_summary.booking_details")} key={bookingTab.BookingDetails}>
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item className="calendar-item" name={["Appointment", "BookingDate"]}>
                                    <Calendar fullscreen={false} onChange={onDateChange} onPanelChange={onPanelChange} disabledDate={disableDate} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item name={["Appointment", "DropOffTime"]} label={t("booking_detail.drop_off")} rules={[{ required: true, message: t("common.required_field") }]}>
                                            <Select className="text-body" style={{ width: "100%" }} onChange={handleChange} disabled={disableTimeSelection()}>
                                                {
                                                    timeSlots.map(n => <Option key={n.getTime()} value={getTimeSlotName(n)}>{getTimeSlotName(n)}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label={t("service_detail.service_advisor")}
                                            colon={false} labelAlign="left"
                                            className="advisor-item"
                                            name="ServiceAdvisorId"
                                        >
                                            <Select showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={currentAdvisor ? currentAdvisor.DmsUserId : undefined}
                                                onChange={onAdvisorChange}
                                            >
                                                <Option value="random">{t("booking_detail.random_advisor")}</Option>
                                                {
                                                    availableAdvisors.map((n, index) => <Option key={index} value={n.DmsUserId}>{n.Name}</Option>)
                                                }
                                            </Select>
                                        </Form.Item>
                                        <div style={{ fontStyle: "italic" }}>{t("service_detail.service_advisor_notice")}</div>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item name="CustomerComment" label={t("booking_detail.note")}
                                        normalize={value => CommonService.excludeSpecialCharactersInput(value) }>
                                            <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("booking_summary.customer_info")} key={bookingTab.Customer}>
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("customer_detail.mobile_phone")}
                                    name={["Customer", "Mobile"]} colon={false} labelAlign="left"
                                    // rules={[{ required: true, message: t("customer_detail.mobile_phone_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input disabled />
                                </Form.Item>

                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("customer_detail.email")}
                                    name={["Customer", "Email"]} colon={false} labelAlign="left"
                                    // rules={[{ type: "email", message: t("customer_detail.email_invalid") },
                                    // { required: true, message: t("customer_detail.email_required") }
                                    // ]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab={t("service_detail.service_jobs")} key={bookingTab.Service}>
                        <Row>
                            <Col xs={24}>
                                <List
                                    className='service-list'
                                    dataSource={serviceOperationCodes}
                                    locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                                    renderItem={(item, i) => (
                                        <List.Item>
                                            <Card key={i} onClick={() => onChangeServiceList(item)}>
                                                <Row justify='space-between' align='middle'>
                                                    <Col xs={{ span: 20 }}>
                                                        <div className='flex-display'>
                                                            <Avatar src={item.ImageUrl} shape="square" />
                                                            <label className={`title-field ${item.IsSelected ? 'color-primary' : ''}`}>{item.Name}</label>
                                                        </div>
                                                    </Col>
                                                    <Col xs={{ span: 4 }} className="text-right">
                                                        <Checkbox checked={item.IsSelected}></Checkbox>
                                                    </Col>

                                                </Row>
                                            </Card>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                            <Col xs={24}>
                                <Form.Item name="Note" label={t("booking_detail.note")}
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }>
                                    <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>

                </Tabs>


            </Form>
        </Modal>
    )
}

export default UpdateBooking
import React, { useState, useEffect } from 'react';
import './addUpdateRole.scss'
import { Form, Input, Modal, Select, Row, Col, Table, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppPageRole, ModalOptions, RepairOrderJob, Role, RoleCategory } from '../../../models/appModels';
import {
    CommonService as commonService,
    Constant,
    ClientService,
    UserManagementService,
    CommonService
} from './../../../services/services'
import LoadingContext from './../../utils/loadingCompt/loadingContext'


const { Option } = Select;
const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const roleJobModel = commonService.propToString<Role>()

export interface RoleModalOptions extends ModalOptions<Role> {
    statusList: any[],
    roleCategoryList: RoleCategory[],
    pageList: any[]
}

const AddUpdateRole = (props: RoleModalOptions) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    const [form] = Form.useForm();
    const [pageData, setPageData] = useState<any[]>([])

    const onSubmit = (role: Role) => {
        let selectedPages = pageData.filter(x => x.AllowAccess).map(y => {
            let obj: AppPageRole = {
                PageId: y.Id,
                RoleId: props.objectData ? props.objectData.Id : null,
                AllowModify: y.AllowModify
            }
            return obj
        })
        role.AppPageRole = selectedPages
        if (props.objectData) {
            updateRole(role, props.objectData.Id)
        }
        else {
            addRole(role)
        }
    };

    const addRole = (role: Role) => {
        showLoading()
        UserManagementService.addRole(role)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('role_management.create_successful'), t("common.notification_header"))
                    props.handleCancel(role)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const updateRole = (role: Role, id: string) => {
        role.Id = id
        showLoading()
        UserManagementService.updateRole(role)
        .finally(() => dismissLoading())
        .then(result => {
            if (!result.data){
                commonService.presentToast('success', t('role_management.update_successful'), t("common.notification_header"))
                props.handleCancel(role)
            }
        })
        .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const onCategoryChange = (newValue: any) => {
        if (newValue) {
            let selectedCategory = props.roleCategoryList.filter(x => x.Code == newValue)

            if (selectedCategory.length > 0) {
                let pages = props.pageList.map(x => {
                    let allowPages = selectedCategory[0].AppPageRoleCategory.filter(y => y.PageId == x.Id)
                    x.AllowAccess = allowPages.length > 0 ? true : false
                    x.AllowModify = false
                    return x
                })
                setPageData(pages)
            }
        }
    }

    const onCheckboxChange = (e: any, record: any) => {
        let pages = props.pageList.map(x => {
            if (record.Id == x.Id) {
                x.AllowAccess = !record.AllowAccess
                if(!x.AllowAccess){
                    x.AllowModify = false
                }
            }
            return x
        })
        setPageData(pages)
    }

    const onAllowModifyCheckboxChange = (e: any, record: any) => {
        let pages = props.pageList.map(x => {
            if (record.Id == x.Id) {
                x.AllowModify = !record.AllowModify
            }
            return x
        })
        setPageData(pages)
    }

    useEffect(() => {
        let pages: any[] = []
        if (!props.objectData) {
            pages = props.pageList.map(x => Object.assign({}, x))
            form.setFieldsValue({
                [roleJobModel.StatusCode]: Constant.statusCode.Active
            });
            
        }
        else {
            pages = props.pageList.map(x => {
                let selectedPages = props.objectData.AppPageRole.filter((y: any) => y.PageId == x.Id)
                if (selectedPages && selectedPages.length > 0){
                    x.AllowAccess = true
                    x.AllowModify = selectedPages[0].AllowModify ? true : false
                }
                    
                return x
            })
        }

        setPageData(pages)
        return () => {
            UserManagementService.cancelRequest()
        }
    }, [])
    return (
        <Modal
            title={!props.objectData ? t("role_management.add_role") : t("role_management.update_role")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className="portal-modal"
            maskClosable={false}
        >
            <Form
                {...layout}
                form={form}
                name="roleForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Row>
                    <Col {...firstCols}>
                        <Form.Item
                            label={t("role_management.role_id")}
                            name={roleJobModel.Name} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("role_management.role_id_required") }]}
                            normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                        >
                            <Input maxLength={Constant.limitLength.length512} />
                        </Form.Item>
                    </Col>
                    <Col {...secondCols}>
                        <Form.Item
                            label={t("role_management.status")}
                            name={roleJobModel.StatusCode} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("role_management.status_required") }]}
                        >
                            <Select>
                                {
                                    props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col {...firstCols}>
                        <Form.Item
                            label={t("common.description")}
                            name={roleJobModel.Description} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("role_management.description_required") }]}
                        >
                            <TextArea maxLength={Constant.limitLength.length512} autoSize={{ minRows: 1, maxRows: 4 }} />
                        </Form.Item>
                    </Col>
                    <Col {...secondCols}>
                        <Form.Item
                            label={t("role_management.category")}
                            name={roleJobModel.RoleCategoryCode} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("role_management.category_required") }]}
                        >
                            <Select onChange={onCategoryChange}>
                                {
                                    props.roleCategoryList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label={t("role_management.access")}
                            colon={false} labelAlign="left"
                        >
                            <Table className="custom-table"
                                columns={[
                                    {
                                        title: t('role_management.page'),
                                        dataIndex: 'Description',
                                        key: 'Id'
                                    },
                                    {
                                        title: t('role_management.display'),
                                        dataIndex: 'AllowAccess',
                                        key: 'AllowAccess',
                                        render: (value: any, record: any) => (
                                            <Checkbox
                                                checked={record.AllowAccess}
                                                className="portal-checkbox"
                                                onChange={e => onCheckboxChange(e, record)} />
                                        ),
                                        align: 'center'
                                    },
                                    {
                                        title: t('role_management.action'),
                                        dataIndex: 'AllowModify',
                                        key: 'AllowModify',
                                        render: (value: any, record: any) => (
                                            <Checkbox
                                                disabled={!record.AllowAccess}
                                                checked={record.AllowModify}
                                                className="portal-checkbox"
                                                onChange={e => onAllowModifyCheckboxChange(e, record)} />
                                        ),
                                        align: 'center'
                                    }
                                ]}
                                rowKey="Id"
                                dataSource={pageData}
                                pagination={false}
                            />
                        </Form.Item>

                    </Col>

                </Row>
            </Form>

        </Modal>
    )
}

export default AddUpdateRole

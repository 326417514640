import axios from 'axios';
import { BaseObjectManagement, Make, Model, ModelType, Role, RoleCategory, ServiceOperationCode } from '../models/appModels';
import { axiosPortal } from './../services/authService'
import { Constant } from './services'

const source: any[] = [];
let baseURL = "/Booking"
export const ServiceOperationCodeService = {
    getServiceOperationCodes: function (pageNumber: number, pageSize: number, sort: string, filterList: any[]) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let filters = ""
        if (filterList.length > 0) {
            filters = JSON.stringify(filterList)
        }
        return axiosPortal.get<BaseObjectManagement<ServiceOperationCode>>(`${baseURL}/GetServiceOperationCodes?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}&Filters=${filters}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getRoleCategories: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<RoleCategory[]>(`${baseURL}/GetRoleCategories`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addServiceOperationCode: function (data: ServiceOperationCode) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.post<string>(`${baseURL}/AddServiceOperationCode`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    updateServiceOperationCode: function (data: ServiceOperationCode) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.put<string>(`${baseURL}/UpdateServiceOperationCode/${data.Id}`, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getAllMakes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Vehicle/MakeFromModels?isRequireModel=false"
        return axiosPortal.get<Make[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getBMWThailandMakes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Vehicle/Makes"
        return axiosPortal.get<Make[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getMakesByCountry(countryCode?: string) {
        if (countryCode == Constant.countryCodes.TH)
            return this.getBMWThailandMakes()

        return this.getAllMakes();
    },
    getServiceOperationCodeByOpCodeAndModelId: function (opCpde: string, modelId?: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetServiceOperationCodeByOpCodeAndModelId?opCode=${opCpde}`
        if (modelId) {
            url += `&modelId=${modelId}`
        }
        return axiosPortal.get<ServiceOperationCode>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getServiceOperationCodesByOpCode: function (opCpde: string, dealershipId?: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `${baseURL}/GetServiceOperationCodeByOpCodes?opCode=${opCpde}`

        if (dealershipId) {
            url += `&dealershipId=${dealershipId}`
        }

        return axiosPortal.get<ServiceOperationCode[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getModelTypes: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Vehicle/GetModelTypes"
        return axiosPortal.get<ModelType[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getModels: function (makeId?: string, modelTypeId?: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = "/Vehicle/Models?"
        if (makeId)
            url += `makeId=${makeId}`
        if (modelTypeId)
            url += `modelTypeId=${modelTypeId}`
        return axiosPortal.get<Model[]>(url, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }

}
import React, { useEffect, useState, forwardRef, useImperativeHandle, useContext, useRef } from 'react';
import './vehicleDetail.scss'
import { Button, Form, Input, Select, Row, Col, Checkbox, InputNumber, Card, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../services/state';
import { Vehicle, BookingData } from '../../models/appModels';
import { CommonService as commonService } from './../../services/commonService'
import { Constant, ClientService } from '../../services/services'
import { Make } from '../../models/make';
import { Model } from '../../models/model';
import { useLocation } from "react-router-dom";
import ModelSelection from './modelSelection/modelSelection';
import { DownOutlined } from '@ant-design/icons';
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import AddUpdateVehicle from './addUpdateVehicle/addUpdateVehicle';
import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Navigation } from 'swiper';
import _ from 'underscore'

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const cols = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 22, offset: 1 }
}

const btnLayout = {
  xs: { span: 24 },
  md: { span: 23 }
};

const vehicleModel = commonService.propToString<Vehicle>()

const VehicleDetail = forwardRef((props: any, ref) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [vehicleDetail, setVehicleDetail] = useGlobalState('vehicleDetailData');
  const [makeList, setMakeList] = useGlobalState('makeList');
  const [bureaucracies, setBureaucracies] = useGlobalState('BureaucracyList');
  const [modelList, setModelList] = useGlobalState('modelList');
  const [customerDetail] = useGlobalState('customerDetailData');
  const location = useLocation();
  const [existedVehicle, setExistedVehicle] = useState<boolean>(false);
  const { showLoading, dismissLoading } = React.useContext(LoadingContext);
  const [dealershipId, setDealershipId] = useGlobalState('dealershipId');
  const [, setOriginalVehicleMake] = useGlobalState('originalVehicleMake');
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentSelectedVehicle, setCurrentSelectedVehicle] = useState<Vehicle | null>();
  const [ownedVehiclesData, setOwnedVehiclesData] = useGlobalState('ownedVehiclesData');
  const [bookingSearchData,] = useGlobalState('bookingSearchData');
  const [loadSearchValue, setLoadSearchValue] = useState<boolean>(false);

  const swiperRef = useRef() as any;

  let previousFormData: any

  React.useEffect(() => {
    let state: any = location.state
    if (state && state.doSubmit) {
      form.validateFields()
    }
  }, [location])

  function changeStep(data: Vehicle, saveOnly: boolean = false) {
    // if (data.Make) {
    //   let make = makeList.filter(m => m.MakeId == data.Make.MakeId)
    //   if (make && make.length > 0) {
    //     data.Make = make[0]
    //   }
    //   if (data.Make.Id > 0) {
    //     data.MakeId = data.Make.Id
    //   }
    // }
    // if (data.Model) {
    //   let model = modelList.filter(m => m.Code == data.Model.Code)
    //   if (model && model.length > 0) {
    //     data.Model = model[0]
    //   }
    //   if (data.Model.Id > 0) {
    //     data.ModelId = data.Model.Id
    //   }

    //   if (data.Model.ModelType) {
    //     let modelType = modelTypeList.filter(m => m.Code == data.Model.ModelType.Code)
    //     if (modelType && modelType.length > 0) {
    //       data.Model.ModelType = modelType[0]
    //     }
    //     if (data.Model.ModelType.Id > 0) {
    //       data.Model.ModelTypeId = data.Model.ModelType.Id
    //     }
    //   }
    // }

    let vehicle = Object.assign(vehicleDetail, data)
    setVehicleDetail(vehicle)
    if (!saveOnly) {
      props.changeStep(Constant.bookingStep.serviceDetail)
    }
  }

  useImperativeHandle(ref, () => ({

    formHasValue() {
      if (form.getFieldValue(vehicleModel.RegoNo) ||
        form.getFieldValue(["Make", "MakeId"]) ||
        form.getFieldValue("Description") ||
        form.getFieldValue("ModelYear") ||
        form.getFieldValue("OdometerReading") ||
        form.getFieldValue("RegoNo"))
        return true
      return false
    },
    saveData() {
      //let data = form.getFieldsValue() as Vehicle
      // data.Id = vehicleDetail.Id
      // changeStep(data, true)
      let data = vehicleDetail
      changeStep(data, true)
    }

  }));

  const getMakes = () => {
    //showLoading()
    ClientService.getMakesByCountry(process.env.REACT_APP_COUNTRY_CODE)
      .finally(() => {
        //dismissLoading()
      })
      .then(result => {
        if (result)
          setMakeList(result.data)

      })
      .catch(error => {
        commonService.handleErrorResponse(error)
      });
  }

  const getBureaucracies = () => {
    showLoading()
    ClientService.getBureaucracies(dealershipId)
    .then(result => {
      setBureaucracies(result?.length > 0 ? result : []);
    })
    .catch(error => commonService.handleErrorResponse(error))
    .finally(() => {
      dismissLoading()
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_COUNTRY_CODE === Constant.countryCodes.JP && bureaucracies.length === 0 ) {
      getBureaucracies();
    }
    
    if (makeList.length == 0) {
      getMakes()
    }
    // if (vehicleDetail.Id > 0 || (vehicleDetail.DmsVehicleKey && vehicleDetail.DmsVehicleKey > 0)) {
    //   setExistedVehicle(true)
    // }
    let temp = (ownedVehiclesData && ownedVehiclesData.length > 0) ? ownedVehiclesData : (isValidVehicle() ? [vehicleDetail] : [])
    temp = temp.map((item: Vehicle, i: number) => {
      item.Index = i
      if (customerDetail &&
          ((customerDetail.Id > 0 && item.OwnerCustomerId > 0 && customerDetail.Id == item.OwnerCustomerId) ||
          (item.OwnerCustomer && customerDetail.DmsCustomerKey > 0 && item.OwnerCustomer.DmsCustomerKey > 0 && customerDetail.DmsCustomerKey == item.OwnerCustomer.DmsCustomerKey))) {
        item.IsCarOwner = true;
      }

      return item
    })
    setOwnedVehiclesData(temp)
    if (isValidVehicle()) {
      if (customerDetail &&
          ((customerDetail.Id > 0 && vehicleDetail.OwnerCustomerId > 0 && customerDetail.Id == vehicleDetail.OwnerCustomerId) ||
          (vehicleDetail.OwnerCustomer && customerDetail.DmsCustomerKey > 0 && vehicleDetail.OwnerCustomer.DmsCustomerKey > 0 && customerDetail.DmsCustomerKey == vehicleDetail.OwnerCustomer.DmsCustomerKey))) {
        vehicleDetail.IsCarOwner = true
        setVehicleDetail(vehicleDetail)
      }

      let currenVehicles = temp.filter(x => x.Index == vehicleDetail.Index ||
        (x.Id > 0 && x.Id == vehicleDetail.Id) ||
        (x.DmsVehicleKey && x.DmsVehicleKey > 0 && x.DmsVehicleKey == vehicleDetail.DmsVehicleKey))
      if (currenVehicles.length > 0) {
        let index = temp.indexOf(currenVehicles[0])
        if (swiperRef && swiperRef.current)
          swiperRef.current.slideTo(index)
      }
    }
    else {
      addVehicle(true)
    }


    return () => {
      ClientService.cancelRequest()
    }
  }, [])

  const setpreviousFormData = () => {
    previousFormData = form.getFieldsValue()
  }

  const checkFieldChange = (fieldName: string, newValue: any) => {
    if (previousFormData) {
      if (previousFormData[fieldName] != newValue) {
        return true
      }
    } else if (newValue) {
      return true
    }

    return false;
  }

  const checkAndGetExistingVehicle = (fieldData: string) => {
    let value = form.getFieldValue(fieldData)
    if (checkFieldChange(fieldData, value)) {
      GetExistingVehicle()
    }
  }

  function GetExistingVehicle() {
    const values = form.getFieldsValue();
    if (values.RegoNo && customerDetail && customerDetail.Mobile) {
      showLoading()
      ClientService.searchVehicle(values.RegoNo, customerDetail.Mobile, dealershipId, customerDetail.IsOrganisation)
        .finally(() => dismissLoading())
        .then(res => {
          if (res) {
            if (commonService.validateVehicleMakeSearch(res.Booking, makeList)) {
              setupData(res)
            }
          }
        })
        .catch(error => commonService.handleErrorResponse(error));
    }
  }

  function setupData(data: BookingData) {
    if (data) {
      if (data && data.Booking && data.Booking.Vehicle) {
        if (data.Booking.Customer && data.Booking.Customer.Id == data.Booking.Vehicle.OwnerCustomerId) {
          data.Booking.Vehicle.IsCarOwner = true
        }
        form.setFieldsValue(data.Booking.Vehicle)
        setVehicleDetail(data.Booking.Vehicle)
        setOriginalVehicleMake(data.Booking.Vehicle.MakeId)
        setExistedVehicle(true)
      }
    }
  }

  const handleCancel = (objectData?: Vehicle) => {
    setVisibleModal(false)
    setLoadSearchValue(false)
    if (objectData) {
      let temp = ownedVehiclesData ? ownedVehiclesData : []
      if (objectData.Index != null && objectData.Index != undefined) {
        temp[objectData.Index] = objectData
      }
      else {
        objectData.Index = temp.length
        temp.push(objectData)
      }

      setOwnedVehiclesData(temp)
      setVehicleDetail(objectData)
      if (swiperRef && swiperRef.current)
        swiperRef.current.slideTo(objectData.Index)
    }
    else {
      if (ownedVehiclesData && ownedVehiclesData.length > 0 && swiperRef && swiperRef.current) {
        let currentIndex = swiperRef.current.activeIndex;
        if (currentIndex != null && currentIndex != undefined)
          setVehicleDetail(ownedVehiclesData[currentIndex])
      }
    }

  };

  const addVehicle = (isLoadSearchValue: boolean = false) => {
    setVisibleModal(true)
    if (!isLoadSearchValue)
      setCurrentSelectedVehicle(null)
    else {
      let searchObj = {
        RegoNo: bookingSearchData && !bookingSearchData.IsVin ? bookingSearchData.CardRegistration : "",
        Vin: bookingSearchData && bookingSearchData.IsVin ? bookingSearchData.CardRegistration : ""
      }
      setCurrentSelectedVehicle(searchObj as Vehicle)
      setLoadSearchValue(isLoadSearchValue)
    }

  }

  const updateVehicle = (objectData: Vehicle) => {
    setVisibleModal(true)
    setCurrentSelectedVehicle(objectData)
  }

  const onSlideChange = (activeIndex: number) => {
    if (ownedVehiclesData && ownedVehiclesData.length > 0) {
      let vehicle = ownedVehiclesData[activeIndex]
      setVehicleDetail(vehicle)
    }
  }

  const submit = () => {
    form.submit()
  }

  const isValidVehicle = () => {
    if (vehicleDetail && (vehicleDetail.Id > 0 || (vehicleDetail.DmsVehicleKey && vehicleDetail.DmsVehicleKey > 0)))
      return true
    return false
  }

  return (
    <>
      <Form
        {...layout}
        name="vehicleForm"
        initialValues={vehicleDetail}
        onFinish={changeStep}
        form={form}
        className=" m-b-10"
      >
        <Row className="align-center">
          <Col xs={{ span: 19 }} md={{ span: 19, offset: 1 }}>
            <label className="title-field bold-text">{t("vehicle_detail.select_vehicle")}</label>
          </Col>
          <Col xs={{ span: 4 }} md={{ span: 3 }} className='text-right'>
            <Button className="btn has-icon-btn" type="primary" icon={<PlusOutlined />}
              onClick={() => addVehicle()}>{t("common.add")}</Button>
          </Col>
        </Row>
        <Row className='m-t-20' align='middle'>
          <Col {...cols}>
            <Card className='vehicle-details'>
              {
                ((!ownedVehiclesData || ownedVehiclesData.length == 0) && <div className='no-vehicle-container'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("vehicle_detail.vehicle_not_found")} /></div>)
              }
              {ownedVehiclesData.length > 0 && <Swiper navigation={true} modules={[Navigation]}
                onSlideChange={(swiper) => {
                  const {
                    activeIndex
                  } = swiper;
                  onSlideChange(activeIndex)
                }}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper;
                }}>
                {
                  ownedVehiclesData.map((item: Vehicle, i) => {
                    return <SwiperSlide key={i}>
                      <Row className='title'>
                        <Col span={18}>
                          <label className="title-field uppercase-text bold-text m-b-10">{t("vehicle_detail.vehicle_info")}</label>
                        </Col>
                        <Col span={5} className="text-right">
                          <Button shape="round" icon={<EditFilled />} onClick={() => updateVehicle(item)} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 24 }}>
                          <label className="title-field">{t("vehicle_detail.car_registration")}</label>
                          <label className="text-field m-b-10">{item.RegoNo || '_'}</label>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <label className="title-field">{t("vehicle_detail.make")}</label>
                          <label className="text-field m-b-10">{item.Make?.MakeId || '_'}</label>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                          <label className="title-field">{t("vehicle_detail.vin")}</label>
                          <label className="text-field m-b-10">{item.Vin || '_'}</label>
                        </Col>
                        <Col xs={{ span: 24 }}>
                          <label className="title-field">{t("vehicle_detail.model")}</label>
                          <label className="text-field m-b-10">{item.Description || '_'}</label>
                        </Col>
                        <Col xs={{ span: 12 }}>
                          <label className="title-field">{t("vehicle_detail.year")}</label>
                          <label className="text-field m-b-10">{item.ModelYear || '_'}</label>
                        </Col>
                        <Col xs={{ span: 12 }}>
                          <label className="title-field">{t("vehicle_detail.odometer")}</label>
                          <label className="text-field m-b-10">{item.OdometerReading || '_'}</label>
                        </Col>
                      </Row>
                    </SwiperSlide>
                  })
                }
              </Swiper>
              }
            </Card>
          </Col>
        </Row>

        {
          visibleModal &&
          <AddUpdateVehicle handleCancel={handleCancel} objectData={currentSelectedVehicle} makeList={makeList}
            customerDetail={customerDetail} dealershipId={dealershipId} ownedVehiclesData={ownedVehiclesData} bureaucracies={bureaucracies}></AddUpdateVehicle>
        }

      </Form>
      <Row className='custom-footer'>
        <Col {...btnLayout} className="text-right">
          <Button type="primary" className="btn" onClick={submit}>
            {t("common.next_label")}
          </Button>
        </Col>
      </Row>
    </>
  )
})

export default VehicleDetail

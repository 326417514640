import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Input, Form, TimePicker, Row, Col, Radio, Empty, DatePicker, Checkbox } from 'antd';
import { EditFilled, DeleteOutlined, CheckCircleOutlined, DeliveredProcedureOutlined } from '@ant-design/icons'
import { FormInstance } from 'antd/lib/form';
import './gridColumn.scss'
import { CommonService, Constant, PaginationSize } from '../../../services/services';
import { useGlobalState } from './../../../services/state';
import moment from 'moment'

const { RangePicker } = TimePicker;

export const EditDeleteCommandCell = (props: any) => {
    const { t } = useTranslation();

    return (
        <td className="text-center">
            <Tooltip placement="bottom" title={t('common.update')}>
                <Button type="primary" icon={<EditFilled />} size='small'
                    onClick={() => props.editFunction(props.data)} />
            </Tooltip>
            <Tooltip placement="bottom" title={t('common.delete')}>
                <Button type="primary" icon={<EditFilled />} size='small'
                    onClick={() => props.deleteFunction(props.data)} />
            </Tooltip>
        </td>
    );
};

export const EditCommandCell = (props: any) => {
    const { t } = useTranslation();
    return (
        <span className="grid-action-btn">
            <Tooltip placement="bottom" title={t('common.edit')}>
                <Button type="primary" icon={<EditFilled />} size='small'
                    onClick={() => props.editFunction(props.data)} />
            </Tooltip>
        </span>
    );
};

export const DeleteCommandCell = (props: any) => {
    const { t } = useTranslation();
    return (
        <span className="grid-action-btn">
            <Tooltip placement="bottom" title={props.tooltip}>
                <Button type="primary" className='delete-button' icon={<DeleteOutlined />} size='small'
                    onClick={() => props.deleteFunction(props.data)} />
            </Tooltip>
        </span>
    );
};

export const ConfirmCommandCell = (props: any) => {
    const { t } = useTranslation();
    return (
        <span className="grid-action-btn">
            <Tooltip placement="bottom" title={props.tooltip}>
                <Button type="primary" className='confirmed-button' icon={<CheckCircleOutlined />} size='small'
                    onClick={() => props.processFunction(props.data)} />
            </Tooltip>
        </span>
    );
};

export const StartCommandCell = (props: any) => {
    const { t } = useTranslation();
    return (
        <span className="grid-action-btn">
            <Tooltip placement="bottom" title={props.tooltip}>
                <Button type="primary" className='start-button' icon={<DeliveredProcedureOutlined />} size='small'
                    onClick={() => props.processFunction(props.data)} />
            </Tooltip>
        </span>
    );
};

export const StatusCell = (props: any) => {
    return (
        <span className={"status-tag " + (props.data.StatusCode == 1 ? "active-tag" : "")}>
            <Tooltip placement="bottom" title={props.data.StatusCode == 1 ? props.activeStatus : props.inactiveStatus}>
                {props.data.StatusCode == 1 ? props.activeStatus : props.inactiveStatus}
            </Tooltip>
        </span>
    );
};

export const BookingStatusCell = (props: any) => {
    const { t } = useTranslation();
    let statusObject: any[] = [
        {
            ClassName: "active-tag",
            Name: t('common.active')
        },
        {
            ClassName: "",
            Name: t('common.cancelled')
        },
        {
            ClassName: "complete-tag",
            Name: t('common.complete')
        },
        {
            ClassName: "pending-tag",
            Name: t('common.pending')
        }
    ]

    return (
        <span className={"status-tag " + (statusObject[props.data - 1] ? statusObject[props.data - 1].ClassName : "")}>
            {statusObject[props.data - 1] ? statusObject[props.data - 1].Name : t('common.complete')}
        </span>
        // <span className={"status-tag " + (props.data == Constant.bookingStatusCode.Active ? "active-tag" :
        //     (props.data == Constant.bookingStatusCode.Cancelled ? "" : "complete-tag"))}>
        //     {props.data == Constant.bookingStatusCode.Active ? props.activeStatus :
        //         (props.data == Constant.bookingStatusCode.Cancelled ? props.cancelStatus : props.completeStatus)}
        // </span>
    );

};

export const BookingActionTypeCell = (props: any) => {
    const getStatusClass = () => {
        if (props.data == Constant.bookingActionType.Submit)
            return "active-tag"
        else if (props.data == Constant.bookingActionType.Update)
            return "pending-tag"
        else if (props.data == Constant.bookingActionType.Confirm)
            return "confirm-tag"
        else if (props.data == Constant.bookingActionType.CompleteFromDms)
            return "complete-tag"
        return ""
    }
    return (
        <span className={`status-tag ${getStatusClass()}`}>
            <Tooltip placement="bottom" title={props.description}>
                {props.description}
            </Tooltip>
        </span>
    );
};

export const ItemList = (props: any) => {
    const { t } = useTranslation();
    const getListItemDescription = () => {
        let result = props.data.map((item: any) => {
            let selectedPages = props.itemList.filter((y: any) => y.Id == item.PageId)
            if (selectedPages && selectedPages.length > 0)
                return selectedPages[0].Description
        })
        if (result.length > 0)
            return result.join(", ")
        return ""
    }
    return (
        <span>
            {props.itemList.length == props.data.length && <span>{t('role_management.all')}</span>}
            {props.itemList.length != props.data.length &&
                <span>{getListItemDescription()}</span>
            }
        </span>
    );
};

interface EditableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    dataIndexex: any,
    record: any;
    cellType: number;
    handleSave: (record: any) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    title,
    editable,
    children,
    dataIndex,
    dataIndexex,
    record,
    cellType,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<Input>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (cellType == Constant.cellType.Checkbox) {
            form.setFieldsValue(record)
        }

    }, [children])

    const toggleEdit = () => {
        setEditing(!editing);
        if (cellType == Constant.cellType.Input) {
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        } else if (cellType == Constant.cellType.TimeRange) {
            let values: any[] = []
            dataIndexex.forEach((index: any) => {
                if (record[index]) {
                    values.push(moment(record[index]))
                } else {
                    values.push(null)
                }
            });
            form.setFieldsValue({ [dataIndex]: values });
        }
    };

    const save = async (data: any) => {
        try {
            if (cellType == Constant.cellType.Input) {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } else if (cellType == Constant.cellType.TimeRange) {
                if (data && dataIndexex && data.length == dataIndexex.length) {
                    for (let i = 0; i < dataIndexex.length; i++) {
                        record[dataIndexex[i]] = new Date(data[i])
                    }
                    handleSave(record);
                }
                toggleEdit();
            }
            else if(cellType == Constant.cellType.Checkbox){
                record[dataIndexex] = data.target ? data.target.checked : false
                handleSave(record);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const getCell = () => {
        if (cellType == Constant.cellType.Input) {
            return (
                <Form form={form}>
                    <Form.Item
                        style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                        normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                    >
                        <Input ref={inputRef} onPressEnter={save} />
                    </Form.Item>
                </Form>
            )
        } else if (cellType == Constant.cellType.TimeRange) {
            return (
                <Form form={form}>
                    <Form.Item
                        style={{ margin: 0 }}
                        name={dataIndex}
                        rules={[
                            {
                                required: true,
                                message: `${title} is required.`,
                            },
                        ]}
                    >
                        <RangePicker format="HH:mm" ref={inputRef} onChange={save} />
                    </Form.Item>
                </Form>
            )
        }
        else if (cellType == Constant.cellType.Checkbox) {
            return (
                <Form form={form}>
                    <Form.Item
                        style={{
                            margin: 0,
                        }}
                        name={dataIndex}
                        valuePropName="checked"
                    >
                        <Checkbox onChange={save}/>

                    </Form.Item>
                </Form>
            )
        }
    }

    let childNode = children;

    if (editable) {
        if (cellType == Constant.cellType.Input || cellType == Constant.cellType.TimeRange) {
            childNode = editing ? (
                getCell()
            ) : (
                <div className="editable-cell-value-wrap cell-hover" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                    {children}
                </div>
            );
        }
        else {
            childNode = getCell()
        }
    }

    return <td {...restProps}>{childNode}</td>;
};

export const GetColumnTextFilterProps = () => {
    const { t } = useTranslation();
    return (
        {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }: any) => (
                <div className="custom-filter-dropdown">
                    <Form>
                        <Form.Item labelCol={{ span: 24 }}
                            label={t("common.filter_value")}
                        >
                            <Input
                                value={selectedKeys[0]}
                                onChange={e =>
                                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                                }
                                onPressEnter={() => confirm()}
                            />
                        </Form.Item>
                        <div className="ant-table-filter-dropdown-btns custom-btns">
                            <Button type="link" size="small" onClick={() => clearFilters()} disabled={!selectedKeys[0]}>
                                {t('common.reset')}
                            </Button>
                            <Button type="primary" size="small" onClick={() => confirm()}>
                                {t('common.filter')}
                            </Button>
                        </div>
                    </Form>
                </div>
            )
        })
};

export const GetColumnStatusFilterProps = (props: any) => {
    const { t } = useTranslation();
    return (
        {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }: any) => (
                <div className="custom-filter-dropdown">
                    <Form>
                        <Radio.Group onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            value={selectedKeys[0]}>
                            <Radio value={Constant.statusCode.Active}>{props.activeText}</Radio>
                            <Radio value={Constant.statusCode.Inactive}>{props.inactiveText}</Radio>
                        </Radio.Group>
                        <div className="ant-table-filter-dropdown-btns custom-btns">
                            <Button type="link" size="small" onClick={() => clearFilters()} disabled={!selectedKeys[0]}>
                                {t('common.reset')}
                            </Button>
                            <Button type="primary" size="small" onClick={() => confirm()}>
                                {t('common.filter')}
                            </Button>
                        </div>
                    </Form>
                </div>
            )
        })
};

export const GetColumnDateRangeFilterProps = () => {
    const { t } = useTranslation();
    const [dateFormat, setDateFormat] = useGlobalState('dateFormat');
    return (
        {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }: any) => (
                <div className="custom-filter-dropdown">
                    <Form>
                        <Form.Item labelCol={{ span: 24 }}
                            label={t("common.date_from")}
                        >
                            <DatePicker format={dateFormat}
                                value={selectedKeys[0]?.DateFrom}
                                onChange={e =>
                                    setSelectedKeys((!e && (!selectedKeys[0] || !selectedKeys[0].DateTo)) ? [] : [{ DateFrom: e, DateTo: (selectedKeys && selectedKeys.length > 0 ? selectedKeys[0].DateTo : "") }])
                                } />
                        </Form.Item>
                        <Form.Item labelCol={{ span: 24 }}
                            label={t("common.date_to")}
                        >
                            <DatePicker format={dateFormat}
                                value={selectedKeys[0]?.DateTo}
                                onChange={e =>
                                    setSelectedKeys((!e && (!selectedKeys[0] || !selectedKeys[0].DateFrom)) ? [] : [{ DateFrom: (selectedKeys && selectedKeys.length > 0 ? selectedKeys[0].DateFrom : ""), DateTo: e }])
                                } />
                        </Form.Item>
                        <div className="ant-table-filter-dropdown-btns custom-btns">
                            <Button type="link" size="small" onClick={() => clearFilters()} disabled={!selectedKeys[0]}>
                                {t('common.reset')}
                            </Button>
                            <Button type="primary" size="small" onClick={() => confirm()}>
                                {t('common.filter')}
                            </Button>
                        </div>
                    </Form>
                </div>
            )
        })
};

export const GetTableConfigs = (columns: any, dataSource: any, totalItems: number, rowKey: string, onChangeFn: any) => {
    const { t } = useTranslation();
    return {
        className: "custom-table",
        scroll: { x: 768, y: 300 },
        pagination: {
            total: totalItems,
            size: "small" as PaginationSize,
            showTotal: (total: any) => t('common.total_items', { total: total }),
            showQuickJumper: true
        },
        locale: {
            emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} />,
            filterConfirm: t('common.filter')
        },
        columns: columns,
        dataSource: dataSource,
        onChange: onChangeFn,
        rowKey: rowKey
    }
}
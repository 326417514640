import React from 'react';
import { ReactComponent as Location } from '../../assets/icons/svg/map-pin.svg';
import { ReactComponent as Phone } from '../../assets/icons/svg/phone.svg';
import { ReactComponent as Mail } from '../../assets/icons/svg/mail.svg';
import { ReactComponent as ActiveBooking } from '../../assets/icons/svg/active-booking.svg';
import { ReactComponent as CompleteBooking } from '../../assets/icons/svg/complete-booking.svg';
import { ReactComponent as CancelBooking } from '../../assets/icons/svg/cancel-booking.svg';
import { ReactComponent as PendingBooking } from '../../assets/icons/svg/pending-booking.svg';
import { ReactComponent as OutlineCheck } from '../../assets/icons/svg/outline-check.svg';

export const MailIcon = () => {
    return (
        <Mail />
    );
}

export const LocationIcon = () => {
    return (
        <Location />
    );
}

export const PhoneIcon = () => {
    return (
        <Phone />
    );
}

export const ActiveBookingIcon = () => {
    return (
        <ActiveBooking />
    );
}

export const CompleteBookingIcon = () => {
    return (
        <CompleteBooking />
    );
}

export const CancelBookingIcon = () => {
    return (
        <CancelBooking />
    );
}

export const PendingBookingIcon = () => {
    return (
        <PendingBooking />
    );
}

export const OutlineCheckIcon = () => {
    return (
        <OutlineCheck />
    );
}
import React, { useState } from 'react';
import './addUpdateJob.scss'
import { Form, Input, Modal, Select, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalOptions, Model, RepairOrderJob, ServiceOperationCode } from '../../../models/appModels';
import { CommonService, CommonService as commonService } from './../../../services/commonService'
import ServiceCodeSelection from '../serviceCodeSelection/serviceCodeSelection';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};
const repairOrderJobModel = commonService.propToString<RepairOrderJob>()

export interface JobModalOptions extends ModalOptions<RepairOrderJob> {
    serviceOperationCodes: ServiceOperationCode[],
    jobLineID: number
}

const AddUpdateJob = (props: JobModalOptions) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedServiceCode, setSelectedServiceCode] = useState<ServiceOperationCode | null>();

    const onSubmit = (job: RepairOrderJob) => {
        if (job.ServiceOperationCode) {
            let operationCodes = props.serviceOperationCodes.filter(x => x.ServiceCode == job.ServiceOperationCode.ServiceCode)
            if (operationCodes.length > 0) {
                job.ServiceOperationCode = operationCodes[0]
                job.ServiceOperationCodeId = operationCodes[0].Id
            }
        }
        job.JobLineId = props.jobLineID
        if (props.objectData) {
            Object.assign(props.objectData, job)
            props.handleCancel(props.objectData)
        } else {
            props.handleCancel(job)
        }
    };

    const closeForm = () => {
        props.handleCancel()
    }

    const searchServiceCode = () => {
        setVisibleModal(true)
    }

    const closeSearchServiceCoce = (obj?: ServiceOperationCode) => {
        setVisibleModal(false)
        if (obj) {
            setSelectedServiceCode(obj)
            form.setFieldsValue({
                ["ServiceOperationCode"]: obj,
                [repairOrderJobModel.Description]: obj.Name
            });
        }
    }

    const canClearCode = () => {
        let id = getSelectedServiceCodeId()
        return id ? true : false
    }

    const getSelectedServiceCodeId = () => {
        return form.getFieldValue(["ServiceOperationCode", "Id"])
    }

    const clearServiceCode = () => {
        form.setFieldsValue({
            ["ServiceOperationCode"]: null
        });
    }

    return (
        <div>
            <Modal
                title={!props.objectData ? t("service_detail.add_job") : t("service_detail.update_job")}
                visible={true}
                onOk={form.submit}
                confirmLoading={false}
                onCancel={closeForm}
                okText={t("common.ok")}
                cancelText={t("common.cancel")}
                className="job-modal"
            >
                <Form
                    {...layout}
                    form={form}
                    name="jobLineForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Form.Item
                        label={t("service_detail.job")}
                        colon={false} labelAlign="left"
                        className="job-line-item"
                    >
                        <Tag color="#2db7f5">{props.jobLineID}</Tag>
                    </Form.Item>

                    <Form.Item
                        label={t("service_detail.operation_code")}
                        name={["ServiceOperationCode", "ServiceCode"]} colon={false} labelAlign="left"
                        className={`dropdown-modal`}
                    >
                        <Input readOnly={true} onClick={searchServiceCode}
                            suffix={canClearCode() ? <CloseOutlined onClick={clearServiceCode} /> : <DownOutlined onClick={searchServiceCode} />} />
                    </Form.Item>
                    <Form.Item
                        className="no-display"
                        label={t("service_detail.operation_code")}
                        name={["ServiceOperationCode", "Id"]} colon={false} labelAlign="left"
                        normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t("service_detail.operation_description")}
                        name={repairOrderJobModel.Description} colon={false} labelAlign="left"
                        rules={[{ required: true, message: t("service_detail.description_required") }]}
                        normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                    >
                        <TextArea autoSize={{ minRows: 2, maxRows: 5 }} />
                    </Form.Item>
                </Form>

            </Modal>
            {
                visibleModal &&
                <ServiceCodeSelection handleCancel={closeSearchServiceCoce} serviceOperationCodes={props.serviceOperationCodes} serviceCodeId={getSelectedServiceCodeId()}></ServiceCodeSelection>
            }
        </div>

    )
}

export default AddUpdateJob
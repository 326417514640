import React, { useEffect, useState } from 'react';
import './generalSetup.scss'
import { Switch, Form, Checkbox, Upload, Button, Row, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { AppSettingService, CommonService, Constant, StorageService } from '../../services/services'
import LoadingContext from './../utils/loadingCompt/loadingContext'
import { AppSetting } from '../../models/appModels';
import { CloseOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import AddImage from './../../assets/icons/svg/add-image.svg'
import { useGlobalState } from '../../services/state';

const appSettingModel = CommonService.propToString<AppSetting>()

const btnLayout = {
    wrapperCol: {
        xs: { span: 24 }
    }
};

function GeneralSetup(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [isDmsIntegration, setIsDmsIntegration] = React.useState<boolean>(false);
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [imageBase64, setImageBase64] = useState<string | null | undefined>();
    const [logoUrl, setLogoUrl] = useGlobalState('logoUrl');
    const [allowModify, setAllowModify] = useState<boolean>(false)

    let history = useHistory();

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.GeneralSetup))
        GetSetting()
        return () => {
            AppSettingService.cancelRequest()
        }
    }, [])

    const GetSetting = () => {
        showLoading()
        AppSettingService.getAppSetting()
            .finally(() => dismissLoading())
            .then(result => {
                if (result && result.data) {
                    setIsDmsIntegration(result.data.DmsIntegration)
                    form.setFieldsValue(result.data)
                    if (result.data.AvatarUrl) {
                        setLogoUrl(result.data.AvatarUrl)
                        let logoUrlStorage = StorageService.getLogoUrl()
                        if (logoUrlStorage != result.data.AvatarUrl) {
                            StorageService.setLogoUrl(result.data.AvatarUrl)
                        }

                    }
                    else {
                        StorageService.setLogoUrl("")
                    }
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const onSubmit = (setting: AppSetting) => {
        setting.AvatarBase64 = imageBase64 ? imageBase64 : ''
        setting.AvatarUrl = logoUrl ? logoUrl : ''
        showLoading()
        AppSettingService.updateAppSetting(setting)
            .finally(() => dismissLoading())
            .then(() => {
                if (setting.AvatarBase64) {
                    setImageBase64(setting.AvatarBase64)
                    StorageService.setLogoUrl(setting.AvatarBase64)
                    setLogoUrl(setting.AvatarBase64)
                }
                else if (setting.AvatarUrl) {
                    setLogoUrl(setting.AvatarUrl)
                    StorageService.setLogoUrl(setting.AvatarUrl)
                }
                else {
                    setImageBase64("")
                    setLogoUrl("")
                    StorageService.setLogoUrl("")
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
                GetSetting()
            })
    };

    const onIntegrationChange = (value: boolean) => {
        let setting = {
            DmsIntegration: value
        } as AppSetting

        onSubmit(setting)
    }

    const beforeUploadPhoto = (img: any) => {
        CommonService.getImgBase64(img, imageUrl => {
            setImageBase64(imageUrl);
        });
        return true
    }

    const removeLogo = () => {
        setImageBase64("");
        setLogoUrl("");
    }

    const reset = () => {
        form.setFieldsValue({
            "DmsIntegration": isDmsIntegration
        })
        let logoUrlStorage = StorageService.getLogoUrl()
        if (logoUrlStorage)
            setLogoUrl(logoUrlStorage)

    }

    return (
        <div className="general-setup">
            <Form
                layout='horizontal'
                form={form}
                name="loginForm"
                onFinish={onSubmit}
                initialValues={appSettingModel}

            >
                <Form.Item
                    className='flex-auto'
                    label={t("general_setup.dms_integration")}
                    name="DmsIntegration" colon={false} labelAlign="left"
                    valuePropName="checked"
                    labelCol={{ xs: { span: 16 }, md: { span: 5 }, xl: { span: 3 } }}
                    wrapperCol={{ xs: { span: 8 }, md: { span: 7 }, xl: { span: 3 } }}
                >
                    <Switch disabled={!allowModify}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />} />
                </Form.Item>

                <Form.Item
                    label={t("general_setup.dealer_logo")}
                    colon={false} labelAlign="left"
                    labelCol={{ xs: { span: 24 }, md: { span: 5 }, xl: { span: 3 } }}
                    wrapperCol={{ xs: { span: 24 }, md: { span: 7 }, xl: { span: 3 } }}
                >
                    <div className='avatar-container'>
                        <Upload
                            disabled={!allowModify}
                            className="avatar-uploader"
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={beforeUploadPhoto}
                            accept="image/*"
                        >
                            {
                                imageBase64
                                    ? <img alt={t('general_setup.dealer_logo')} src={imageBase64} />
                                    : (
                                        logoUrl
                                            ? <img alt={t('general_setup.dealer_logo')} src={`${logoUrl}?${(new Date()).toString()}`} />
                                            : <img alt={t('general_setup.dealer_logo')} className="no-image" src={AddImage} />
                                    )
                            }
                        </Upload>
                        {(logoUrl || imageBase64) && allowModify &&
                            <span className="remove-logo">
                                <Button type="primary" shape="circle" icon={<DeleteOutlined />} onClick={removeLogo} size="middle" />
                            </span>
                        }
                    </div>

                </Form.Item>
                {
                    allowModify &&
                    <Form.Item {...btnLayout}>
                        <Space>
                            <Button className="btn" onClick={reset}>
                                {t("common.reset")}
                            </Button>
                            <Button type="primary" htmlType="submit" className="primary-btn">
                                {t("common.save")}
                            </Button>
                        </Space>
                    </Form.Item>
                }

            </Form>
        </div>
    )
}

export default GeneralSetup
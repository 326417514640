import React, { useState, useContext, useEffect, forwardRef, useImperativeHandle } from 'react';
import './serviceDetail.scss'
import { Button, Form, Modal, Select, Row, Col, Card, List, Empty, Avatar, Checkbox, Input } from 'antd';
import { Constant, ClientService, CommonService as commonService } from '../../services/services'
import { useTranslation } from 'react-i18next';
import { EditFilled, PlusOutlined, DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { RepairOrderJob, ServiceOperationCode, Location, ServiceAdvisor } from '../../models/appModels';
import AddUpdateJob from './addUpdateJob/addUpdateJob';
import _, { filter } from 'underscore'
import { useGlobalState } from '../../services/state';
import { useLocation } from "react-router-dom";
import LoadingContext from './../utils/loadingCompt/loadingContext'

const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const cols = {
    xs: { span: 24 },
    md: { span: 22, offset: 1 }
}

const btnLayout = {
    xs: { span: 24 },
    md: { span: 23 }
};

const locationModel = commonService.propToString<Location>()

const ServiceDetail = forwardRef((props: any, ref) => {
    const { t } = useTranslation();
    const [jobLineData, setJobLineData] = useGlobalState('jobLineData');
    const [vehicleData] = useGlobalState('vehicleDetailData');
    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedObject, setSelectedObject] = useState<RepairOrderJob | null>();
    const [serviceOperationCodes, setServiceOperationCodes] = useState<ServiceOperationCode[]>([]);
    const [locations, setLocations] = useGlobalState('locationList');
    const [dealershipLocations, setDealershipLocations] = useState<Location[]>([]);
    const [jobLineID, setJobLineID] = useState(0);
    const [form] = Form.useForm();
    const [, reRender] = useState<null>();
    const location = useLocation();
    const [advisors, setAdvisors] = useState<ServiceAdvisor[]>([]);
    const [currentAdvisor, setCurrentAdvisor] = useState<ServiceAdvisor | null>(null);
    const [serviceDetailData, setServiceDetailData] = useGlobalState('serviceDetailData');
    const [jobLineList, setJobLineList] = useState<RepairOrderJob[]>([]);
    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const [dealershipId] = useGlobalState('dealershipId');


    React.useEffect(() => {
        let state: any = location.state
        if (state && state.doSubmit) {
            form.validateFields()
        }
    }, [location])

    React.useEffect(() => {
        let jobs = jobLineData.map(x => Object.assign({}, x))
        setJobLineList(jobs)

        // if(serviceDetailData && serviceDetailData.location && serviceDetailData.location.Id){
        //     ClientService.getServiceAdvisors(serviceDetailData.location.Id).then(result => {
        //         if (result && result.data) {
        //             setAdvisors(result.data)
        //             if(serviceDetailData && serviceDetailData.serviceAdvisor){
        //                 if(serviceDetailData.serviceAdvisor.Name){
        //                     setCurrentAdvisor(serviceDetailData.serviceAdvisor);
        //                 }    else if(serviceDetailData.serviceAdvisor.DmsUserId) {
        //                     let advisor = result.data.filter(a => a.DmsUserId == serviceDetailData.serviceAdvisor!.DmsUserId)
        //                     if(advisor && advisor.length > 0){
        //                         serviceDetailData.serviceAdvisor = advisor[0]
        //                         setCurrentAdvisor(advisor[0])
        //                     }
        //                 }                   
        //             }
        //         }
        //     })
        // }

    }, [])

    function changeStep(value: any, saveOnly: boolean = false) {
        let selectedLocations = locations.filter(x => x.Id == value.Id)
        if (selectedLocations.length > 0) {
            setServiceDetailData({
                ...serviceDetailData,
                location: selectedLocations[0]
                //serviceAdvisor: currentAdvisor
            })
        }
        let selectedServices = serviceOperationCodes.filter(x => x.IsSelected)
        if (selectedServices.length > 0 || value.Note) {
            let jobs = selectedServices.map((x, i) => {
                let obj: RepairOrderJob = {
                    JobLineId: i + 1,
                    ServiceOperationCode: x,
                    ServiceOperationCodeId: x.Id,
                    Description: x.Description,
                    Id: 0,
                    ServiceBookingId: 0,
                    ServiceOperationCodeServiceCode: x.ServiceCode
                }
                return obj
            })

            if (value.Note) {
                let obj = {
                    JobLineId: jobs.length + 1,
                    Description: value.Note,
                    Id: 0,
                    ServiceBookingId: 0,
                } as RepairOrderJob

                jobs.push(obj)
            }

            setJobLineData(jobs)
        }
        else {
            setJobLineData([])
        }

        if (!saveOnly) {
            props.changeStep(Constant.bookingStep.bookingDetail)
        }
    }

    useImperativeHandle(ref, () => ({

        formHasValue() {
            if (form.getFieldValue(locationModel.LocationCode) ||
                jobLineList.length > 0)
                return true
            return false
        },
        saveData() {
            let location = form.getFieldsValue() as Location
            changeStep(location, true)
        }
    }));

    const getLastedJobLineID = () => {
        if (jobLineList.length > 0) {
            let sortedList = _.sortBy(jobLineList, item => item.JobLineId)
            return sortedList[jobLineList.length - 1].JobLineId + 1
        }
        else {
            return 1
        }
    }

    const handleCancel = (objectData?: RepairOrderJob) => {
        if (objectData) {
            let existedIndex = jobLineList.findIndex(x => x.JobLineId == objectData.JobLineId)
            if (existedIndex < 0) {
                jobLineList.push(objectData)
            }
            else {
                jobLineList[existedIndex] = objectData
            }
            setJobLineList(jobLineList)
        }
        setVisibleModal(false)
    };

    const addJobLine = () => {
        setVisibleModal(true)
        setJobLineID(getLastedJobLineID())
        setSelectedObject(null)
    }

    const updateJobLine = (objectData: RepairOrderJob) => {
        setVisibleModal(true)
        setJobLineID(objectData.JobLineId)
        setSelectedObject(objectData)
    }

    const removeJobLine = (objectData: RepairOrderJob) => {
        confirm({
            title: t("common.confirm"),
            icon: <ExclamationCircleOutlined />,
            content: t("common.confirm_delete"),
            okText: t("common.ok"),
            cancelText: t("common.cancel"),
            onOk() {
                let index = jobLineList.indexOf(objectData)
                jobLineList.splice(index, 1)
                setJobLineList([...jobLineList])
                // setTimeout(() => {
                //     reRender(null)
                // }, 200)

            },
            onCancel() {
            }

        });
    }

    const onLocationChange = (locationId: number) => {
        if (locationId > 0) {
            setCurrentAdvisor(null)
            setAdvisors([])
            ClientService.getServiceAdvisors(locationId).then(result => {
                if (result) {
                    setAdvisors(result.data)
                }
            })
        }
    }

    const onAdvisorChange = (value: string) => {
        if (value) {
            let advisor = advisors.filter(a => a.DmsUserId == value)
            if (advisor && advisor.length > 0) {
                setCurrentAdvisor(advisor[0]);
            }
        } else {
            setCurrentAdvisor(null);
        }
    }

    useEffect(() => {
        let locationId = undefined
        if (serviceDetailData && serviceDetailData.location) {
            locationId = serviceDetailData.location.Id
        }

        ClientService.getServiceOperationCodesByLocationId(vehicleData?.Make?.MakeId, locationId)
            .then(function (results) {
                if (results) {
                    let temp = results.data.map(x => {
                        if (x.Id && jobLineData && jobLineData.length > 0) {
                            if (jobLineData.some(y => (y.ServiceOperationCodeId && y.ServiceOperationCodeId == x.Id) || (y.DmsOperationCode && y.DmsOperationCode == x.DmsOperationCode)))
                                x.IsSelected = true
                        }
                        return x
                    })
                    setServiceOperationCodes(temp)
                }
            })
            .catch(error => {
                commonService.handleErrorResponse(error)
            });

        let otherJobs = jobLineData.filter(j => !j.ServiceOperationCodeId && !j.DmsOperationCode)
        if (otherJobs && otherJobs.length > 0) {
            let note = ""
            otherJobs.forEach(job => {
                if (note) {
                    note += "\r\n"
                }
                note += job.Description
            })

            let formValue = {
                ...serviceDetailData.location,
                Note: note
            }
            form.setFieldsValue(formValue)
        }

        if (!locations || locations.length == 0) {
            ClientService.getLocations()
                .then(function (results) {
                    if (results) {
                        setLocations(results.data)
                        if (dealershipId) {
                            let filterLocations = results.data.filter(l => l.DealershipId == dealershipId)
                            setDealershipLocations(filterLocations)
                        } else {
                            setDealershipLocations(results.data)
                        }
                    }
                })
                .catch(error => {
                    commonService.handleErrorResponse(error)
                });
        } else {
            if (dealershipId) {
                let filterLocations = locations.filter(l => l.DealershipId == dealershipId)
                setDealershipLocations(filterLocations)
            } else {
                setDealershipLocations(locations)
            }
        }

        return () => {
            ClientService.cancelRequest()
        }
    }, [])

    const getLocations = () => {
        if (!locations || locations.length == 0) {
            return ClientService.getLocations()
        } else {
            return Promise.resolve();
        }

    }

    const onChangeServiceList = (item: ServiceOperationCode) => {
        let temp = [...serviceOperationCodes]//serviceOperationCodes.map(x => Object.assign({}, x))
        let selectedItems = temp.filter(x => x.Id == item.Id)
        if (selectedItems.length > 0) {
            selectedItems[0].IsSelected = !selectedItems[0].IsSelected
        }
        setServiceOperationCodes(temp)
    }

    const submit = () => {
        form.submit()
    }

    return (
        <>
            <Form
                {...layout}
                name="vehicleForm"
                initialValues={serviceDetailData.location}
                onFinish={changeStep}
                className="service-detail"
                form={form}
            >
                <Row>
                    <Col {...cols}>
                        <Form.Item
                            label={t("service_detail.service_location")}
                            name={locationModel.Id} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("service_detail.location_required") }]}
                        >
                            <Select showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={onLocationChange}
                            >
                                <Option value="-1">{t("common.none")}</Option>
                                {
                                    dealershipLocations.map((n, index) => <Option key={index} value={n.Id}>{n.LocationName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10, offset: 2 }} >
                    <Form.Item
                        label={t("service_detail.service_advisor")}
                        colon={false} labelAlign="left"
                        className="advisor-item"
                    >
                        <Select showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={currentAdvisor ? currentAdvisor.DmsUserId : undefined}
                            onChange={onAdvisorChange}
                        >
                            <Option value="">{t("common.none")}</Option>
                            {
                                advisors.map((n, index) => <Option key={index} value={n.DmsUserId}>{n.Name}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    <div style={{fontStyle: "italic"}}>{t("service_detail.service_advisor_notice")}</div>
                </Col>               */}
                </Row>
                <Row className="align-center m-t-10">
                    <Col {...cols}>
                        <label className="title-field bold-text">{t("service_detail.service_jobs")}</label>
                    </Col>
                    {/* <Col xs={{ span: 12 }} md={{ span: 11 }} className="text-right">
                    <Button className="btn has-icon-btn" type="primary" icon={<PlusOutlined />}
                        onClick={addJobLine}>{t("common.add")}</Button>
                </Col> */}
                </Row>

                <Row className="m-t-10 m-b-10">
                    <Col {...cols}>
                        <List
                            className='service-list'
                            dataSource={serviceOperationCodes}
                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                            renderItem={(item, i) => (
                                <List.Item>
                                    <Card key={i} onClick={() => onChangeServiceList(item)}>
                                        <Row justify='space-between' align='middle'>
                                            <Col xs={{ span: 20 }}>
                                                <div className='flex-display'>
                                                    <Avatar src={item.ImageUrl} shape="square" />
                                                    <label className={`title-field ${item.IsSelected ? 'color-primary' : ''}`}>{item.Name}</label>
                                                </div>
                                            </Col>
                                            <Col xs={{ span: 4 }} className="text-right">
                                                <Checkbox checked={item.IsSelected}></Checkbox>
                                            </Col>

                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col {...cols}>
                        <Form.Item name="Note" label={t("booking_detail.note")}
                        normalize={value => commonService.excludeSpecialCharactersInput(value) }
                        >
                            <TextArea autoSize={{ minRows: 2, maxRows: 2 }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col {...cols}>
                        <div className="note-label">{t("service_detail.service_notice")}</div>
                    </Col>
                </Row>

                {
                    visibleModal &&
                    <AddUpdateJob handleCancel={handleCancel} objectData={selectedObject} serviceOperationCodes={serviceOperationCodes} jobLineID={jobLineID}></AddUpdateJob>
                }
                {/* <Form.Item {...btnLayout} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" className="btn m-t-10">
                        {t("common.next_label")}
                    </Button>
                </Form.Item> */}

            </Form>
            <Row className='custom-footer'>
                <Col {...btnLayout} className="text-right">
                    <Button type="primary" className="btn" onClick={submit}>
                        {t("common.next_label")}
                    </Button>
                </Col>
            </Row>
        </>
    )
})

export default ServiceDetail
import React, { useEffect, useState } from 'react';
import './loginLayout.scss';
import { Layout, Select, ConfigProvider } from 'antd';
import { SettingFilled, ArrowLeftOutlined } from '@ant-design/icons'
import { Constant, AuthService, ClientService, StorageService, CommonService } from './../../services/services'
import { useGlobalState } from '../../services/state';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Header } = Layout;

const LoginLayout = (props: any) => {
    const [languages] = useGlobalState('languageList');
    const { t, i18n } = useTranslation();
    const [locations, setLocations] = useGlobalState('locationList');
    const [dealerships, setDealerships] = useGlobalState('dealerships');
    const [antLanguage, setAntLanguage] = useGlobalState('antLanguage');
    const [dateFormat, setDateFormat] = useGlobalState('dateFormat');
    const [currentLanguage, setCurrentLanguage] = useState<string>();

    useEffect(() => {
        setCurrentLanguage(languages.includes(i18n.language) ? i18n.language : undefined);
      }, [JSON.stringify(languages)]);

    useEffect(() => {
        AuthService.registerIntercepter(props.history)
        ClientService.registerIntercepterWithAuth(props.history)
    }, [])

    const isLoginPage = () => {
        if (props.location.pathname == Constant.pages.Login)
            return true
        return false
    }

    const goToConfig = () => {
        props.history.push(Constant.pages.Config);
    }

    const onLanguageChange = (value: any) => {
        i18n.changeLanguage(value)
        setCurrentLanguage(value)
        StorageService.setLanguage(value)

        let language = Constant.languageCodes.find(l => l.Code == value)
        if (language) {
            setAntLanguage(language.AntdLanguage)
            setDateFormat(language.DateFormat)
        }

        let newLocation = CommonService.updateLocationDealershipGlobalName(locations, value)
        setLocations(newLocation)

        let newDealership = CommonService.updateDealershipGlobalName(dealerships, value)
        setDealerships(newDealership)
    }

    return (
        <Layout className="layout-login">
            <Header className="full-width login-header">
                <div className="right-component">
                    {languages && languages.length > 1 &&
                        <Select value={currentLanguage} dropdownClassName="wrap-text-dropdown uppercase-text" defaultValue={languages[0]} className="w-65 m-r-5 custom-dropdown uppercase-text" onChange={onLanguageChange}>
                            {
                                languages.map((n, index) => <Option key={index} title={n} value={n}>{n}</Option>)
                            }
                        </Select>
                    }
                </div>
            </Header>
            <div className="page-container">
                <ConfigProvider locale={antLanguage}>
                    {props.children}
                </ConfigProvider>
            </div>

        </Layout>
    )
}

export default LoginLayout
import React, { useState, useEffect } from 'react';
import './addUpdateTeam.scss'
import { Form, Input, Modal, Select, Row, Col, Table, Tabs, TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalOptions, LocationTimeslot, WorkingDay } from '../../../models/appModels';
import {
    Constant,
    LocationManagementService,
    CommonService
} from './../../../services/services'
import moment from 'moment'
import { EditableCell } from '../../utils/gridColumn/gridColumn'


const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const teamModel = CommonService.propToString<LocationTimeslot>()

export interface TeamModalOptions extends ModalOptions<LocationTimeslot> {
    statusList: any[]
}

const AddUpdateTeam = (props: TeamModalOptions) => {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [exclusiveEndRequired, setExclusiveEndRequired] = useState<boolean>(false);

    const onSubmit = (team: LocationTimeslot) => {
        team.BookingStartTime = new Date(team.BookingStartTimeMap)
        team.BookingEndTime = new Date(team.BookingEndTimeMap)

        if (team.ExclusionStartTimeMap) {
            team.ExclusionStartTime = new Date(team.ExclusionStartTimeMap)
        }

        if (team.ExclusionEndTimeMap) {
            team.ExclusionEndTime = new Date(team.ExclusionEndTimeMap)
        }

        if (props.objectData) {
            Object.assign(props.objectData, team)
            props.handleCancel(props.objectData)
        } else {
            props.handleCancel(team)
        }
    };

    const closeForm = () => {
        props.handleCancel()
    }

    useEffect(() => {        
        if (props.objectData) {
            props.objectData.BookingStartTimeMap = moment(props.objectData.BookingStartTime)
            props.objectData.BookingEndTimeMap = moment(props.objectData.BookingEndTime)

            if (props.objectData.ExclusionStartTime) {
                props.objectData.ExclusionStartTimeMap = moment(props.objectData.ExclusionStartTime)
            }

            if (props.objectData.ExclusionEndTime) {
                props.objectData.ExclusionEndTimeMap = moment(props.objectData.ExclusionEndTime)
            }

            form.setFieldsValue(props.objectData)
        } else {
            form.setFieldsValue({
                [teamModel.StatusCode]: Constant.statusCode.Active
            });

        }

        return () => {
            LocationManagementService.cancelRequest()
        }
    }, [])

    const columns = [
        {
            title: '',
            dataIndex: 'DayOfTheWeekDescription',
            key: 'DayOfTheWeekDescription',
            width: 150
        },
        {
            title: t('location_management.working_time_range'),
            dataIndex: 'StartTime,EndTime',
            dataIndexex: ['StartTime', 'EndTime'],
            render: (value: any, record: any) => (
                <label className={!record || !record.StartTime ? "edit-label" : ""}>{record && record.StartTime ? CommonService.getTimeString(record.StartTime) + ' - ' + CommonService.getTimeString(record.EndTime) : "Click to edit."}</label>
            ),
            editable: true,
            cellType: Constant.cellType.TimeRange
        }
    ];

    const components = {
        body: {
            cell: EditableCell
        },
    };

    const checkExclusiveEndRequired = () => {
        let time = form.getFieldValue(["ExclusionStartTimeMap"])
        if (time) {
            setExclusiveEndRequired(true)
        } else {
            setExclusiveEndRequired(false)
        }
    }

    return (
        <Modal
            title={!props.objectData ? t("location_management.add_team") : t("location_management.update_team")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className="team-modal"
            maskClosable={false}
        >
            <Form
                {...layout}
                form={form}
                name="roleForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t("location_management.team")} key="1" className="m-l-5 m-r-5">
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_name")}
                                    name={teamModel.Name} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.location_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                                >
                                    <Input maxLength={128} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_status")}
                                    name={teamModel.StatusCode} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("role_management.status_required") }]}
                                >
                                    <Select>
                                        {
                                            props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.header_booking_start")}
                                    name={teamModel.BookingStartTimeMap} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.start_time_required") }]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.header_booking_end")}
                                    name={teamModel.BookingEndTimeMap} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("location_management.end_time_required") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                let start = getFieldValue('BookingStartTimeMap')
                                                if (!start || start >= value) {
                                                    return Promise.reject(t("location_management.start_end_error"));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    })]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </Col>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("location_management.exclusive_start_time")}
                                    name={teamModel.ExclusionStartTimeMap} colon={false} labelAlign="left"
                                >
                                    <TimePicker format="HH:mm" onChange={checkExclusiveEndRequired} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("location_management.exclusive_end_time")}
                                    name={teamModel.ExclusionEndTimeMap} colon={false} labelAlign="left"
                                    rules={[{ required: exclusiveEndRequired, message: t("location_management.exclusive_end_required") },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (value) {
                                                let start = getFieldValue('ExclusionStartTimeMap')
                                                if (!start || start >= value) {
                                                    return Promise.reject(t("location_management.start_end_error"));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    })]}
                                >
                                    <TimePicker format="HH:mm" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    {/* <TabPane tab={t("location_management.working_day")} key="2">
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    colon={false} labelAlign="left"
                                >
                                    <Table className="custom-table" size="small"
                                        components={components}
                                        columns={columnsRender}
                                        rowKey="DayOfTheWeek"
                                        dataSource={workingDayData}
                                        pagination={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane> */}
                </Tabs>
            </Form>

        </Modal>
    )
}

export default AddUpdateTeam

import { Button, Col, Row } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { OutlineCheckIcon } from '../../components/icons/icon';
import { useGlobalState } from '../../services/state';
import './bookingSuccess.scss'
import { useTranslation } from 'react-i18next';
import { Constant, ClientService } from '../../services/services';

const BookingSuccess = forwardRef((props: any, ref) => {
    const [bookingData] = useGlobalState('bookingData');
    const [prefix, setPrefix ] = useGlobalState('prefix');
    const { t } = useTranslation();

    React.useEffect(() => {
        if(!prefix){
            ClientService.getPrefix().then(res => {
                setPrefix(res.data)
            }).catch(e => {})
        }
    }, []);

    const formLayout = {
        xl: { span: 8, offset: 8 },
        lg: { span: 10, offset: 7 },
        md: { span: 12, offset: 6 },
        sm: { span: 16, offset: 4 },
        xs: { span: 22, offset: 1 }
    };

    const goBackHome = () => {
        props.backToMain(true, true)
    }
    return (
        <div className='booking-success'>
            <Row>
                <Col {...formLayout} className="text-center">
                    <OutlineCheckIcon />
                    <h2>{t(bookingData.Booking.StatusCode == Constant.bookingStatusCode.Active?"confirmation_detail.booking_successful":"common.email_confirmation")}</h2>
                </Col>

                <Col {...formLayout} className="text-center">
                    <h2>{prefix}{bookingData.Booking.Id}</h2>
                </Col>
            </Row>
            <Row className='custom-footer'>
                <Col {...formLayout}>
                    <Button type="primary" className="btn full-width m-t-20" onClick={goBackHome}>
                        {t("booking_management.create_new_booking")}
                    </Button>
                </Col>
            </Row>
        </div>
    )
})

export default BookingSuccess
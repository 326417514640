import React, { useState, useContext } from "react";
import { Col, Form, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { NamePath } from "antd/lib/form/interface";
import { FormInstance, RuleRender } from "antd/lib/form";

import { Vehicle } from "../../../../models/vehicle";
import {
  Customer,
  Make,
  ModalOptions,
  Bureaucracy,
  AreaCode,
} from "../../../../models/appModels";
import { ClientService } from "../../../../services/clientService";
import { useGlobalState } from "../../../../services/state";
import {
  CommonService,
  CommonService as commonService,
} from "../../../../services/commonService";
import LoadingContext from "../../../utils/loadingCompt/loadingContext";
import useDebounceFnc from "../../../utils/useDebounceFnc";

const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export interface VehicleModalOptions extends ModalOptions<Vehicle> {
  makeList: Make[];
  customerDetail: Customer;
  dealershipId?: number;
  ownedVehiclesData: Vehicle[];
  bureaucracies: Bureaucracy[];
  form: FormInstance<any>;
  isDisabled: boolean;
  onUpdateVehicleDetail: (vehicle: Vehicle) => void;
  onUpdateExistedVehicle: (isExited: boolean) => void;
}
const vehicleModel = commonService.propToString<Vehicle>();

const Abc = ({
  makeList,
  customerDetail,
  dealershipId,
  ownedVehiclesData,
  bureaucracies,
  form,
  isDisabled,
  onUpdateVehicleDetail,
  onUpdateExistedVehicle,
}: VehicleModalOptions) => {
  const { t } = useTranslation();
  const [customerDetailData] = useGlobalState("customerDetailData");
  const [, setOriginalVehicleMake] = useGlobalState("originalVehicleMake");
  const { showLoading, dismissLoading } = useContext(LoadingContext);
  const [areaCode, setAreaCode] = useState<AreaCode[]>([]);
  let previousRegoNoJapan: string;

  const setPreviousRegoNoJapan = (event: any) => {
    const areaCode = form.getFieldValue("AreaCode") ?? "";
    const component = getCombinedCode(form.getFieldValue);
    previousRegoNoJapan = `${areaCode}${component}`;
  };

  const checkAndGetExistingVehicleJapan = () => {
    const areaCode = form.getFieldValue("AreaCode") ?? "";
    const component = getCombinedCode(form.getFieldValue);

    const isRegoNoJapanValid = areaCode !== "" && component !== "";
    if (!isRegoNoJapanValid) {
      return;
    }

    const inputtedRegoNoJapan = `${areaCode}${component}`;
    const isRegoNoJapanChanged = previousRegoNoJapan !== inputtedRegoNoJapan;
    if (!isRegoNoJapanChanged) {
      return;
    }

    const ownedVehicle = getExistingVehicleInOwnedVehicles(inputtedRegoNoJapan);
    if (ownedVehicle != null) {
      form.setFieldsValue(deriveVehicleAreaCode(ownedVehicle));
      onUpdateVehicleDetail(ownedVehicle);
      if (
        ownedVehicle.Id > 0 ||
        (ownedVehicle.DmsVehicleKey && ownedVehicle.DmsVehicleKey > 0)
      ) {
        onUpdateExistedVehicle(true);
      }
    } else {
      debounceGetExistingVehicle(inputtedRegoNoJapan);
    }
  };

  const getExistingVehicle = (value: string) => {
    if (value && customerDetail && customerDetail.Mobile) {
      showLoading();
      ClientService.searchVehicle(
        value,
        customerDetail.Mobile,
        dealershipId,
        customerDetailData.IsOrganisation
      )
        .finally(() => dismissLoading())
        .then((res) => {
          if (
            res &&
            res.Booking &&
            res.Booking.Vehicle &&
            res.Booking.Vehicle.Id > 0
          ) {
            if (
              commonService.validateVehicleMakeSearch(res.Booking, makeList)
            ) {
              form.setFieldsValue(deriveVehicleAreaCode(res.Booking.Vehicle));
              onUpdateVehicleDetail(res.Booking.Vehicle);
              onUpdateExistedVehicle(true);
              setOriginalVehicleMake(res.Booking.Vehicle.MakeId);
            } else {
              form.resetFields();
            }
          }
        })
        .catch((error) => commonService.handleErrorResponse(error));
    }
  };

  const [debounceGetExistingVehicle, cancelGetExistingVehicle] = useDebounceFnc(
    getExistingVehicle,
    2400
  );
  const getExistingVehicleInOwnedVehicles = (regoNo: string) => {
    const existingVehicle =
      ownedVehiclesData?.filter((x) => x.RegoNo === regoNo) ?? [];
    return existingVehicle?.[0] ?? null;
  };

  const handleChangeBureaucracy = (bureaucracyCode: string) => {
    getAreaCode(bureaucracyCode);
    form.setFieldsValue({ AreaCode: "" });
  };

  const getAreaCode = (bureaucracyCode: string) => {
    showLoading();
    ClientService.getAreaCodeByBureaucracy(bureaucracyCode, dealershipId)
      .then((result) => {
        setAreaCode(result ?? []);
      })
      .catch((error) => commonService.handleErrorResponse(error))
      .finally(() => dismissLoading());
  };

  const getCombinedCode = (getFieldValueFn: (name: NamePath) => any) => {
    const component1 = getFieldValueFn("AreaCodeComponent1") ?? "";
    const component2 = getFieldValueFn("AreaCodeComponent2") ?? "";
    const component3 = getFieldValueFn("AreaCodeComponent3") ?? "";
    return `${component1}${component2}${component3}`;
  };

  const deriveVehicleAreaCode = (vehicle: Vehicle) => {
    return (
      vehicle && {
        ...vehicle,
        AreaCodeComponent1:
          vehicle.RegistrationPlateComponents?.split(";")[0] ?? "",
        AreaCodeComponent2:
          vehicle.RegistrationPlateComponents?.split(";")[1] ?? "",
        AreaCodeComponent3:
          vehicle.RegistrationPlateComponents?.split(";")[2] ?? "",
      }
    );
  };

  const validateAreaCodeComponentRule: RuleRender = (form) => ({
    validator() {
      const component = getCombinedCode(form.getFieldValue);
      return isDisabled || component.length > 0
        ? Promise.resolve()
        : Promise.reject();
    },
  });

  return (
    <>
      <Col xs={{ span: 24 }}>
        <Form.Item
          {...layout}
          label={t("vehicle_detail.bureaucracy")}
          name="RegoBureaucracy"
          colon={false}
          labelAlign="left"
          rules={[
            {
              required: !isDisabled,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="value"
            filterOption={(input, option) =>
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={handleChangeBureaucracy}
            disabled={isDisabled}
          >
            {bureaucracies.map((n, index) => (
              <Option key={index} value={n.Name}>
                {n.Name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }}>
        <Form.Item
          label={t("vehicle_detail.area_code")}
          name={vehicleModel.RegoNo}
          colon={false}
          labelAlign="left"
          rules={[
            ({ getFieldValue }) => ({
              validator() {
                const areaCode = getFieldValue("AreaCode") ?? "";
                const component = getCombinedCode(getFieldValue);

                return isDisabled ||
                  (areaCode?.length > 0 && component.length > 0)
                  ? Promise.resolve()
                  : Promise.reject(
                      t("vehicle_detail.car_registration_required")
                    );
              },
            }),
          ]}
          normalize={(value) =>
            CommonService.excludeSpecialCharactersInput(value)
          }
        >
          <div className="area-code-input">
            <Form.Item
              name="AreaCode"
              className="area-item"
              rules={[
                () => ({
                  validator(_, areaCode) {
                    return isDisabled || areaCode?.length > 0
                      ? Promise.resolve()
                      : Promise.reject();
                  },
                }),
              ]}
            >
              <Select
                showSearch
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={isDisabled}
                onFocus={setPreviousRegoNoJapan}
                onBlur={checkAndGetExistingVehicleJapan}
                onChange={() => {
                  cancelGetExistingVehicle();
                }}
              >
                {areaCode.map((n, index) => (
                  <Option key={index} value={n.Code}>
                    {n.Code}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="AreaCodeComponent1"
              className="code-item"
              help=""
              rules={[validateAreaCodeComponentRule]}
            >
              <Input
                maxLength={3}
                disabled={isDisabled}
                onFocus={setPreviousRegoNoJapan}
                onBlur={checkAndGetExistingVehicleJapan}
                onChange={() => {
                  cancelGetExistingVehicle();
                }}
              />
            </Form.Item>
            <Form.Item
              name="AreaCodeComponent2"
              className="code-item"
              help=""
              rules={[validateAreaCodeComponentRule]}
            >
              <Input
                maxLength={1}
                disabled={isDisabled}
                onFocus={setPreviousRegoNoJapan}
                onBlur={checkAndGetExistingVehicleJapan}
                onChange={() => {
                  cancelGetExistingVehicle();
                }}
              />
            </Form.Item>
            <Form.Item
              name="AreaCodeComponent3"
              className="code-item"
              help=""
              rules={[validateAreaCodeComponentRule]}
            >
              <Input
                maxLength={4}
                disabled={isDisabled}
                onFocus={setPreviousRegoNoJapan}
                onBlur={checkAndGetExistingVehicleJapan}
                onChange={() => {
                  cancelGetExistingVehicle();
                }}
              />
            </Form.Item>
          </div>
        </Form.Item>
      </Col>
    </>
  );
};

export default Abc;

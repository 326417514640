import React, { useState, useContext } from 'react';
import './forgotPassword.scss';
import './../../layouts/loginLayout/loginLayout.scss';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { Constant, AuthService, CommonService } from './../../services/services'
import { SettingFilled, LeftOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import LoadingContext from './../utils/loadingCompt/loadingContext'

const { Option } = Select;
const { TextArea } = Input;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const ForgotPassword = (props: any) => {
    console.log(props)
    const { t } = useTranslation();
    let history = useHistory();
    const [form] = Form.useForm();
    const { showLoading, dismissLoading } = useContext(LoadingContext)
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        if (data && data.Email) {
            showLoading()
            AuthService.forgotPassword(data.Email).finally(() => dismissLoading()).then(() => {
                setIsSuccess(true)
            }).catch(error => {
                CommonService.handleErrorResponse(error)
            })
        }

    };

    const goBack = () => {
        history.push(Constant.pages.Login);
    }

    return (
        <div className="forgot-password-page">
            <p className="page-title"><span className="back-icon"><LeftOutlined onClick={goBack} /></span>{t('login_page.forgot_password')}</p>
            {   !isSuccess &&
                <Form
                    {...layout}
                    form={form}
                    name="forgotPasswordForm"
                    onFinish={onSubmit}
                    initialValues={props.objectData}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label={t("customer_detail.email")}
                                name="Email" colon={false} labelAlign="left"
                                rules={[{ type: "email", message: t("customer_detail.email_invalid") },
                                { required: true, message: t("customer_detail.email_required") }
                                ]}
                                normalize={value => CommonService.excludeSpecialCharactersInput(value) }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="page-footer">
                        <Col span={10}>
                            <Button htmlType="button" className="full-width" onClick={goBack}>
                                {t("common.cancel")}
                            </Button>
                        </Col>
                        <Col span={14}>
                            <Button className="primary-btn submit-btn" htmlType="submit">
                                {t("common.submit")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
            {
                isSuccess && <p className="success-text">{t("login_page.reset_password_success")}</p>
            }
        </div>
    )
}

export default ForgotPassword
import axios from 'axios';
import { BaseObjectManagement, Location, Country, Dealership, ServiceAdvisor } from '../models/appModels';
import { axiosPortal } from './../services/authService'
import moment from 'moment'

const source: any[] = [];
let baseURL = "/Location"
export const LocationManagementService = {
    getLocations: function (pageNumber: number, pageSize: number, sort: string, filterList: any[]) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        let filters = ""
        if (filterList && filterList.length > 0) {
            filters = JSON.stringify(filterList)
        }

        return axiosPortal.get<BaseObjectManagement<Location>>(`${baseURL}?PageNumber=${pageNumber}&PageSize=${pageSize}&Sort=${sort}&Filters=${filters}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    addUpdateLocations: function (location: Location) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        if(location){
            if(location.BookingStartTime){
                location.BookingStartTime = moment(location.BookingStartTime).format("YYYY-MM-DD HH:mm:ss")
            }
            if(location.BookingEndTime){
                location.BookingEndTime = moment(location.BookingEndTime).format("YYYY-MM-DD HH:mm:ss")
            }

            if(location.LocationTimeslot){
                location.LocationTimeslot.forEach(l => {
                    if(l.BookingStartTime){
                        l.BookingStartTime = moment(l.BookingStartTime).format("YYYY-MM-DD HH:mm:ss")
                    }
                    if(l.BookingEndTime){
                        l.BookingEndTime = moment(l.BookingEndTime).format("YYYY-MM-DD HH:mm:ss")
                    }

                    if(l.WorkingDay){
                        l.WorkingDay.forEach(w => {
                            if(w.StartTime){
                                w.StartTime = moment(w.StartTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                            if(w.EndTime){
                                w.EndTime = moment(w.EndTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        })
                    }
                })
            }
          }
        return axiosPortal.post<number>(baseURL, location, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getCountries: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<Country[]>(`${baseURL}/Countries`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDealerships: function () {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<Dealership[]>("/Dealership", {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getDmsLocation: function (dealershipId: number, locationCode: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        return axiosPortal.get<Location>(`${baseURL}/DmsLocation?dealershipId=${dealershipId}&locationCode=${locationCode}`, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    getServiceAdvisors: function (locationCode: string, dealershipId: number) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)
        let url = `/Booking/ServiceAdvisors?locationCode=${locationCode}&dealershipId=${dealershipId}`
        return axiosPortal.get<ServiceAdvisor[]>(url, {
          cancelToken: cancelToken.token
        }).finally(() => {
          let index = source.indexOf(cancelToken)
          if (index > -1) {
            source.splice(index, 1)
          }
        })
      },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
import React, { useState, useEffect } from 'react';
import { Modal, Form, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingContext from '../../utils/loadingCompt/loadingContext'
import { ServiceBooking, CancelBookingRequest } from '../../../models/appModels';
import {
    Constant,
    CommonService,
    BookingManagementService,
} from '../../../services/services'

const { Option } = Select;
const { TextArea } = Input;

const CancelBooking = (props: any) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [bookingData, setBookingData] = useState<ServiceBooking>()
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)

    function cancelBooking() {
        form.submit()
    }

    async function doCancelBooking(data: any) {
        if (props.bookingData) {
            if (!props.isFromDMS) {
                let cancelRequest = {
                    ServiceBookingId: props.bookingData.Id,
                    CancellationCode: data.Code,
                    CancellationComment: data.Comment
                } as CancelBookingRequest

                showLoading()
                await BookingManagementService.cancelBooking(cancelRequest)
                    .finally(() => dismissLoading())
                    .then(() => {
                        CommonService.presentToast('success', t("confirmation_detail.booking_cancel"), t("common.notification_header"))
                        //props.backToMain(true, true)
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error)
                    })
            } else {
                let cancelRequest = {
                    DmsBooking: props.bookingData,
                    CancellationCode: data.Code,
                    CancellationComment: data.Comment
                } as CancelBookingRequest

                showLoading()
                await BookingManagementService.cancelDmsBooking(cancelRequest)
                    .finally(() => dismissLoading())
                    .then(() => {
                        CommonService.presentToast('success', t("confirmation_detail.booking_cancel"), t("common.notification_header"))
                    })
                    .catch(error => {
                        CommonService.handleErrorResponse(error)
                    })
            }

            props.handleCancel(props.bookingData)
        }
    }

    const closeForm = () => {
        props.handleCancel()
    }

    return (
        <Modal
            title={t("common.cancel") + ' ' + t("booking_management.title_booking") + ' ' + props.bookingData?.IdPrefix}
            visible={true}
            onOk={cancelBooking}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            closable={false}
            maskClosable={false}
        >
            <div>
                <label>{t("booking_management.header_rego")}: {props.bookingData?.Vehicle?.RegoNo}</label>
            </div>
            <div>
                <label>{t("booking_management.header_customer")}: {props.bookingData?.Customer?.Name}</label>
            </div>
            <div>
                <label>{t("booking_management.header_dms_booking_id")}: {props.bookingData?.DmsBookingId}</label>
            </div>

            <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="cancelBookingForm"
                onFinish={doCancelBooking}
                form={form}
            >
                <Form.Item
                    label={t("confirmation_detail.cancel_reason")}
                    name="Code" colon={false} labelAlign="left"
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!getFieldValue('Comment') && (value === 0 || !value)) {
                                    return Promise.reject(t("confirmation_detail.reason_missing"));
                                }
                                return Promise.resolve();
                            },
                        })
                    ]}
                >
                    <Select className="full-width"
                    >
                        <Option value={Math.random()}>{t("common.none")}</Option>
                        {
                            props.cancellationCodes.map((n: any, index: any) => <Option key={index} value={n.Code}>{n.Code}-{n.Description}</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t("confirmation_detail.cancel_comment")}
                    name="Comment" colon={false} labelAlign="left"
                >
                    <TextArea className="m-t-5" autoSize={{ minRows: 2, maxRows: 2 }} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default CancelBooking
import axios from 'axios';
import { Constant } from './constant'

const source: any[] = [];

export const TranslationService = {
    translateText: function (text: string, sourceLanguage: string, targetLanguage: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        const url = `${Constant.translateServerUrl}/translate`;
        let data = {
            q: text,
            source: sourceLanguage,
            target: targetLanguage
        }

        return axios.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    detectLanguage: function (text: string) {
        let cancelToken = axios.CancelToken.source()
        source.push(cancelToken)

        const url = `${Constant.translateServerUrl}/detect`
        let data = {
            q: text
        }

        return axios.post(url, data, {
            cancelToken: cancelToken.token
        }).finally(() => {
            let index = source.indexOf(cancelToken)
            if (index > -1) {
                source.splice(index, 1)
            }
        })
    },
    cancelRequest: function () {
        if (source && source.length > 0) {
            for (var i = 0; i < source.length; i++) {
                source[i].cancel('Operation canceled.')
            }
            source.splice(0, source.length)
        }
    }
}
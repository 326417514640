import React, { useEffect, useRef, useState } from 'react';
import './smsTemplateSetup.scss'
import { useTranslation } from 'react-i18next';
import { Button, Col, Empty, Form, Input, List, Modal, Row, Select, Tooltip } from 'antd';
import LoadingContext from '../utils/loadingCompt/loadingContext';
import { AppSettingService, CommonService, Constant } from '../../services/services';
import { SmsTemplate, SmsTemplateParameter } from '../../models/appModels';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TextArea } = Input;
const smsTemplateParamModel = CommonService.propToString<SmsTemplateParameter>()


function SmsTemplateSetup(props: any) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [smsTemplates, setSmsTemplates] = React.useState<SmsTemplate[]>([]);
    const [currentSmsTemplate, setCurrentSmsTemplate] = React.useState<SmsTemplate>();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [editParameterVisible, setEditParameterVisible] = React.useState<boolean>(false);
    const [templateType, setTemplateType] = React.useState<any>();
    const [allowModify, setAllowModify] = useState<boolean>(false)
    const [templateContent, setTemplateContent] = useState<string>()
    let clickTimeout: any = null

    useEffect(() => {
        setAllowModify(CommonService.allowModyfyAndAccessPage(Constant.appPageIds.GeneralSetup))
        getTemplates()

        return () => {
            AppSettingService.cancelRequest()
        }
    }, [])

    const getTemplates = () => {
        showLoading()
        AppSettingService.getSmsTemplates()
            .finally(() => dismissLoading())
            .then(result => {
                if (result && result.data) {
                    setSmsTemplates(result.data)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const reset = () => {
        setTemplateType(null)
        setCurrentSmsTemplate(undefined)
        // if (emailEditorRef && emailEditorRef.current) {
        //     emailEditorRef.current.editor.loadDesign(Constant.emailDefaultDesign);
        // }
        setTemplateContent("")
        getTemplates()
    }

    const handleChange = (value: any) => {
        let template = smsTemplates.filter(e => e.SmsTemplateType == value)
        if (template && template.length > 0) {
            setCurrentSmsTemplate(template[0])
            setTemplateType(template[0].SmsTemplateType)
            console.log(template[0].Content)
            setTemplateContent(template[0].Content)

        }
    };

    const copyToClipboard = (value: string) => {
        if (clickTimeout == null) {
            clickTimeout = setTimeout(() => {
                clickTimeout = null
                navigator.clipboard.writeText(value)
                CommonService.presentToast("info", value + t("email_template_setup.copied_clipboard"))
            }, 300);
        }

    }

    const openParamModal = (paramKey: string | null) => {
        clearTimeout(clickTimeout)
        clickTimeout = null
        if (currentSmsTemplate) {
            if (paramKey) {
                let param = currentSmsTemplate?.SmsTemplateParameter.filter(p => p.ParameterKey == paramKey)
                if (param && param.length > 0) {
                    form.setFieldsValue(param[0])
                }
            }
            setEditParameterVisible(true)
        }
    }

    const closeModal = () => {
        form.resetFields()
        setEditParameterVisible(false)
    }

    const addUpdateParameter = () => {
        form.submit()
    }

    const doAddUpdateParameter = (data: any) => {
        if (currentSmsTemplate) {
            let params = currentSmsTemplate.SmsTemplateParameter.filter(p => p.ParameterKey == data.ParameterKey)
            if (params && params.length > 0) {
                Object.assign(params[0], data)
            } else {
                data.SmsTemplateId = currentSmsTemplate.Id
                currentSmsTemplate.SmsTemplateParameter.push(data)
            }

        }
        setEditParameterVisible(false)
    }

    const saveTemplate = () => {
        if(currentSmsTemplate){
            currentSmsTemplate.Content = templateContent ? templateContent : ""
            showLoading()
            AppSettingService.addUpdateSmsTemplate(currentSmsTemplate)
                .finally(() => dismissLoading())
                .then(() => {
                    CommonService.presentToast("success", t("email_template_setup.save_success"))
                })
                .catch(error => CommonService.handleErrorResponse(error))
        }
    };

    const onTemplateContentChange = (value: any) => {
        setTemplateContent(value.target.value)
    }
    return (
        <div className="sms-template-setup">
            <Row>
                <Col span={4}>
                    <label>{t("sms_template_setup.header_email_template")}</label>
                    <Select value={templateType} className="text-body" style={{ width: "100%" }} onChange={handleChange}>
                        {
                            smsTemplates.map(n => <Option key={n.SmsTemplateType} value={n.SmsTemplateType}>{n.Description}</Option>)
                        }
                    </Select>
                </Col>
                {
                    allowModify &&
                    <Col span={4} className="button-col">
                        <div className="btn-container">
                            <Button className="btn m-l-10" type="text" onClick={reset}>{t("common.reset")}</Button>
                            <Button className="primary-btn m-l-10" onClick={saveTemplate}>{t("common.save")}</Button>
                        </div>
                    </Col>
                }
            </Row>

            <Row className="m-t-10">
                <Col span={12}>
                    <TextArea value={templateContent} onChange={onTemplateContentChange} rows={10} allowClear />
                </Col>
                <Col className="p-l-20" span={6} style={{ maxHeight: "600px" }}>
                    {
                        allowModify &&
                        <div>
                            <label>{t("email_template_setup.header_parameters")}</label>
                            <PlusOutlined className="m-l-10 param-add-btn" onClick={() => openParamModal(null)} />
                        </div>
                    }

                    <List
                        className="scroll-list"
                        dataSource={currentSmsTemplate?.SmsTemplateParameter}
                        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("common.no_data")} /> }}
                        renderItem={(item, i) => (
                            <List.Item className="param-item" onClick={() => copyToClipboard(item.ParameterKey)} onDoubleClick={() => openParamModal(item.ParameterKey)}>
                                <Tooltip placement="topLeft" className="tooltip" title={t("email_template_setup.copy_clipboard")}>
                                    <div className="ant-list-item-meta-content">
                                        <h4 className="ant-list-item-meta-title">{item.ParameterKey}</h4>
                                        <div className="ant-list-item-meta-description">{item.Description}</div>
                                    </div>
                                </Tooltip>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>

            {
                editParameterVisible &&
                <Modal
                    title={t("sms_template_setup.parameter_title")}
                    visible={true}
                    onOk={addUpdateParameter}
                    confirmLoading={false}
                    onCancel={closeModal}
                    okText={t("common.ok")}
                    cancelText={t("common.cancel")}
                    closable={false}
                >
                    <Form
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="smsTemplateForm"
                        onFinish={doAddUpdateParameter}
                        form={form}
                    >
                        <Form.Item
                            label={t("email_template_setup.header_parameter_key")}
                            name={smsTemplateParamModel.ParameterKey} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_table")}
                            name={smsTemplateParamModel.TableName} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_field")}
                            name={smsTemplateParamModel.FieldName} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_query")}
                            name={smsTemplateParamModel.Query} colon={false} labelAlign="left"
                            rules={[{ required: true, message: t("common.required_field") }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_separator")}
                            name={smsTemplateParamModel.Separator} colon={false} labelAlign="left"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("email_template_setup.header_description")}
                            name={smsTemplateParamModel.Description} colon={false} labelAlign="left"
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Modal>
            }

        </div>
    )
}

export default SmsTemplateSetup
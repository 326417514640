import React, { useState, useEffect } from 'react';
import './../../userManageMent/userManagement.scss';
import { Form, Input, Modal, Select, Row, Col, Table, Checkbox, Switch, DatePicker, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { User, ModalOptions, Role, Location } from '../../../models/appModels';
import { useGlobalState } from '../../../services/state';

import {
    CommonService as commonService,
    Constant,
    ClientService,
    UserManagementService,
    CommonService,
    StorageService,
    AlignType
} from './../../../services/services'
import LoadingContext from './../../utils/loadingCompt/loadingContext'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import { EditableCell } from '../../utils/gridColumn/gridColumn';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
};

const firstCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11 }
}

const secondCols = {
    xs: { span: 24 },
    sm: { span: 24 },
    md: { span: 11, offset: 2 }
}

const userModel = commonService.propToString<User>()

export interface UserModalOptions extends ModalOptions<User> {
    statusList: any[],
    roleList: Role[]
}

const AddUpdateUser = (props: UserModalOptions) => {
    const { t } = useTranslation();
    const { showLoading, dismissLoading } = React.useContext(LoadingContext)
    const [isChangePassword, setChangePassword] = useState(false)
    const [dealerships] = useGlobalState('dealerships');
    const [dealershipId, setDealershipId] = useState<number | undefined>(undefined);
    const [locationData, setLocationData] = useGlobalState('locationList');
    const [displayLocations, setDisplayLocations] = useState<Location[]>([]);
    const [dateFormat, setDateFormat] = useGlobalState('dateFormat');

    const [form] = Form.useForm();

    const onSubmit = (user: User) => {
        if (displayLocations && displayLocations.length > 0) {
            let selectedLocations = displayLocations.filter(x => x.IsSelected)
            if (selectedLocations.length > 0)
                user.LocationIds = selectedLocations.map(x => x.Id)
        }
        if (props.objectData) {
            updateUser(user, props.objectData.Id)
        }
        else {
            addUser(user)
        }
    };

    const addUser = (user: User) => {
        showLoading()

        if (dealershipId && dealershipId > 0 && !user.DealershipId) {
            user.DealershipId = dealershipId
        }

        UserManagementService.addUser(user)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('user_configuration.create_successful'), t("common.notification_header"))
                    props.handleCancel(user)
                }

            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const updateUser = (user: User, id: string) => {
        user.Id = id
        showLoading()
        UserManagementService.updateUser(user)
            .finally(() => dismissLoading())
            .then(result => {
                if (!result.data) {
                    commonService.presentToast('success', t('user_configuration.update_successful'), t("common.notification_header"))
                    props.handleCancel(user)
                }
            })
            .catch(error => CommonService.handleErrorResponse(error))
    }

    const closeForm = () => {
        props.handleCancel()
    }

    const onChangePasswordSwitch = (value: boolean) => {
        setChangePassword(value)
    }

    const onChangeTerminationDate = (value: any) => {
        if (value) {
            let currentDate = moment(new Date()).format("YYYY-MM-DD")
            let selectedDate = moment(value).format("YYYY-MM-DD")
            if (new Date(selectedDate) <= new Date(currentDate)) {
                form.setFieldsValue({
                    [userModel.StatusCode]: Constant.statusCode.Inactive
                });
            }
        }

    }

    const onChnageStatus = (value: any) => {
        if (value && value == Constant.statusCode.Active) {
            form.setFieldsValue({
                ["TerminationDate"]: null
            });
        }
    }

    useEffect(() => {
        let data = StorageService.getApplicationData()
        if (data.User, data.User.DealershipId) {
            setDealershipId(data.User.DealershipId)
        }

        if (!props.objectData) {
            form.setFieldsValue({
                [userModel.StatusCode]: Constant.statusCode.Active
            });
        }

        if (!locationData || locationData.length == 0) {
            getLocations()
        }
        return () => {
            UserManagementService.cancelRequest()
        }
    }, [])

    const components = {
        body: {
            cell: EditableCell
        },
    };

    const locationColumns = [
        {
            title: t('location_management.header_dealership'),
            dataIndex: 'DealershipName',
            key: 'DealershipName',
            width: 150
        },
        {
            title: t('location_management.header_name'),
            dataIndex: 'LocationName',
            key: 'LocationName',
            width: 150
        },
        {
            title: t('common.display'),
            dataIndex: 'IsSelected',
            dataIndexex: 'IsSelected',
            editable: true,
            cellType: Constant.cellType.Checkbox,
            align: 'center' as AlignType,
            width: 60
        }
    ];

    const handleSaveLocation = (row: Location) => {
        const newData = [...displayLocations];
        const index = newData.findIndex(item => row.Id === item.Id);
        if (index >= 0) {
            const item = newData[index];
            newData.splice(index, 1, {
                ...item,
                ...row,
            });
            setDisplayLocations(newData);
        }
    };

    const columnsRender = locationColumns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Location) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                dataIndexex: col.dataIndexex,
                title: col.title,
                cellType: col.cellType,
                width: col.width,
                handleSave: handleSaveLocation
            }),
        };
    });

    const dealershipChange = (value: any) => {
        let locations = locationData.map(x => Object.assign({}, x))
        if (value) {
            locations = locations.filter(x => x.DealershipId == value)
        }
        locations.forEach((item: Location) => {
            let selectedItems = displayLocations.filter(d => d.Id == item.Id && d.IsSelected);
            if (selectedItems && selectedItems.length > 0) {
                item.IsSelected = true
            }
            else{
                item.IsSelected = false
            }
        })
        setDisplayLocations(locations);
    };

    const getLocations = () => {
        ClientService.getLocations()
            .then(function (results) {
                if (results) {
                    setLocationData(results.data)
                }
            })
            .catch(error => {
                CommonService.handleErrorResponse(error)
            });
    }

    useEffect(() => {
        if (locationData && locationData.length > 0) {
            let tempList = locationData.map(x => Object.assign({}, x))
            if (props.objectData) {
                if (props.objectData) {
                    if (props.objectData.DealershipId) {
                        tempList = tempList.filter(x => x.DealershipId == props.objectData.DealershipId)
                    }
                }
                if (props.objectData.LocationIds && props.objectData.LocationIds.length > 0) {
                    props.objectData.LocationIds.forEach((id: number) => {
                        let item = tempList.filter(d => d.Id == id);
                        if (item && item.length > 0) {
                            item[0].IsSelected = true
                        }
                    })
                }
            }

            setDisplayLocations(tempList)
        }
    }, [locationData])

    return (
        <Modal
            title={!props.objectData ? t("user_configuration.add_user") : t("user_configuration.update_user")}
            visible={true}
            onOk={form.submit}
            confirmLoading={false}
            onCancel={closeForm}
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            className="portal-modal"
            maskClosable={false}
        >
            <Form
                {...layout}
                form={form}
                name="roleForm"
                onFinish={onSubmit}
                initialValues={props.objectData}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t("user_configuration.user_info")} key="1" className="m-l-5 m-r-5">
                        <Row>
                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("login_page.username")}
                                    name={userModel.UserName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("login_page.username_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input disabled={props.objectData ? true : false} maxLength={Constant.limitLength.length256} />
                                </Form.Item>
                            </Col>
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("common.status")}
                                    name={userModel.StatusCode} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("common.status_required") }]}
                                >
                                    <Select onChange={onChnageStatus}>
                                        {
                                            props.statusList.map((n, index) => <Option key={index} value={n.Code}>{n.Description}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {!props.objectData &&
                            <Row>
                                <Col {...firstCols}>
                                    <Form.Item
                                        label={t("login_page.password")}
                                        name={userModel.Password} colon={false} labelAlign="left"
                                        rules={[{ required: true, message: t("login_page.password_required") }]}
                                    >
                                        <Input autoComplete="new-password" type="password" maxLength={Constant.limitLength.length256} />
                                    </Form.Item>
                                </Col>
                                <Col {...secondCols}>
                                    <Form.Item
                                        label={t("user_configuration.confirm_password")}
                                        name={userModel.ConfirmPassword} colon={false} labelAlign="left"
                                        rules={[{ required: true, message: t("user_configuration.confirm_password_required") },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (value) {
                                                    let password = getFieldValue(userModel.Password)
                                                    if (password && password != value) {
                                                        return Promise.reject(t("user_configuration.confirm_password_not_match"));
                                                    }
                                                }
                                                return Promise.resolve();
                                            },
                                        })]}
                                    >
                                        <Input type="password" maxLength={Constant.limitLength.length256} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <Row>
                            {process.env.REACT_APP_COUNTRY_CODE === Constant.countryCodes.JP && ( 
                            <>                                               
                            <Col {...firstCols}>
                            <Form.Item
                                    label={t("customer_detail.last_name")}
                                    name={userModel.Surname} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("customer_detail.last_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>                                
                            </Col>
                            <Col {...secondCols}>
                            <Form.Item
                                    label={t("customer_detail.first_name")}
                                    name={userModel.FirstName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("customer_detail.first_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>                               
                            </Col>
                            </>
                            )}                                                                         
                            <Col {...firstCols}>
                            <Form.Item
                                    label={t("customer_detail.email")}
                                    name={userModel.Email} colon={false} labelAlign="left"
                                    rules={[{ type: "email", message: t("customer_detail.email_invalid") },
                                    { required: true, message: t("customer_detail.email_required") }
                                    ]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>                 
                            </Col>
                            {process.env.REACT_APP_COUNTRY_CODE !== Constant.countryCodes.JP && ( 
                            <>  
                            <Col {...secondCols}>
                            <Form.Item
                                    label={t("customer_detail.first_name")}
                                    name={userModel.FirstName} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("customer_detail.first_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>                               
                            </Col>                            
                            <Col {...firstCols}>
                            <Form.Item
                                    label={t("customer_detail.last_name")}
                                    name={userModel.Surname} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t("customer_detail.last_name_required") }]}
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length512} />
                                </Form.Item>                                  
                            </Col>
                            </>
                            )}
                            <Col {...secondCols}>
                                <Form.Item
                                    label={t("user_configuration.phone_number")}
                                    name={userModel.PhoneNumber} colon={false} labelAlign="left"
                                    normalize={value => CommonService.excludeSpecialCharactersInput(value)}
                                >
                                    <Input maxLength={Constant.limitLength.length256} />
                                </Form.Item>
                            </Col>

                            <Col {...firstCols}>
                                <Form.Item
                                    label={t("user_configuration.roles")}
                                    name={userModel.RoleIds} colon={false} labelAlign="left"
                                    rules={[{ required: true, message: t('user_configuration.roles_required'), type: 'array' }]}
                                >
                                    <Select mode="multiple">
                                        {
                                            props.roleList.map((n, index) => <Option key={index} value={n.Id}>{n.Name}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col {...secondCols} hidden={dealershipId ? true : false}>
                                <Form.Item
                                    label={t("location_management.header_dealership")}
                                    name={userModel.DealershipId} colon={false} labelAlign="left"
                                >
                                    <Select allowClear onChange={dealershipChange}>
                                        {
                                            dealerships.map((n, index) => <Option key={index} value={n.DealerId}>{n.DealerName}</Option>)
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {props.objectData &&
                            <div>
                                <Row>
                                    <Col {...firstCols}>
                                        <Form.Item
                                            label={t("user_configuration.termination_date")}
                                            name="TerminationDate" colon={false} labelAlign="left"
                                        >
                                            <DatePicker dropdownClassName="portal-date-picker" format={dateFormat}
                                                onChange={onChangeTerminationDate} />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Row className="flex-center">
                                    <Col {...firstCols} className="switch-item-contain">
                                        <Form.Item labelCol={{ xs: { span: 12 }, md: { span: 19} }} wrapperCol={{ xs: { span: 12 }, md: { span: 5 } }}
                                            label={
                                                <span style={{ fontSize: "15px" }}>
                                                  {t("login_page.change_password")}
                                                </span>
                                            }
                                            name="IsChangePassword" colon={false} labelAlign="left"
                                            valuePropName="checked"
                                        >
                                            <Switch onChange={value => onChangePasswordSwitch(value)}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                className="portal-switch"
                                            />
                                        </Form.Item>
                                    </Col>
                                    {
                                        isChangePassword &&
                                        <Col {...secondCols}>
                                            <Form.Item
                                                label={t("user_configuration.old_password")}
                                                name={userModel.OldPassword} colon={false} labelAlign="left"
                                                rules={[{ required: true, message: t("user_configuration.old_password_required") }]}
                                            >
                                                <Input.Password className="no-border" maxLength={Constant.limitLength.length256} />
                                            </Form.Item>
                                        </Col>
                                    }
                                </Row>
                                {
                                    isChangePassword &&
                                    <Row>
                                        <Col {...firstCols}>
                                            <Form.Item
                                                label={t("user_configuration.new_password")}
                                                name={userModel.Password} colon={false} labelAlign="left"
                                                rules={[{ required: true, message: t("user_configuration.new_password_required") },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (value) {
                                                            let oldPassword = getFieldValue(userModel.OldPassword)
                                                            if (oldPassword && oldPassword == value) {
                                                                return Promise.reject(t("user_configuration.new_password_invalid"));
                                                            }
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })]}
                                            >
                                                <Input.Password className="no-border" maxLength={Constant.limitLength.length256} />
                                            </Form.Item>
                                        </Col>
                                        <Col {...secondCols}>
                                            <Form.Item
                                                label={t("user_configuration.confirm_password")}
                                                name={userModel.ConfirmPassword} colon={false} labelAlign="left"
                                                rules={[{ required: true, message: t("user_configuration.confirm_password_required") },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (value) {
                                                            let password = getFieldValue(userModel.Password)
                                                            if (password && password != value) {
                                                                return Promise.reject(t("user_configuration.confirm_password_not_match"));
                                                            }
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                })]}
                                            >
                                                <Input.Password className="no-border" maxLength={Constant.limitLength.length256} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                }
                            </div>
                        }
                    </TabPane>
                    <TabPane tab={t("user_configuration.location_access")} key="2" className="m-l-5 m-r-5">
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    colon={false} labelAlign="left"
                                >
                                    <Table className="custom-table" size="small"
                                        components={components}
                                        columns={columnsRender}
                                        rowKey="Id"
                                        dataSource={displayLocations}
                                        pagination={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>


            </Form>
        </Modal>
    )
}

export default AddUpdateUser

